<template src="./activityReferenceInfrastructure.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityReferenceInfrastructure',
  components: {
    inputContainer,
    smallButton
  },
  data () {
    return {
      loading: false,
      tempRoomName: '',
      tempRoomDepartment: '',
      tempRoomBuilding: '',
      tempRoomSharingDepartments: [],
      tempRoomClassRoom: false,
      tempRoomPracticalLab: false,
      tempRoomOffice: false,
      tempRoomConferenceHall: false,
      tempRoomMeetingRoom: false,
      tempRoomSpecialRoom: false,
      userData: {},
      selectedInstitute: {},
      items: [],
      headers: [
        {
          text: 'Room Name',
          value: 'roomName'
        },
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Sharing Departments',
          value: 'sharingDepartments',
          sortable: false
        },
        {
          text: 'Class Room',
          value: 'classRoom'
        },
        {
          text: 'Office Room',
          value: 'office'
        },
        {
          text: 'Practical Lab',
          value: 'practicalLab'
        },
        {
          text: 'Conference Hall',
          value: 'conferenceHall'
        },
        {
          text: 'Meeting Room',
          value: 'meetingRoom'
        },
        {
          text: 'Special Room',
          value: 'specialRoom'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      search: '',
      selectedRooms: [],
      selectedRoom: {},
      buildings: [],
      selectedBuilding: {},
      selectedBuildingId: '',
      addingRoom: false,
      departments: [],
      editing: false,
      addingBuilding: false,
      tempBuildingName: '',
      showBuildingDeletingDialog: false,
      tempBuildingSelectedForDeleting: {}
    }
  },
  created () {
    this.loading = true
    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Reference Infrastructure'
    )
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.userData = this.$store.getters['user/get_userData']
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(
      this
    )
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(
      this
    )
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      this.editing = false
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.departments = await this.academicReferenceRepositoryInstance.getDepartments(
          objToPush
        )
        this.buildings = await this.infrastructureReferenceRepositoryInstance.getBuildings(
          objToPush
        )
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async selectBuilding (building) {
      this.loading = true
      this.selectedBuilding = building
      this.selectedBuildingId = building.buildingId
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingId: this.selectedBuildingId,
        buildingName: this.selectedBuilding.buildingName
      }
      try {
        this.items = await this.infrastructureReferenceRepositoryInstance.getRooms(
          objToPush
        )
      } catch (err) {
        console.log(err)
        this.items = []
      }
      this.loading = false
    },
    selectSharingDepartment (dept) {
      this.loading = true
      if (this.tempRoomSharingDepartments.includes(dept)) {
        this.tempRoomSharingDepartments = arrayOps.removeFromArray(
          this.tempRoomSharingDepartments,
          this.tempRoomSharingDepartments.indexOf(dept)
        )
      } else {
        if (
          !this.tempRoomSharingDepartments.includes(this.tempRoomDepartment)
        ) {
          this.tempRoomSharingDepartments.push(dept)
        }
      }
      this.loading = false
    },
    async saveRoom () {
      this.loading = true
      if (this.selectedBuildingId) {
        if (this.editing === true) {
          const roomIndex = this.items.findIndex(
            room => room.roomId === this.selectedRoom.roomId
          )
          if (this.tempRoomName !== this.items[roomIndex].roomName) {
            try {
              const objToPush = {
                instituteId: this.selectedInstitute,
                buildingId: this.selectedBuildingId,
                roomId: this.selectedRoom.roomId,
                roomName: this.tempRoomName
              }
              this.tempRoomName = await this.infrastructureReferenceRepositoryInstance.updateRoomName(
                objToPush
              )
              this.items[roomIndex].roomName = this.tempRoomName
            } catch (err) {
              console.log(err)
              showStatus('Failed to update room name.', 1000, 'error', this)
            } finally {
              this.tempRoomName = ''
            }
          }
          if (this.tempRoomDepartment !== this.items[roomIndex].department) {
            try {
              const objToPush = {
                instituteId: this.selectedInstitute,
                buildingId: this.selectedBuildingId,
                roomId: this.selectedRoom.roomId,
                department: this.tempRoomDepartment
              }
              this.tempRoomDepartment = await this.infrastructureReferenceRepositoryInstance.updateRoomDepartment(
                objToPush
              )
              this.items[roomIndex].department = this.tempRoomDepartment
            } catch (err) {
              showStatus(
                'Failed to update room department.',
                1000,
                'error',
                this
              )
              console.log(err)
            } finally {
              this.tempRoomDepartment = ''
            }
          }
          if (
            !arrayOps.compareArrays(
              this.tempRoomSharingDepartments,
              this.items[roomIndex].sharingDepartments
            )
          ) {
            try {
              const objToPush = {
                instituteId: this.selectedInstitute,
                buildingId: this.selectedBuildingId,
                roomId: this.selectedRoom.roomId,
                sharingDepartments: this.tempRoomSharingDepartments
              }
              this.tempRoomSharingDepartments = await this.infrastructureReferenceRepositoryInstance.updateSharingDepartments(
                objToPush
              )
              this.items[
                roomIndex
              ].sharingDepartments = this.tempRoomSharingDepartments
            } catch (err) {
              showStatus(
                'Failed to update room sharing departments.',
                1000,
                'error',
                this
              )
              console.log(err)
            } finally {
              this.tempRoomSharingDepartments = []
            }
          }
          try {
            const objToPush = {
              instituteId: this.selectedInstitute,
              buildingId: this.selectedBuildingId,
              roomId: this.selectedRoom.roomId,
              type: {
                classRoom: this.tempRoomClassRoom,
                practicalLab: this.tempRoomPracticalLab,
                office: this.tempRoomOffice,
                conferenceHall: this.tempRoomConferenceHall,
                meetingRoom: this.tempRoomMeetingRoom,
                specialRoom: this.tempRoomSpecialRoom
              }
            }
            const response = await this.infrastructureReferenceRepositoryInstance.updateType(
              objToPush
            )
            this.items[roomIndex].isClassRoom = response.classRoom
            this.items[roomIndex].isPracticalLab = response.practicalLab
            this.items[roomIndex].isOfficeRoom = response.office
            this.items[roomIndex].isConferenceHall = response.conferenceHall
            this.items[roomIndex].isMeetingRoom = response.meetingRoom
            this.items[roomIndex].isSpecialRoom = response.specialRoom
          } catch (err) {
            showStatus('Failed to update room types.', 1000, 'error', this)
            console.log(err)
          } finally {
            this.tempRoomClassRoom = false
            this.tempRoomPracticalLab = false
            this.tempRoomOffice = false
            this.tempRoomConferenceHall = false
            this.tempRoomMeetingRoom = false
            this.tempRoomSpecialRoom = false
          }
          this.addingRoom = false
        } else {
          const objToPush = {
            instituteId: this.selectedInstitute,
            buildingId: this.selectedBuildingId,
            roomName: this.tempRoomName,
            department: this.tempRoomDepartment,
            sharingDepartments: this.tempRoomSharingDepartments,
            type: {
              classRoom: this.tempRoomClassRoom,
              practicalLab: this.tempRoomPracticalLab,
              office: this.tempRoomOffice,
              conferenceHall: this.tempRoomConferenceHall,
              meetingRoom: this.tempRoomMeetingRoom,
              specialRoom: this.tempRoomSpecialRoom
            }
          }
          try {
            const room = await this.infrastructureReferenceRepositoryInstance.createRoom(
              objToPush
            )
            this.selectedRooms.push(room)
            showStatus('Created room successfully.', 1000, 'success', this)
            this.addingRoom = false
          } catch (err) {
            showStatus('Failed to create room.', 1000, 'error', this)
            console.log(err)
          }
        }
      }
      this.selectBuilding(this.selectedBuilding)
      this.loading = false
    },
    selectDepartment (dept) {
      this.loading = true
      if (this.tempRoomSharingDepartments.includes(dept)) {
        this.tempRoomSharingDepartments = arrayOps.removeFromArray(
          this.tempRoomSharingDepartments,
          this.tempRoomSharingDepartments.indexOf(dept)
        )
      }
      this.loading = false
    },
    startEditingRoom (room) {
      this.selectedRoom = room
      this.tempRoomName = this.selectedRoom.roomName
      this.tempRoomDepartment = this.selectedRoom.department
      this.tempRoomBuilding = this.selectedRoom.building
      this.tempRoomSharingDepartments = this.selectedRoom.sharingDepartments
      this.tempRoomClassRoom = this.selectedRoom.type.classRoom
      this.tempRoomPracticalLab = this.selectedRoom.type.practicalLab
      this.tempRoomOffice = this.selectedRoom.type.office
      this.tempRoomConferenceHall = this.selectedRoom.type.conferenceHall
      this.tempRoomMeetingRoom = this.selectedRoom.type.meetingRoom
      this.tempRoomSpecialRoom = this.selectedRoom.type.specialRoom
      this.editing = true
      this.addingRoom = true
    },
    async deleteRoom (room) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        roomId: room.roomId,
        roomName: room.roomName,
        buildingId: this.selectedBuilding.buildingId
      }
      try {
        await this.infrastructureReferenceRepositoryInstance.deleteRoom(
          objToPush
        )
        showStatus('Room deleted successfully.', 1000, 'success', this)
        const roomIndex = this.selectedRooms.findIndex(
          roomObject => roomObject.roomId === room.roomId
        )
        if (roomIndex > -1) {
          this.selectedRooms = arrayOps.removeFromArray(
            this.selectedRooms,
            roomIndex
          )
        }
      } catch (err) {
        showStatus('Failed to delete room.', 1000, 'error', this)
        console.log(err)
      }
      this.selectedRooms = []
      this.selectBuilding(this.selectedBuilding)
      this.loading = false
    },
    async addingRoomStep1 () {
      this.tempRoomName = ''
      this.tempRoomDepartment = ''
      this.tempRoomSpecialRoom = false
      this.tempRoomMeetingRoom = false
      this.tempRoomConferenceHall = false
      this.tempRoomOffice = false
      this.tempRoomPracticalLab = false
      this.tempRoomClassRoom = false
      this.addingRoom = true
    },
    async removeMultipleRooms () {
      for (let i = 0; i < this.selectedRooms.length; i++) {
        await this.deleteRoom(this.selectedRooms[i])
      }
      this.selectedRooms = []
    },
    async addBuilding () {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingName: this.tempBuildingName
      }
      try {
        const building = await this.infrastructureReferenceRepositoryInstance.createBuilding(
          objToPush
        )
        this.buildings.push(building)
        this.tempBuildingName = ''
        this.addingBuilding = false
        this.selectBuilding(building)
        showStatus('Building created successfully.', 1000, 'success', this)
      } catch (err) {
        showStatus('Failed to create building.', 1000, 'error', this)
        console.log(err)
      }
      this.loading = false
    },
    async startDeletingBuilding (building) {
      this.tempBuildingSelectedForDeleting = building
      this.showBuildingDeletingDialog = true
    },
    async deleteBuilding (building) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingId: building.buildingId,
        buildingName: building.buildingName
      }
      try {
        await this.infrastructureReferenceRepositoryInstance.deleteBuilding(
          objToPush
        )
        const buildingIndex = this.buildings.findIndex(
          buildingObject => buildingObject.buildingId === building.buildingId
        )
        if (buildingIndex > -1) {
          this.buildings = arrayOps.removeFromArray(
            this.buildings,
            buildingIndex
          )
        }
        this.tempBuildingName = ''
        this.addingBuilding = false
        this.showBuildingDeletingDialog = false
        showStatus('Building deleted successfully.', 1000, 'success', this)
      } catch (err) {
        showStatus('Failed to delete building.', 1000, 'error', this)
        console.log(err)
      }
      this.loading = false
    }
  }
}
</script>
<style scoped src="./activityReferenceInfrastructure.css"></style>
