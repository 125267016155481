import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Timetable'

class Timetable {
  constructor (context) {
    this.context = context
  }

  async createTimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlot
  }

  async getTimetableOfADivisionInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }

  async getFacultiesFreeInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesFreeInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.availableFaculties
  }

  async getLocationsFreeInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLocationsFreeInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.availableLocations
  }

  async updateFacultyInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.updateFacultyInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.uId
  }

  async updateRoomInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.updateRoomInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.roomId
  }

  async deleteTimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteTimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getAllocationsOfAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsOfAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }
}

export default Timetable
