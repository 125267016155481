<template src='./activityManageHoliday.html'>
</template>

<script>
import SemesterRepository from '../../Repository/Semester'
import inputContainer from '../../Components/inputContainer'
import LectureRepository from '../../Repository/Lecture'
import UserRepository from '../../Repository/User'
import SubjectRepository from '../../Repository/Subject'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import { conductableLectureTypes, days } from '../../Constants/Utils/Statics'
import { convertDate, convertTime } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityManageHoliday',
  components: {
    inputContainer
  },
  data () {
    return {
      semesters: [],
      semestersList: [],
      selectedLecture: {},
      confirmCancel: false,
      days: days,
      selectedSemesterName: '',
      loading: false,
      // selectedDivision: {},
      // showArrangeTimetableDialog: false,
      tempStartDate: '',
      tempEndDate: '',
      tempReason: '',
      showHolidayDialog: false,
      selection: [],
      selectedDate: '',
      selectedDepartment: '',
      departments: [],
      selectedInstitute: '',
      selectedSemester: {},
      userData: {},
      divs: [],
      selectedDivisions: [],
      lecturesMapping: {},
      dataFetched: false,
      showCancelLecturesDialog: false,
      selectedDivisionForCancellingLectures: {},
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Lectures',
          value: 'lectures'
        },
        {
          text: 'Operations',
          value: 'actions'
        }
      ],
      headersForCancellingLectures: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Faculty',
          value: 'facultyName'
        },
        {
          text: 'Subject',
          value: 'subjectName'
        },
        {
          text: 'Location',
          value: 'locationName'
        },
        {
          text: 'Operations',
          value: 'actions'
        }
      ]
    }
  },
  created () {
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.$store.commit('liveData/set_selectedActivityName', 'Manage Holiday')
    this.userData = this.$store.getters['user/get_userData']
    const todaysDate = new Date()
    this.selectedDate = [todaysDate.getFullYear(), ('0' + (todaysDate.getMonth() + 1)).slice(-2), ('0' + todaysDate.getDate()).slice(-2)].join('-')
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.departments = []
        this.divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        console.log(this.divs)
        this.divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async selectDate () {
      this.dataFetched = false
      this.selectedDepartment = false
      this.selection = []
    },
    async selectDepartment () {
      this.selection = []
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        dateOfLecturesNeeded: new Date(this.selectedDate).toISOString(),
        department: this.selectedDepartment
      }
      let lectures = []
      try {
        lectures = await this.lectureRepositoryInstance.getLecturesOfADepartmentForADay(objToPush)
        console.log(lectures)
      } catch (err) {
        lectures = []
        console.log(err)
      }
      lectures.forEach((lecture) => {
        if (!(this.lecturesMapping[lecture.department + '_' + lecture.courseYear + '_' + lecture.division])) {
          this.lecturesMapping[lecture.department + '_' + lecture.courseYear + '_' + lecture.division] = {
            isAlreadyAHoliday: false,
            lectures: []
          }
        }
        // if (conductableLectureTypes.includes(lecture.status)) {
        this.lecturesMapping[lecture.department + '_' + lecture.courseYear + '_' + lecture.division].lectures.push(lecture)
        // }
      })

      this.selectedDivisions = this.divs.filter((div) => div.department === this.selectedDepartment)
      this.selectedDivisions.forEach((elem) => {
        elem.id = elem.department + '_' + elem.courseYear + '_' + elem.division
      })
      console.log(this.selectedDivisions, lectures, this.lecturesMapping)

      Object.keys(this.lecturesMapping).forEach((lecturesMappingKey) => {
        this.lecturesMapping[lecturesMappingKey].isAlreadyAHoliday = this.lecturesMapping[lecturesMappingKey].lectures.filter((lecture) => lecture.status === 5).length === this.lecturesMapping[lecturesMappingKey].lectures.length
      })

      const promises = Object.keys(this.lecturesMapping).map((lecturesMappingKey) => this.getDetailsOfLectures(lecturesMappingKey))
      console.log(promises)
      await Promise.allSettled(promises)
      this.dataFetched = true
      this.loading = false
    },
    async getDetailsOfLectures (lecturesMappingKey) {
      for (let i = 0; i < this.lecturesMapping[lecturesMappingKey].lectures.length; i++) {
        try {
          console.log('faculty Name', this.lecturesMapping[lecturesMappingKey].lectures[i].uId)
          if (!this.lecturesMapping[lecturesMappingKey].lectures[i].facultyName) {
            this.lecturesMapping[lecturesMappingKey].lectures[i].facultyName = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.lecturesMapping[lecturesMappingKey].lectures[i].uId })
          }
        } catch (err) {
          console.log(err)
          this.lecturesMapping[lecturesMappingKey].lectures[i].facultyName = ''
        }

        try {
          if (!this.lecturesMapping[lecturesMappingKey].lectures[i].subjectName) {
            this.lecturesMapping[lecturesMappingKey].lectures[i].subjectName = await this.subjectRepositoryInstance.getSubjectName({
              instituteId: this.lecturesMapping[lecturesMappingKey].lectures[i].instituteId,
              semId: this.lecturesMapping[lecturesMappingKey].lectures[i].semId,
              subjectId: this.lecturesMapping[lecturesMappingKey].lectures[i].subjectId
            })
          }
        } catch (err) {
          console.log(err)
          this.lecturesMapping[lecturesMappingKey].lectures[i].subjectName = ''
        }

        try {
          if (!this.lecturesMapping[lecturesMappingKey].lectures[i].locationName) {
            this.lecturesMapping[lecturesMappingKey].lectures[i].locationName = await this.infrastructureReferenceRepositoryInstance.getRoomName({
              instituteId: this.lecturesMapping[lecturesMappingKey].lectures[i].instituteId,
              roomId: this.lecturesMapping[lecturesMappingKey].lectures[i].roomId
            })
          }
        } catch (err) {
          console.log(err)
          this.lecturesMapping[lecturesMappingKey].lectures[i].locationName = ''
        }
      }
    },
    async declareHoliday () {
      this.loading = true
      if (new Date(this.tempStartDate) > new Date(this.tempEndDate)) {
        window.alert('Start Date should be prior to end date.')
      } else {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          startDateOfHoliday: new Date(this.tempStartDate).toISOString(),
          endDateOfHoliday: new Date(this.tempEndDate).toISOString(),
          reason: this.tempReason
        }
        for (let i = 0; i < this.selection.length; i++) {
          try {
            objToPush.department = this.selection[i].department
            objToPush.courseYear = this.selection[i].courseYear
            objToPush.division = this.selection[i].division

            await this.lectureRepositoryInstance.declareHoliday(objToPush)
          } catch (err) {
            console.log(err)
          }
        }
        console.log(objToPush, this.selection)
        this.tempReason = ''
        this.showHolidayDialog = false
        window.alert('Holiday declared successfully!')
        this.selectDepartment()
      }
    },
    async cancelLecture (item) {
      if (item === 'all') {
        let promises = []
        // for (let i = 0; i < .length; i++) {
        //   promises.push(this.cancelLecture(this.lecturesMapping[this.selectedDivisionForCancellingLectures.department + '_' + this.selectedDivisionForCancellingLectures.courseYear + '_' + this.selectedDivisionForCancellingLectures.division].lectures[i]))
        // }
        promises = this.lecturesMapping[this.selectedDivisionForCancellingLectures.department + '_' + this.selectedDivisionForCancellingLectures.courseYear + '_' + this.selectedDivisionForCancellingLectures.division].lectures.map((lecture) => {
          return this.cancelLecture(lecture)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        console.log(allSettledPromises)
      } else {
        try {
          item.reason = this.tempReason
          await this.lectureRepositoryInstance.cancelLecture(item)
          const lecturesMappingKey = this.selectedDivisionForCancellingLectures.department + '_' + this.selectedDivisionForCancellingLectures.courseYear + '_' + this.selectedDivisionForCancellingLectures.division
          const index = this.lecturesMapping[lecturesMappingKey].lectures.indexOf(item)
          this.lecturesMapping[lecturesMappingKey].lectures = arrayOps.removeFromArray(this.lecturesMapping[lecturesMappingKey].lectures, index)
          showStatus('Lecture has been cancelled.', 1000, 'success', this)
          this.dismissCancelLectureDialog()
          this.lecturesMapping = JSON.parse(JSON.stringify(this.lecturesMapping))
          return 'Lecture has been cancelled.'
        } catch (err) {
          console.log(err)
          showStatus('Lecture could not be cancelled.', 1000, 'error', this)
          return 'Lecture could not be cancelled.'
        }
      }
    },
    async cancelLectureStep1 (item) {
      this.selectedLecture = item
      console.log(item)
      this.tempReason = ''
      this.confirmCancel = true
    },
    async arrangeLectures (item, day) {
      this.selectedDivision = item
      this.showArrangeTimetableDialog = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: item.department,
        courseYear: item.courseYear,
        division: item.division,
        day: day,
        dateOfLecturesToBeCreated: new Date(this.selectedDate).toISOString()
      }
      console.log(objToPush)
    },
    isLectureConductable (item) {
      return conductableLectureTypes.includes(item.status)
    },
    showLecturesToCancel (item) {
      this.showCancelLecturesDialog = true
      this.selectedDivisionForCancellingLectures = item
    },
    dismissCancelLectureDialog () {
      this.showCancelLecturesDialog = false
      this.selectedDivisionForCancellingLectures = {}
    },
    convertDate (dateTime) {
      return convertDate(dateTime)
    },
    convertTime (dateTime) {
      return convertTime(dateTime)
    }
  }
}
</script>

<style src='./activityManageHoliday.css'>
</style>
