import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/User";

class User {
  constructor(context) {
    this.context = context;
  }

  async createUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createUser,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.user;
  }

  async addEmail(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.postRequest(ApiNames.addEmail, data);
    this.context.$store.commit("liveData/set_loading", false);
  }
  async addAndUpdateUserName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    await networkManager.postRequest(ApiNames.addAndUpdateUserName, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async addPhone(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.postRequest(ApiNames.addPhone, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async login(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(ApiNames.login, data);
    this.context.$store.commit("user/set_userData", { uId: response.uId,email:data.email });
    this.context.$store.commit("liveData/set_loading", false);
  }

  async createAdmissionUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(ApiNames.createAdmissionUser, data);
    this.context.$store.commit("user/set_userData", { uId: response.uId,email:data.email });
    this.context.$store.commit("liveData/set_loading", false);
  }
  async loginAdmissionUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(ApiNames.loginAdmissionUser, data);
    this.context.$store.commit("user/set_userData", { uId: response.uId,email:data.email });
    this.context.$store.commit("liveData/set_loading", false);
  }

  async getUserProfile(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getUserProfile,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.user;
  }

  async getUserAuthentication(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getUserAuthentication,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return { emails: response.emails, phones: response.phones, userName: response.userName};
  }

  async getUserAuthenticationByEmail(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getUserAuthenticationByEmail,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.userAuthentications;
  }

  async getFullNameOfUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    // console.log(data)
    const response = await networkManager.getRequest(
      ApiNames.getFullNameOfUser,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.fullName;
  }

  async getFullNameOfUsers(data) {
    this.context.$store.commit("liveData/set_loading", true);
    // console.log(data)
    const response = await networkManager.getRequest(
      ApiNames.getFullNameOfUsers,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.fullNames;
  }

  async getParentsOfAUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getParentsOfAUser,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.parents;
  }

  async updateFirstName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateFirstName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.firstName;
  }

  async updateMiddleName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateMiddleName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateLastName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateLastName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.lastName;
  }

  async updateDateOfBirth(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateDateOfBirth,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.dateOfBirth;
  }

  async updateGender(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateGender,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.gender;
  }

  async updateEmail(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateEmail,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.email;
  }

  async updatePhone(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updatePhone,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.phone;
  }

  async deleteEmail(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.deleteRequest(ApiNames.deleteEmail, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deletePhone(data) {
    this.context.$store.commit("liveData/set_loading", true);
    await networkManager.deleteRequest(ApiNames.deletePhone, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async loginWithUserNameAndPassword(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(JSON.stringify(data));
    const response = await networkManager.postRequest(
      ApiNames.loginWithUserNameAndPassword,
      data
    );
    return response;
    this.context.$store.commit("liveData/set_loading", false);
  }

  async passwordUpdate(data) {
    try {
      this.context.$store.commit("liveData/set_loading", true);
      await networkManager.putRequest(ApiNames.passwordUpdate, data);
      this.context.$store.commit("liveData/set_loading", false);
      return { error: false };
    } catch (e) {
      console.error(`Error resetting password: ${e}`);
      if (e.message === "Request failed with status code 400") {
        return { error: true, message: "Current password does not match" };
      }
    } finally {
      this.context.$store.commit("liveData/set_loading", false);
    }
  }

  async forgotPassword(data) {
    try {
      this.context.$store.commit("liveData/set_loading", true);
      console.log(JSON.stringify(data));
      await networkManager.postRequest(ApiNames.forgotPassword, data);
      this.context.$store.commit("liveData/set_loading", false);
    } catch (e) {
      console.error(`Error in forgot password: ${e}`);
    } finally {
      this.context.$store.commit("liveData/set_loading", false);
    }
  }
}

export default User;
