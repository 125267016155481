<template src='./activityEditSemesterHolidays.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import SemesterRepository from '../../Repository/Semester'
import { convertDate, convertFromYYYYMMDDToDate } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityEditSemesterHolidays',
  data () {
    return {
      loading: false,
      selectedSemester: '',
      items: [],
      selection: [],
      selectionType: 'leaf',
      tempDate: '',
      tempTitle: '',
      tempStatus: '',
      tableItems: [],
      statusList: ['Confirm', 'Non Confirm'],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Status',
          value: 'confirmed'
        },
        {
          text: '',
          value: 'actions'
        }
      ]
    }
  },
  props: [
    'prop_selectedDepartment',
    'tree'
  ],
  components: {
    inputContainer,
    smallButton
  },
  created () {
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.items = this.tree
    if (this.prop_selectedDepartment) {
      this.items
        .filter((item) => item.name === this.prop_selectedDepartment)
        .forEach((item) => {
          this.selection.push(item)
        })
    }
    this.loading = false
    this.fetchDetails()
  },
  methods: {
    async fetchDetails () {
      this.loading = true
      this.tableItems = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        objToPush.department = this.selection[i].name
        const holidays = await this.semesterRepositoryInstance.getHolidaysOfADepartmentOfASemester(objToPush)
        console.log(holidays)
        for (let j = 0; j < holidays.length; j++) {
          holidays[j].department = this.selection[i].name
          holidays[j].date = convertDate(holidays[j].date)
          holidays[j].confirmed = holidays[j].confirmed ? 'Confirm' : 'Non Confirm'
          this.tableItems.push(holidays[j])
        }
      }
      console.log(this.tableItems)
      this.loading = false
    },
    async deleteHoliday (holiday) {
      this.loading = true
      console.log(holiday)
      const holidaysOfADepartment = this.tableItems.filter((holidayObject) => {
        return holidayObject.department === holiday.department &&
        holidayObject.title !== holiday.title &&
        holidayObject.date !== holiday.date
      }).map((holidayObject) => {
        return {
          title: holidayObject.title,
          date: holidayObject.date,
          confirmed: holidayObject.confirmed
        }
      })
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          semName: this.selectedSemester.semName,
          department: holiday.department,
          holidays: holidaysOfADepartment
        }
        await this.semesterRepositoryInstance.updateHolidaysOfADepartmentOfASemester(objToPush)
        showStatus('Holiday successfully deleted!', 1500, 'success', this)
        const indexOfRemovedHoliday = this.tableItems.findIndex((holidayObject) => {
          return holidayObject.department === holiday.department &&
          holidayObject.title === holiday.title &&
          holidayObject.date === holiday.date
        })
        if (indexOfRemovedHoliday > -1) {
          this.tableItems = arrayOps.removeFromArray(this.tableItems, indexOfRemovedHoliday)
        }
      } catch (err) {
        showStatus('Could not connect to server!', 1500, 'error', this)
        console.log(err)
      }
      this.loading = false
    },
    async addHoliday () {
      this.loading = true
      for (let i = 0; i < this.selection.length; i++) {
        const holidaysOfADepartment = this.tableItems.filter((holidayObject) => holidayObject.department === this.selection[i].name)
          .map((holidayObject) => {
            return {
              title: holidayObject.title,
              date: holidayObject.date,
              confirmed: holidayObject.confirmed === 'Confirm'
            }
          })
        try {
          const holiday = {
            title: this.tempTitle,
            date: convertFromYYYYMMDDToDate(this.tempDate),
            confirmed: this.tempStatus === 'Confirm'
          }

          holidaysOfADepartment.push(holiday)
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            semName: this.selectedSemester.semName,
            department: this.selection[i].name,
            holidays: holidaysOfADepartment
          }
          await this.semesterRepositoryInstance.updateHolidaysOfADepartmentOfASemester(objToPush)
          showStatus('Holiday successfully added!', 1500, 'success', this)
          holiday.department = this.selection[i].name
          holiday.date = convertDate(holiday.date)
          holiday.confirmed = holiday.confirmed ? 'Confirm' : 'Non Confirm'
          this.tableItems.push(holiday)
        } catch (err) {
          showStatus('Could not connect to server!', 1500, 'error', this)
          console.log(err)
        }
      }
      this.loading = false
    }
  },
  destroyed () {
    console.log('Destroyed')
  }
}
</script>
<style src='./activityEditSemesterHolidays.css' scoped>
</style>
