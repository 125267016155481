import firebase from 'firebase'

var config = {
  apiKey: 'AIzaSyBEv8iqVOBCyAEOUzqNYNPF6CfAf5GPXBg',
  authDomain: 'finalinprospect.firebaseapp.com',
  databaseURL: 'https://finalinprospect.firebaseio.com',
  projectId: 'finalinprospect',
  storageBucket: 'finalinprospect.appspot.com',
  messagingSenderId: '787468078530'
}

const auth = {
  context: null,
  messaging: null,

  init (context) {
    // let self = this
    this.context = context

    firebase.initializeApp(config)

    firebase.auth().onAuthStateChanged((user) => {
      // const self = this
      if (user) {
        user.getIdToken().then((authToken) => {
          this.context.$store.dispatch('user/set_user', user)
          this.context.$store.dispatch('user/set_authToken', authToken)
          this.context.$router.push('/activitySetup')
        })
      }
    })
  },
  getFirebaseObject () {
    return firebase
  },
  login () {
    var provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('Profile')
    provider.addScope('email')
    return firebase.auth().signInWithPopup(provider)
      .catch(function (err) {
        console.log(err)
        alert('Something went wrong while logging in using Gmail. Please try again.')
      })
  },
  user () {
    return this.context ? firebase.auth().currentUser : null
  },
  logout (context) {
    firebase.auth().signOut()
      .then(function () {
        context.$router.push('/activityWelcome')
      })
      .catch(function (err) {
        console.log(err)
      })
  }
}

export default auth
