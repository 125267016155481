<template src='./activityEditStudentsRegistration.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import { subjectTypes } from '../../Constants/Utils/Statics'
import UserRepository from '../../Repository/User'
import SubjectRepository from '../../Repository/Subject'
import SemesterUserRepository from '../../Repository/SemesterUser'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityEditStudentsRegistration',
  components: {
    inputContainer
  },
  data () {
    return {
      departments: [],
      selectedDepartment: '',
      courseYears: [],
      selectedCourseYear: '',
      divisions: [],
      selectedDivision: '',
      subjects: [],
      selectedSubject: '',
      electiveSubjects: {},
      students: [],
      selected: [],
      selectedBackup: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Roll Number',
          value: 'rollNumber'
        }
      ],
      userMappings: {},
      snack: false,
      timeout: 2000,
      loading: false,
      subjectTypes: subjectTypes
    }
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  created () {
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']

    this.userRepositoryInstance = new UserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)

    if (this.tree) {
      this.departments = this.tree.map((child) => child.name)
      // for (let i = 0; i < this.tree.length; i++) {
      //   this.departments.push(this.tree[i].name)
      // }
    } else {
      this.departments = []
    }
    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.selectedDepartment = this.prop_selectedDepartment
      this.getCourseYears()
      this.selectedCourseYear = this.prop_selectedCourseYear
      this.getDivisions()
      this.selectedDivision = this.prop_selectedDivision
    }
    this.loading = false
  },
  methods: {
    async getCourseYears () {
      this.loading = true
      this.subjects = []
      this.electiveSubjects = {}
      this.selectedSubject = ''
      this.selectedCourseYear = ''
      this.courseYears = []
      this.selectedDivision = ''
      this.divisions = []
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      this.courseYears = this.tree[indexOfDepartment].children.map((child) => child.name)
      this.loading = false
    },
    async getDivisions (div) {
      this.loading = true
      this.selectedDivision = ''
      this.divisions = []
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      const indexOfCourseYear = this.courseYears.indexOf(this.selectedCourseYear)
      this.divisions = this.tree[indexOfDepartment].children[indexOfCourseYear].children.map((child) => child.name)

      // if (div) {
      //   console.log(div)
      //   this.selectedDivision = div
      // }

      this.getSubjects()
      this.loading = false
    },
    async getSubjects () {
      this.loading = true
      this.subjects = []
      this.electiveSubjects = {}
      this.selectedSubject = ''

      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear
      }

      console.log(objToPush)

      try {
        this.subjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)

        console.log(this.subjects)
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'success', this)
        console.log(err)
      }

      for (let i = 0; i < this.subjects.length; i++) {
        this.electiveSubjects[this.subjects[i].subjectName + ' | ' + this.subjectTypes[this.subjects[i].subjectType]] = this.subjects[i]
      }

      this.selectedSubject = Object.keys(this.electiveSubjects)[0]
      console.log(this.subjects)

      this.loading = false
    },
    async getStudents () {
      this.loading = true
      this.students = []
      this.selected = []
      this.selectedBackup = []

      if (
        this.students.filter((student) =>
          student.department === this.selectedDepartment &&
          student.courseYear === this.selectedCourseYear &&
          student.division === this.selectedDivision
        ).length === 0
      ) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision
        }

        console.log(objToPush)
        this.students = []
        try {
          const studentObjects = await this.semesterUserRepositoryInstance.getStudentsOfADivision(objToPush) || []

          for (let i = 0; i < studentObjects.length; i++) {
            try {
              const nameOfStudent = await this.userRepositoryInstance.getFullNameOfUser({ uId: studentObjects[i].uId })

              if (nameOfStudent) {
                console.log(studentObjects[i])
                this.students.push(
                  {
                    uId: studentObjects[i].uId,
                    name: nameOfStudent,
                    rollNumber: studentObjects[i].rollNumber,
                    department: studentObjects[i].department,
                    courseYear: studentObjects[i].courseYear,
                    division: studentObjects[i].division,
                    batch: studentObjects[i].batch,
                    subjects: studentObjects[i].subjects
                  }
                )
              }
              if (studentObjects[i].subjects.indexOf(this.electiveSubjects[this.selectedSubject].subjectId) > -1) {
                this.selected.push(this.students[this.students.length - 1])
              }
            } catch (err) {
              console.log(err)
            }
          }

          console.log(this.students)
          this.selectedBackup = JSON.parse(JSON.stringify(this.selected))
        } catch (err) {
          console.log(err)
          this.students = []
        }
      }
      this.loading = false
    },
    async saveChanges () {
      this.loading = true
      const selectedIds = this.selected.map((student) => student.uId)
      const selectedBackupIds = this.selectedBackup.map((student) => student.uId)

      const added = selectedIds.filter((uId) => !selectedBackupIds.includes(uId))
      const removed = selectedBackupIds.filter((uId) => !selectedIds.includes(uId))

      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision
      }

      for (let i = 0; i < this.students.length; i++) {
        if (added.includes(this.students[i].uId)) {
          try {
            this.students[i].subjects.push(this.electiveSubjects[this.selectedSubject].subjectId)

            objToPush.uId = this.students[i].uId
            objToPush.batch = this.students[i].batch
            objToPush.subjects = this.students[i].subjects
            await this.semesterUserRepositoryInstance.updateSubjectsOfAStudentInASemester(objToPush)
          } catch (err) {
            console.log(err)
            this.students[i].subjects.pop()
            this.selected.push(this.students[i])
          }
        } else if (removed.includes(this.students[i].uId)) {
          try {
            const indexOfSubject = this.students[i].subjects.indexOf(this.electiveSubjects[this.selectedSubject].subjectId)
            if (indexOfSubject > -1) {
              this.students[i].subjects = arrayOps.removeFromArray(this.students[i].subjects, indexOfSubject)
            }
            console.log(objToPush)
            objToPush.uId = this.students[i].uId
            objToPush.batch = this.students[i].batch
            objToPush.subjects = this.students[i].subjects
            await this.semesterUserRepositoryInstance.updateSubjectsOfAStudentInASemester(objToPush)
          } catch (err) {
            console.log(err)
            this.students[i].subjects.push(this.electiveSubjects[this.selectedSubject].subjectId)
            const index = this.selected.indexOf(this.students[i])
            this.selected = arrayOps.removeFromArray(this.selected, index)
          }
        }
      }

      this.selectedBackup = JSON.parse(JSON.stringify(this.selected))
      this.snack = true
      this.loading = false
    },
    discardChanges () {
      this.selected = JSON.parse(JSON.stringify(this.selectedBackup))
    }
  }
}
</script>
<style src='./activityEditStudentsRegistration.css' scoped>
</style>
