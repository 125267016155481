export default {
  createTimetableSlot: '/timetable/createSlot',
  getTimetableOfADivision: '/timetable/getTimetableSlotsOfADivision',
  getFacultiesFreeInATimetableSlot: '/timetable/getFacultiesFreeInATimetableSlot',
  getLocationsFreeInATimetableSlot: '/timetable/getLocationsFreeInATimetableSlot',
  updateFacultyInATimetableSlot: '/timetable/updateFaculty',
  updateRoomInATimetableSlot: '/timetable/updateRoom',
  deleteTimetableSlot: '/timetable/deleteSlot',
  getTimetableSlotsOfAFaculty: '/timetable/getTimetableSlotsOfAFaculty'
}
