<template src='./activityNotifications.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import NotificationRepository from '../../Repository/Notification'
import SemesterRepository from '../../Repository/Semester'
import { convertFromYYYYMMDDToDate } from '../../Services/Utils/DateTimeFormatter'
export default {
  name: 'activityNotifications',
  components: {
    inputContainer
  },
  data () {
    return {
      loading: false,
      datesWiseNotifications: {},
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      userData: null,
      selectedInstitute: '',
      selectedSemester: {},
      notifications: [],
      selectedDate: '',
      headers: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Description',
          value: 'description'
        }
      ]
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'My Notifications')
    this.notificationRepositoryInstance = new NotificationRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    console.log(this.userData, this.selectedInstitute)
    const date = new Date()
    this.selectedDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      this.loading = true
      this.notifications = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId,
        dateOfNotificationsNeeded: this.convertToISOString(convertFromYYYYMMDDToDate(this.selectedDate))
      }
      console.log(objToPush)
      try {
        const notifications = await this.notificationRepositoryInstance.getNotificationsForADay(objToPush)
        this.notifications = notifications || []
      } catch (err) {
        console.log(err)
        this.notifications = []
      }
      await this.processNotifications()
    },
    async processNotifications () {
      this.notifications.forEach((notification) => {
        const dateString = notification.dateTime.slice(0, 10)
        if (!this.datesWiseNotifications[dateString]) {
          this.datesWiseNotifications[dateString] = []
        }
        this.datesWiseNotifications[dateString].push(notification)
      })
      this.loading = false
    },
    milliToDate (milli) {
      const D = new Date(parseInt(milli))
      console.log(('0' + D.getDate()).slice(-2) + '/' + ('0' + (D.getMonth() + 1)).slice(-2) + '/' + D.getFullYear())
      return ('0' + D.getDate()).slice(-2) + '/' + ('0' + (D.getMonth() + 1)).slice(-2) + '/' + D.getFullYear()
    },
    convertToISOString (date) {
      try {
        const convertedDate = new Date(date.valueOf())
        // IST is 5.30 hours ahead of UTC
        convertedDate.setHours(5, 30, 0, 0)
        return convertedDate.toISOString()
      } catch (err) {
        console.log(err)
        return undefined
      }
    }
  }
}
</script>

<style src='./activityNotifications.css'>
</style>
