var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityEditSemesterUsers"},[_c('v-card',{staticStyle:{"margin-top":"20px","box-shadow":"2px 2px 20px rgba(0, 0, 0, 0.1)"}},[_c('v-card-title',[_vm._v(" Users List "),_c('inputContainer',{staticStyle:{"margin-left":"20px","width":"40vw"}},[_c('v-text-field',{attrs:{"solo":"","flat":"","background-color":"transparent","label":"Search ID"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{staticStyle:{"color":"white","margin-left":"10px"},attrs:{"color":"#FF4F1F"},on:{"click":_vm.gotoTransferUsers}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add")],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"10px"},attrs:{"outlined":""},on:{"click":function($event){}}},on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter")],1)]}}])},[_c('v-row',{staticStyle:{"background-color":"#fff","width":"250px"}},[_c('v-col',[_c('v-treeview',{attrs:{"items":_vm.academicTreeForFilter,"selection-type":_vm.selectionType,"selectable":"","return-object":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)],1),_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"outlined":""},on:{"click":_vm.onRefresh}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Refresh")],1)],1),_c('v-row',{staticClass:"subcontainer"},[_c('v-col',{attrs:{"cols":"12","disabled":_vm.loading === true}},[_c('v-radio-group',{attrs:{"row":"","disabled":_vm.loading},on:{"change":_vm.setupActivity},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[_c('v-radio',{attrs:{"label":"Faculty","value":"faculty","color":"#ff4f1f"}}),_c('v-radio',{attrs:{"label":"Students","value":"students","color":"#ff4f1f"}})],1)],1)],1),(_vm.userType ==='students')?_c('v-data-table',{attrs:{"loading":_vm.loading,"color":"#ff4f1f","headers":_vm.headersForStudents,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.uId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userMappings[item.uId])+" ")]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":Object.keys(_vm.referenceAcademicTree)},on:{"change":function($event){return _vm.updateDepartment(item)}},model:{value:(item.department),callback:function ($$v) {_vm.$set(item, "department", $$v)},expression:"item.department"}})]}},{key:"item.courseYear",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":Object.keys(_vm.referenceAcademicTree[item.department])},on:{"change":function($event){return _vm.updateCourseYear(item)}},model:{value:(item.courseYear),callback:function ($$v) {_vm.$set(item, "courseYear", $$v)},expression:"item.courseYear"}})]}},{key:"item.division",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.referenceAcademicTree[item.department][item.courseYear]},on:{"change":function($event){return _vm.updateDivision(item)}},model:{value:(item.division),callback:function ($$v) {_vm.$set(item, "division", $$v)},expression:"item.division"}})]}},{key:"item.batch",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number"},on:{"input":function($event){return _vm.updateBatch(item)}},model:{value:(item.batch),callback:function ($$v) {_vm.$set(item, "batch", $$v)},expression:"item.batch"}})]}},{key:"item.rollNumber",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"input":function($event){return _vm.updateRollNumber(item)}},model:{value:(item.rollNumber),callback:function ($$v) {_vm.$set(item, "rollNumber", $$v)},expression:"item.rollNumber"}})]}},{key:"item.examSeatNumber",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"input":function($event){return _vm.updateExamSeatNumber(item)}},model:{value:(item.examSeatNumber),callback:function ($$v) {_vm.$set(item, "examSeatNumber", $$v)},expression:"item.examSeatNumber"}})]}},{key:"item.instituteProvidedId",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"input":function($event){return _vm.updateInstituteProvidedId(item)}},model:{value:(item.instituteProvidedId),callback:function ($$v) {_vm.$set(item, "instituteProvidedId", $$v)},expression:"item.instituteProvidedId"}})]}}],null,false,2508629182)}):_c('v-data-table',{attrs:{"loading":_vm.loading,"color":"#ff4f1f","headers":_vm.headersForFaculties,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.uId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userMappings[item.uId])+" ")]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":Object.keys(_vm.referenceAcademicTree)},on:{"change":function($event){return _vm.updateDepartment(item)}},model:{value:(item.department),callback:function ($$v) {_vm.$set(item, "department", $$v)},expression:"item.department"}})]}},{key:"item.instituteProvidedId",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"input":function($event){return _vm.updateInstituteProvidedId(item)}},model:{value:(item.instituteProvidedId),callback:function ($$v) {_vm.$set(item, "instituteProvidedId", $$v)},expression:"item.instituteProvidedId"}})]}}])}),(_vm.importAll)?_c('ImportAllDialog',{attrs:{"dialog":_vm.importAll,"closeDialog":_vm.closeImportAllDialog,"tree":_vm.academicTreeForFilter,"getUsers":_vm.getUsers}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }