<template src='./activityEditSemesterDates.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import { convertDate, convertFromYYYYMMDDToDate } from '../../Services/Utils/DateTimeFormatter'
import showStatus from '../../NetworkManager/showStatus'
import SemesterRepository from '../../Repository/Semester'
export default {
  name: 'activityEditSemesterDates',
  data () {
    return {
      loading: false,
      selectedSemester: '',
      items: [],
      selection: [],
      selectionType: 'leaf',
      tempStartDate: '',
      tempEndDate: '',
      tableItems: [],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        }
      ]
    }
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer
  },
  created () {
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']
    this.items = this.tree
    this.semesterRepositoryInstance = new SemesterRepository(this)

    console.log(this.prop_selectedDepartment, this.prop_selectedCourseYear, this.prop_selectedDivision)

    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.items
        .filter((item) => item.name === this.prop_selectedDepartment)
        .forEach((department) => {
          department.children
            .filter((child) => child.name === this.prop_selectedCourseYear)
            .forEach((courseYear) => {
              courseYear.children
                .filter((child) => child.name === this.prop_selectedDivision)
                .forEach((division) => {
                  this.selection.push(division)
                })
            })
        })
    }
    console.log('selection', this.selection)
    this.loading = false
    this.fetchDetails()
  },
  methods: {
    async fetchDetails () {
      this.loading = true
      // console.log(this.selection)
      this.tableItems = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }

      for (let i = 0; i < this.selection.length; i++) {
        try {
          const tableItem = {
            department: this.selection[i].department,
            courseYear: this.selection[i].courseYear,
            division: this.selection[i].name,
            startDate: '',
            endDate: ''
          }
          objToPush.department = tableItem.department
          objToPush.courseYear = tableItem.courseYear
          objToPush.division = tableItem.division
          this.tableItems.push(tableItem)

          const response = await this.semesterRepositoryInstance.getDatesOfADivisionOfASemester(objToPush)
          tableItem.startDate = response.startDate ? convertDate(response.startDate.toString()) : ''
          tableItem.endDate = response.endDate ? convertDate(response.endDate.toString()) : ''
        } catch (err) {
          console.log(err)
        }
      }
      this.loading = false
      // console.log(this.tableItems)
    },
    async save () {
      this.loading = true
      console.log(this.tempStartDate, this.tempEndDate)
      const parsedStartDate = convertFromYYYYMMDDToDate(this.tempStartDate)
      const parsedEndDate = convertFromYYYYMMDDToDate(this.tempEndDate)

      if (parsedEndDate > parsedStartDate) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          semName: this.selectedSemester.semName,
          startDate: parsedStartDate,
          endDate: parsedEndDate
        }
        let successfulCall = 0
        for (let i = 0; i < this.selection.length; i++) {
          try {
            objToPush.department = this.selection[i].department
            objToPush.courseYear = this.selection[i].courseYear
            objToPush.division = this.selection[i].name
            objToPush.oldEndDate = this.selection[i].endDate ? convertFromYYYYMMDDToDate(convertDate(this.selection[i].endDate).split('-').reverse().join('-')) : undefined
            const response = await this.semesterRepositoryInstance.updateDatesOfADivisionInASemester(objToPush)
            successfulCall++
            this.selection[i].startDate = convertDate(response.startDate.toString())
            this.selection[i].endDate = convertDate(response.endDate.toString())

            const indexOfItem = this.tableItems.findIndex((item) => {
              return item.department === this.selection[i].department &&
              item.courseYear === this.selection[i].courseYear &&
              item.division === this.selection[i].name
            })

            if (indexOfItem > -1) {
              this.tableItems[indexOfItem].startDate = this.selection[i].startDate
              this.tableItems[indexOfItem].endDate = this.selection[i].endDate
            }
          } catch (err) {
            showStatus('Could not connect to server!', 1500, 'error', this)
            console.log(err)
          }
        }
        if (successfulCall === this.selection.length) {
          console.log('All Dates Updated Successfully!', 1500, 'success', this)
          showStatus('All Dates Updated Successfully!', 1500, 'success', this)
        }
      } else {
        showStatus('Selected Dates are invalid!', 1500, 'error', this)
        console.log('Start date cannot be equal to or less than end date')
      }
      this.loading = false
    }
  },
  destroyed () {
    console.log('Destroyed')
  }
}
</script>
<style src='./activityEditSemesterDates.css' scoped>
</style>
