export default {
  createBuilding: '/infrastructureBuilding/createBuilding',
  createRoom: '/infrastructureRoom/createRoom',
  getRoomName: '/infrastructureRoom/getRoomName',
  getBuildings: '/infrastructureBuilding/getBuildings',
  getRooms: '/infrastructureRoom/getRoomsOfABuilding',
  getRoomsOfADepartment: '/infrastructureRoom/getRoomsOfADepartment',
  updateRoomName: '/infrastructureRoom/updateRoomName',
  updateRoomDepartment: '/infrastructureRoom/updateDepartmentOfRoom',
  updateSharingDepartments: '/infrastructureRoom/updateSharingDepartments',
  updateType: '/infrastructureRoom/updateType',
  deleteRoom: '/infrastructureRoom/deleteRoom',
  deleteBuilding: '/infrastructureBuilding/deleteBuilding'
}
