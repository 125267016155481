import objectValues from "caniuse-lite/data/features/object-values"

let instituteId=''
const state = {
  loading: false,
  selectedActivity: 0,
  selectedActivityName: '',
  statusMessage: 'Success!',
  statusDuration: 2000,
  statusType: 'success',
  snackbar: false,
  editingSemester: {},
  razorPayNumber:'',
  razorpayAccessToken:'',
  razorpaySecrete:'',
  descriptionForPayment:'',
  selectedExam: {},
  selectedExamSubject: {},
  selectedExamTimetable: {},
  selectedTopic: {},
  admissionSubjects: [],
  admissionSemester: '',
  admissionDepartment: '',
  admissionCourseYear: '',
  admissionInstitute:'',
  admissionDivision: '',
  instituteStudentBrandingName: '',
  instituteStudentLogoLink: '',
  senderMail: '',
  senderMailPassword: '',
  displayName: '',
  subjectDescription: '',
  showAdmissionModule: false,
  instituteAddress: '',
  instituteEmailId: '',
  instituteContactNumber: '',
  instituteAddress: '',
  instituteTermsAndConditions: '',
  instituteGstNumber: '',
  emailBody: ''
}


const getters = { // getters for state
  get_loading: state => state.loading,
  get_selectedActivity: state => state.selectedActivity,
  get_selectedActivityName: state => state.selectedActivityName,
  get_statusMessage: state => state.statusMessage,
  get_statusDuration: state => state.statusDuration,
  get_statusType: state => state.statusType,
  get_editingSemester: state => state.editingSemester,
  get_selectedExam: state => state.selectedExam,
  get_selectedExamSubject: state => state.selectedExamSubject,
  get_selectedExamTimetable: state => state.selectedExamTimetable,
  get_selectedTopic: state => state.selectedTopic,
  get_admissionSubjects: state => state.admissionSubjects,
  get_admissionSemester: state => state.admissionSemester,
  get_admissionInstitute: state => state.admissionInstitute,
  get_admissionDepartment: state => state.admissionDepartment,
  get_admissionCourseYear: state => state.admissionCourseYear,
  get_admissionDivision: state => state.admissionDivision,
  get_showAdmissionModule: state => state.showAdmissionModule,
  get_razorPayNumber: state => state.razorPayNumber,
  get_razorpayAccessToken: state => state.razorpayAccessToken,
  get_razorpaySecrete: state => state.razorpaySecrete,
  get_descriptionForPayment: state => state.descriptionForPayment,
  get_instituteStudentLogoLink: state => state.instituteStudentLogoLink,
  get_instituteStudentBrandingName: state => state.instituteStudentBrandingName,
  get_senderMail: state => state.senderMail,
  get_senderMailPassword: state => state.senderMailPassword,
  get_displayName: state => state.displayName,
  get_subjectDescription: state => state.subjectDescription,
  get_instituteAddress: state => state.instituteAddress,
  get_instituteEmailId: state => state.instituteEmailId,
  get_emailBody: state => state.emailBody,
  get_instituteContactNumber: state => state.instituteContactNumber,
  get_instituteGstNumber: state => state.instituteGstNumber,
  get_instituteTermsAndConditions: state => state.instituteTermsAndConditions,
}

const mutations = { // mutations for state
 
  set_loading: (state, value) => {
    state.loading = value
  },
  set_selectedActivity: (state, value) => {
    state.selectedActivity = value
  },
  set_selectedActivityName: (state, value) => {
    state.selectedActivityName = value
  },
  set_statusMessage: (state, value) => {
    state.statusMessage = value
  },
  set_statusDuration: (state, value) => {
    state.statusDuration = value
  },
  set_statusType: (state, value) => {
    state.statusType = value
  },
  set_snackbar: (state, value) => {
    state.snackbar = value
  },
  set_editingSemester: (state, value) => {
    state.editingSemester = value
  },
  set_selectedExam: (state, value) => {
    state.selectedExam = value
  },
  set_selectedExamSubject: (state, value) => {
    state.selectedExamSubject = value
  },
  set_selectedExamTimetable: (state, value) => {
    state.selectedExamTimetable = value
  },
  set_selectedTopic: (state, value) => {
    state.selectedTopic = value
  },
  set_admissionSubjects: (state, value) => {
    state.admissionSubjects = value
  },
  set_admissionSemester: (state, value) => {
    state.admissionSemester = value
  },
  set_admissionInstitute: (state, value) => {
    state.admissionInstitute = value.admissionInstitute
  },
  //payment details
  set_razorPayNumber: (state, value) => {
    state.razorPayNumber = value.razorPayNumber
  },
  set_razorpayAccessToken: (state, value) => {
    state.razorpayAccessToken = value.razorpayAccessToken
  },
  set_razorpaySecrete: (state, value) => {
    state.razorpaySecrete = value.razorpaySecrete
  },
  set_descriptionForPayment: (state, value) => {
    state.descriptionForPayment = value.descriptionForPayment
  },
  set_instituteStudentBrandingName: (state, value) => {
    state.instituteStudentBrandingName = value.instituteStudentBrandingName
  },
  set_instituteStudentLogoLink: (state, value) => {
    state.instituteStudentLogoLink = value.instituteStudentLogoLink
  },
  set_instituteAddress: (state, value) => {
    state.instituteAddress = value.instituteAddress
  },
  set_instituteEmailId: (state, value) => {
    state.instituteEmailId = value.instituteEmailId
  },
  set_instituteContactNumber: (state, value) => {
    state.instituteContactNumber = value.instituteContactNumber
  },
  set_instituteGstNumber: (state, value) => {
    state.instituteGstNumber = value.instituteGstNumber
  },
  set_instituteTermsAndConditions: (state, value) => {
    state.instituteTermsAndConditions = value.instituteTermsAndConditions
  },
  //mailing details
  set_senderMail: (state, value) => {
    state.senderMail = value.senderMail
  },
  set_senderMailPassword: (state, value) => {
    state.senderMailPassword = value.senderMailPassword
  },
  set_displayName: (state, value) => {
    state.displayName = value.displayName
  },
  set_subjectDescription: (state, value) => {
    state.subjectDescription = value.subjectDescription
  },
  set_emailBody: (state, value) => {
    state.emailBody = value.emailBody
  },
  //mailing details


  //payment details
  set_admissionDepartment: (state, value) => {
    state.admissionDepartment = value
  },
  set_admissionCourseYear: (state, value) => {
    state.admissionCourseYear = value
  },
  set_admissionDivision: (state, value) => {
    state.admissionDivision = value
  },
  set_showAdmissionModule: (state, value) => {
    state.showAdmissionModule = value
  },
  reset(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = { // actions for state
  set_institute: ({ commit, state }, value) => {
    commit('set_institute', value)
    return state.message
  },
  set_loading: ({ commit, state }, value) => {
    commit('set_loading', value)
    return state.message
  },
  set_selectedActivity: ({ commit, state }, value) => {
    commit('set_selectedActivity', value)
    return state.message
  },
  set_admissionSubjects: ({ commit, state }, value) => {
    commit('set_admissionSubjects', value)
    return state.message
  },
  set_admissionSemester: ({ commit, state }, value) => {
    commit('set_admissionSemester', value)
    return state.message
  },
  set_admissionInstitute: ({ commit, state }, value) => {
    commit('set_admissionInstitute', value)
    return state.message
  },
  set_admissionDepartment: ({ commit, state }, value) => {
    commit('set_admissionDepartment', value)
    return state.message
  },
  set_admissionCourseYear: ({ commit, state }, value) => {
    commit('set_admissionCourseYear', value)
    return state.message
  },
  set_admissionDivision: ({ commit, state }, value) => {
    commit('set_admissionDivision', value)
    return state.message
  },
  set_razorPayNumber: ({ commit, state }, value) => {
    commit('set_razorPayNumber', value)
    return state.message
  },
  set_razorpayAccessToken: ({ commit, state }, value) => {
    commit('set_razorpayAccessToken', value)
    return state.message
  },
  set_razorpaySecrete: ({ commit, state }, value) => {
    commit('set_razorpaySecrete', value)
    return state.message
  },
  set_descriptionForPayment: ({ commit, state }, value) => {
    commit('set_descriptionForPayment', value)
    return state.message
  },
  set_instituteStudentLogoLink: ({ commit, state }, value) => {
    commit('set_instituteStudentLogoLink', value)
    return state.message
  },
  set_instituteStudentBrandingName: ({ commit, state }, value) => {
    commit('set_instituteStudentBrandingName', value)
    return state.message
  },
  set_senderMail: ({ commit, state }, value) => {
    commit('set_senderMail', value)
    return state.message
  },
  set_senderMailPassword: ({ commit, state }, value) => {
    commit('set_senderMailPassword', value)
    return state.message
  },
  set_displayName: ({ commit, state }, value) => {
    commit('set_displayName', value)
    return state.message
  },
  set_subjectDescription: ({ commit, state }, value) => {
    commit('set_subjectDescription', value)
    return state.message
  },
  set_instituteAddress: ({ commit, state }, value) => {
    commit('set_instituteAddress', value)
    return state.message
  },
  set_instituteEmailId: ({ commit, state }, value) => {
    commit('set_instituteEmailId', value)
    return state.message
  },
  set_instituteContactNumber: ({ commit, state }, value) => {
    commit('set_instituteContactNumber', value)
    return state.message
  },
  set_instituteGstNumber: ({ commit, state }, value) => {
    commit('set_instituteGstNumber', value)
    return state.message
  },
  set_instituteTermsAndConditions: ({ commit, state }, value) => {
    commit('set_instituteTermsAndConditions', value)
    return state.message
  },
  set_emailBody: ({ commit, state }, value) => {
    commit('set_emailBody', value)
    return state.message
  },
}
export default { // exports the vuex object of 'institutesData' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function initialState() {
  return {
    loading: false,
    selectedActivity: 1,
    statusMessage: 'Success!',
    statusDuration: 2000,
    statusType: 'success',
    snackbar: false,
    editingSemester: {},
    selectedExam: {},
    selectedExamSubject: {},
    selectedExamTimetable: {},
    selectedTopic: {},
    admissionSubjects: [],
    admissionSemester: '',
    admissionInstitute: state.admissionInstitute,
    razorPayNumber: state.razorPayNumber,
    razorpayAccessToken: state.razorpayAccessToken,
    razorpaySecrete: state.razorpaySecrete,
    descriptionForPayment: state.descriptionForPayment,
    instituteStudentBrandingName: state.instituteStudentBrandingName,
    instituteStudentLogoLink: state.instituteStudentLogoLink,
    senderMail: state.senderMail,
    senderMailPassword: state.senderMailPassword,
    displayName: state.displayName,
    subjectDescription: state.subjectDescription,
    instituteAddress: state.instituteAddress,
    instituteEmailId: state.instituteEmailId,
    instituteGstNumber: state.instituteGstNumber,
    instituteContactNumber: state.instituteContactNumber,
    instituteTermsAndConditions: state.instituteTermsAndConditions,
    admissionDepartment: '',
    admissionCourseYear: '',
    admissionDivision: '',
    showAdmissionModule: false,
    emailBody: state.emailBody
  }
}

