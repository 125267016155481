import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Lecture'

class LectureRepository {
  constructor (context) {
    this.context = context
  }

  async getLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLecturesForADayForAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADayForAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async createLecturesFromATimetableDay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createLecturesFromATimetableDay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getAttendanceAnalysisForADivisionForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForADivisionForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }

  async getLecturesOfADepartmentForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfADepartmentForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getRealtimeSchedule (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRealtimeSchedule, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesOfADivisionForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfADivisionForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async arrangeALecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.arrangeALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async cancelLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.cancelLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectureStatus
  }

  async declareHoliday (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.declareHoliday, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectureStatus
  }

  async generateAttendanceAnalysisExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAttendanceAnalysisExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default LectureRepository
