<template src='./activityEditSemesterUsers.html'>
</template>

<script>
import ImportAllDialog from './importAllDialog/importAllDialog'
import inputContainer from '../../Components/inputContainer'
import SemesterUserRepository from '../../Repository/SemesterUser'
import UserRepository from '../../Repository/User'
import InstituteUserRepository from '../../Repository/InstituteUser'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'

export default {
  name: 'ActivityUsersTree',
  components: {
    ImportAllDialog,
    inputContainer
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  data () {
    return {
      selectionType: 'leaf',
      addDialog: false,
      importAll: false,
      loading: false,
      users: [],
      search: '',
      headersForStudents: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
          filterable: true
        },
        {
          text: 'Department',
          align: 'left',
          sortable: true,
          value: 'department',
          filterable: true
        },
        {
          text: 'Course Year',
          align: 'left',
          sortable: true,
          value: 'courseYear',
          filterable: true
        },
        {
          text: 'Division',
          align: 'left',
          sortable: true,
          value: 'division',
          filterable: true
        },
        { text: 'Batch', value: 'batch', width: '120px' },
        // { text: 'Last Name', value: 'lastName' },
        { text: 'Roll Number', value: 'rollNumber' },
        { text: 'Exam Seat Number', value: 'examSeatNumber' },
        {
          text: 'Student Id',
          align: 'center',
          sortable: true,
          value: 'instituteProvidedId',
          filterable: true
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center'
        }
      ],
      headersForFaculties: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
          filterable: true
        },
        {
          text: 'Department',
          align: 'left',
          sortable: true,
          value: 'department',
          filterable: true
        },
        {
          text: 'Faculty Id',
          align: 'center',
          sortable: true,
          value: 'instituteProvidedId',
          filterable: true
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center'
        }
      ],
      selectedUser: null,
      userType: 'faculty',
      selection: [],
      selectedInstitute: null,
      selectedSemester: {},
      userMappings: {},
      changedObjs: [],
      changedIds: [],
      academicTreeForFilter: [],
      referenceAcademicTree: {}
    }
  },
  created () {
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']
    this.academicTreeForFilter = JSON.parse(JSON.stringify(this.tree))
    this.setupActivity()
  },
  methods: {
    async setupActivity () {
      await this.getMasterDataTree()
      await this.getUsers()
      // this.userMappings = []
    },
    async getMasterDataTree () {
      this.loading = true
      this.referenceAcademicTree = {}
      for (let i = 0; i < this.tree.length; i++) {
        const obj = this.tree[i]
        if (!this.referenceAcademicTree[obj.name]) {
          this.referenceAcademicTree[obj.name] = {}
        }
        for (let j = 0; j < obj.children.length; j++) {
          const obj2 = obj.children[j]
          if (!this.referenceAcademicTree[obj.name][obj2.name]) {
            this.referenceAcademicTree[obj.name][obj2.name] = []
          }
          for (let k = 0; k < obj2.children.length; k++) {
            this.referenceAcademicTree[obj.name][obj2.name].push(obj2.children[k].name)
          }
        }
      }
      this.academicTreeForFilter = []
      if (this.userType === 'students') {
        this.academicTreeForFilter = JSON.parse(JSON.stringify(this.tree))
      } else {
        for (let i = 0; i < this.tree.length; i++) {
          const obj = this.tree[i]
          this.academicTreeForFilter.push({
            id: obj.id,
            name: obj.name,
            department: obj.name
          })
          console.log('constructing tree for faculty', obj)
        }
      }
      console.log('academic tree and tree', this.tree, this.academicTreeForFilter)
      this.loading = false
    },
    onRefresh () {
      this.setupActivity()
    },
    gotoTransferUsers () {
      this.$router.push({
        name: 'activityTransferUsers',
        params: {
          prop_selectedDepartment: this.prop_selectedDepartment,
          prop_selectedCourseYear: this.prop_selectedCourseYear,
          prop_selectedDivision: this.prop_selectedDivision,
          tree: this.tree
        }
      })
    },
    async getUsers () {
      this.loading = true
      this.users = []
      this.changedObjs = []
      this.changedIds = []

      let promises = []

      if (this.userType === 'students') {
        promises = this.selection.map((divisionObject) => {
          return this.semesterUserRepositoryInstance.getStudentsOfADivision({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: divisionObject.department,
            courseYear: divisionObject.courseYear,
            division: divisionObject.name
          })
        })
      } else {
        promises = [...new Set(this.selection.map((elem) => elem.department))].map((department) => {
          return this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: department
          })
        })
      }

      const allSettledPromises = await Promise.allSettled(promises)

      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          console.log(allSettledPromises[i].value)
          await this.addUsersToList(allSettledPromises[i].value)
        }
      }

      this.loading = false
    },
    async addUsersToList (listOfSemesterUsers) {
      const lengthBeforeUpdate = this.users.length
      this.users = this.users.concat(listOfSemesterUsers)
      const listOfPromises = []
      for (let i = lengthBeforeUpdate; i < this.users.length; i++) {
        listOfPromises.push(this.getFullNameOfUser(this.users[i]))
        listOfPromises.push(this.getInstituteProvidedIdOfUser(this.users[i]))
      }
      await Promise.allSettled(listOfPromises)
    },
    async getFullNameOfUser (userObject) {
      try {
        if (!userObject.name) {
          userObject.name = await this.userRepositoryInstance.getFullNameOfUser({ uId: userObject.uId })
        }
      } catch (err) {
        console.log(err)
        userObject.name = ''
      }
      return userObject
    },
    async getInstituteProvidedIdOfUser (userObject) {
      try {
        if (!userObject.instituteProvidedId) {
          userObject.instituteProvidedId = await this.instituteUserRepositoryInstance.getInstituteProvidedId({
            instituteId: this.selectedInstitute,
            uId: userObject.uId
          })
        }
      } catch (err) {
        console.log(err)
        userObject.instituteProvidedId = '--'
      }
      return userObject
    },
    async deleteUser (user) {
      try {
        await this.semesterUserRepositoryInstance.deleteSemesterUser(user)
        this.users = arrayOps.removeFromArray(this.users, this.users.indexOf(user))
      } catch (err) {
        console.log(err)
      }
    },
    async updateAllRecords () {
      this.loading = true
      // Call API to edit Semester Users
      const data = [...this.changedObjs]
      const res = await this.semesterUserRepositoryInstance.editSemesterUsersForDepartments(data)
      if (res.status === 200) {
        console.log(res)
        this.changedObjs = []
        this.changedIds = []
        showStatus('Changes saved successfully!', 1500, 'success', this)
      }
      this.loading = false
    },
    closeImportAllDialog () {
      this.importAll = false
    },
    addInChanged (item) {
      console.log(item)
      if (!this.changedIds.includes(item.uId)) {
        this.changedIds.push(item.uId)
        this.changedObjs.push(item)
      }
    },
    async updateDepartment (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        department: item.department
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].department = await this.semesterUserRepositoryInstance.updateDepartment(objToPush)
        showStatus('Department Updated!', 1500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    },
    async updateCourseYear (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        courseYear: item.courseYear
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].courseYear = await this.semesterUserRepositoryInstance.updateCourseYear(objToPush)
        showStatus('Course Year Updated!', 1500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    },
    async updateDivision (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        division: item.division
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].division = await this.semesterUserRepositoryInstance.updateDivision(objToPush)
        showStatus('Division Updated!', 1500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    },
    async updateBatch (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        batch: item.batch
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].batch = await this.semesterUserRepositoryInstance.updateBatch(objToPush)
        showStatus('Batch Updated!', 500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    },
    async updateRollNumber (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        rollNumber: item.rollNumber
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].rollNumber = await this.semesterUserRepositoryInstance.updateRollNumber(objToPush)
        showStatus('Roll Number Updated!', 1500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    },
    async updateExamSeatNumber (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        examSeatNumber: item.examSeatNumber
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].examSeatNumber = await this.semesterUserRepositoryInstance.updateExamSeatNumber(objToPush)
        showStatus('Exam Seat Number Updated!', 1500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    },
    async updateInstituteProvidedId (item) {
      console.log(item)
      const objToPush = {
        instituteId: this.selectedInstitute,
        uId: item.uId,
        instituteProvidedId: item.instituteProvidedId
      }
      try {
        const indexOfUser = this.users.indexOf(item)
        this.users[indexOfUser].instituteProvidedId = await this.instituteUserRepositoryInstance.updateInstituteProvidedId(objToPush)
        showStatus('Institute User ID Updated!', 1500, 'success', this)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style scoped src='./activityEditSemesterUsers.css'>
</style>
