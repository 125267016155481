import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Subject'

class Subject {
  constructor (context) {
    this.context = context
  }

  async createSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSubjectName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjectName
  }

  async getSubjectsOfAInstituteInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSubjectsOfACourseYearInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSubjectsOfACourseYearForAdmission (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfACourseYearForAdmission, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getFacultiesTeachingASubjectOfACourseYearInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesTeachingASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.faculties
  }

  async getDetailsOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDetailsOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subject
  }

  async updateGroupName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateGroupName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.groupName
  }

  async updateSubjectName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjectName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjectName
  }

  async updateSubjectCredits (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjectCredits, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.credits
  }

  async updateSubjectCoordinators (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjectCoordinators, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjectCoordinators
  }

  async updateFacultiesTeachingASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateFacultiesTeachingASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.faculties
  }

  async updateSubjectType (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjectType, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjectType
  }

  async deleteSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjectType
  }
}

export default Subject
