<template src='./activitySMSUsers.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import SMSRepository from '../../Repository/SMS'
import UserRepository from '../../Repository/User'
import InstituteUserRepository from '../../Repository/InstituteUser'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activitySMSUsers',
  components: {
    inputContainer
  },
  data () {
    return {
      userData: {},
      selectedInstitute: '',
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      smsUsers: [],
      users: [],
      departmentsList: [],
      search: '',
      tempRole: '',
      selectedUser: {},
      loadingSMSUsers: false,
      loadingUsers: false,
      showAddSMSUserDialog: false,
      showEditUserDialog: false,
      addSMSUserStepNumber: 1,
      tempPhoneNumber: '',
      tempDepartment: '',
      headers: [
        {
          text: 'Names',
          value: 'name'
        },
        {
          text: 'Role',
          value: 'role'
        },
        {
          text: 'Phone Number',
          value: 'phoneNumber'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      headersForUsers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'fullName',
          filterable: true
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'center'
        }
      ]
    }
  },
  created () {
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.smsRepositoryInstance = new SMSRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Manage SMS Users')
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loadingSMSUsers = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      this.smsUsers = []
      try {
        this.smsUsers = await this.smsRepositoryInstance.getSmsUsersOfAnInstitute({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        })

        for (let i = 0; i < this.smsUsers.length; i++) {
          this.smsUsers[i].role = this.smsUsers[i].department ? 'HOD of ' + this.smsUsers[i].department : 'Director'
        }
      } catch (err) {
        console.log(err)
      }
      const batchSize = 100

      const promises = []
      for (let i = 0; i < this.smsUsers.length; i += batchSize) {
        promises.push(this.getFullNameOfUserForSMS(i, i + batchSize < this.smsUsers.length ? i + batchSize : this.smsUsers.length))
      }

      await Promise.allSettled(promises)
      this.smsUsers = this.smsUsers.slice()
      this.loadingSMSUsers = false
    },
    async getFullNameOfUserForSMS (firstIndex, lastIndex) {

      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.smsUsers[i].uId)
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(objToPush)

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find((elem) => elem.uId === this.smsUsers[i].uId)
          this.smsUsers[i].name = user ? user.fullName : ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    async deleteSMSUser (user) {
      try {
        await this.smsRepositoryInstance.deleteSmsUser(user)
        const index = this.smsUsers.indexOf(user)
        this.smsUsers = arrayOps.removeFromArray(this.smsUsers, index)
      } catch (err) {
        console.log(err)
        showStatus('Could not delete. Try again', 1500, 'error', this)
      }
    },
    async editSMSUser (user) {
      this.selectedUser = user
      this.showEditUserDialog = true
      this.tempRole = this.selectedUser.department ? 'HOD' : 'Director'
      this.tempPhoneNumber = this.selectedUser.phoneNumber
      this.tempDepartment = this.selectedUser.department || ''
      await this.getDetailsOfUser()
    },
    async saveEditedSMSUserDetails () {
      // No api on server to update details, hence implementation is incomplete
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedUser.uId,
          phoneNumber: this.tempPhoneNumber
        }
        if (this.tempRole === 'HOD') {
          objToPush.department = this.tempDepartment
        }

        // const existingSMSUser = this.smsUsers.findIndex((smsUser) => smsUser.uId === this.selectedUser.uId)
        // await this.smsRepositoryInstance.addSMSUser(objToPush)
        // this.fetchData()
      } catch (err) {
        console.log(err)
        showStatus('Error adding user.', 1500, 'error', this)
      }
    },
    closeEditSMSUser (user) {
      this.selectedUser = {}
      this.showEditUserDialog = false
      this.tempRole = ''
      this.tempPhone = ''
      this.tempDepartment = ''
    },
    async addSMSUser () {
      this.showAddSMSUserDialog = true
      this.loadingUsers = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }

        const instituteUsers = await this.instituteUserRepositoryInstance.getUsersOfAnInstitute(objToPush)

        for (let i = 0; i < instituteUsers.length; i++) {
          this.users.push({
            fullName: '',
            instituteProvidedId: instituteUsers[i].instituteProvidedId,
            uId: instituteUsers[i].uId
          })
        }

        this.loadingUsers = false
      } catch (err) {
        console.log(err)
      }

      const batchSize = 100

      const promises = []
      for (let i = 0; i < this.users.length; i += batchSize) {
        promises.push(this.getFullNameOfUser(i, i + batchSize < this.users.length ? i + batchSize : this.users.length))
      }

      await Promise.allSettled(promises)
      this.users = this.users.slice()
    },
    async getFullNameOfUser (firstIndex, lastIndex) {
      console.log(firstIndex, lastIndex)
      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.users[i].uId)
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(objToPush)

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find((elem) => elem.uId === this.users[i].uId)
          this.users[i].fullName = user ? user.fullName : ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    async chooseUserAndMoveToNextStep (user) {
      this.selectedUser = user
      await this.getDetailsOfUser()
      this.addSMSUserStepNumber = 2
    },
    async getDetailsOfUser () {
      try {
        const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication({
          uId: this.selectedUser.uId
        })
        this.selectedUser.phones = emailsAndPhones.phones.slice()

        const semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        })
        this.departmentsList = [...new Set(semestersList.map((semester) => semester.department))]
      } catch (err) {
        console.log(err)
      }
    },
    async addUserToSMSUsers () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedUser.uId,
          phoneNumber: this.tempPhoneNumber
        }
        if (this.tempRole === 'HOD') {
          objToPush.department = this.tempDepartment
        }
        console.log(objToPush)
        const existingSMSUser = this.smsUsers.find((smsUser) => smsUser.uId === this.selectedUser.uId)
        if (existingSMSUser) {
          showStatus('User already exists in sms list.', 1500, 'error', this)
        } else {
          await this.smsRepositoryInstance.addSMSUser(objToPush)
          this.fetchData()
        }
      } catch (err) {
        console.log(err)
        showStatus('Error adding user.', 1500, 'error', this)
      }
      this.closeAddUserDialog()
    },
    goBackToStep1 () {
      this.addSMSUserStepNumber = 1
      this.selectedUser = {}
      this.tempPhoneNumber = ''
      this.tempDepartment = ''
      this.tempRole = ''
    },
    closeAddUserDialog () {
      this.addSMSUserStepNumber = 1
      this.selectedUser = {}
      this.users = []
      this.search = ''
      this.tempRole = ''
      this.tempPhoneNumber = ''
      this.showAddSMSUserDialog = false
    }
  }
}
</script>

<style src='./activitySMSUsers.css'>
</style>
