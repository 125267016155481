import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/InstituteUser'

class InstituteUser {
  constructor (context) {
    this.context = context
  }

  async getUsersOfAnInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getUsersOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteUsers
  }

  async getInstituteUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstituteUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteUser
  }

  async getInstituteProvidedId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstituteProvidedId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteProvidedId
  }

  async updateInstituteProvidedId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteProvidedId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteProvidedId
  }
}

export default InstituteUser
