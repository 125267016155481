<template src='./activitySelectInstituteNSemester.html'>
</template>
<script>
import GetInstitutesAndSemestersRepository from '../../Repository/GetInstitutesAndSemesters'
import SemesterUserRepository from '../../Repository/SemesterUser'
import showStatus from '../../NetworkManager/showStatus'
import UserRepository from '../../Repository/User'
import NetworkManager from '../../NetworkManager'
import InstituteAPIs from '../../ApiNames/Institute'
import auth from '../../Services/Firebase/auth'
export default {
  name: 'activitySelectInstituteNSemester',
  data () {
    return {
      isMobileView:false,
      creatingInstitute: false,
      userData: {},
      alignment: 'center',
      justify: 'center',
      selectedInstitute: '',
      institutes: [],
      user: '',
      tempFirstName: '',
      tempMiddleName: '',
      tempLastName: '',
      logoutDialog: false,
      brandingName: '',
      brandingLogo: ''
    }
  },
  created () {
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.getInstitutesAndSemestersRepositoryInstance = new GetInstitutesAndSemestersRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.userData = this.$store.getters['user/get_userData']
    setTimeout(() => this.$router.push('/activityAdmission'), 100)
   // this.fetchData()
   // this.fetchBrandingDetails(this.userData.instituteId)
  },
  methods: {
    async fetchBrandingDetails (instituteId) {
      try {
        const data = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId: instituteId }
        )
        this.brandingName = data.instituteBrandingName
        this.brandingLogo = data.instituteLogoLink
      } catch (error) {
        console.log({ error })
      }
    },
    async fetchData () {
      try {
        const objToPush = {
          uId: this.userData.uId
        }
        this.user = await this.userRepositoryInstance.getUserProfile(objToPush)
        this.tempFirstName = this.user.firstName
        this.tempMiddleName = this.user.middleName
        this.tempLastName = this.user.lastName
        this.institutes = await this.getInstitutesAndSemestersRepositoryInstance.getInstitutes(objToPush)
        showStatus('Got Institutes and Semesters Successfully!', 1000, 'success', this)
        for (let i = 0; i <= this.institutes.length; i++) {
          this.fetchBrandingDetails(this.institutes[i].instituteId)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async selectInstitute (institute) {
      this.selectedInstitute = institute.instituteId
      this.$store.dispatch('instituteData/set_selectedInstitute', this.selectedInstitute)
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.selectedInstitute
      }

      setTimeout(() => this.$router.push('/activityAdmission'), 100)
    },
    gotoMyProfile () {
      this.$router.push('/activityUser')
    },
    logout () {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    },
    startCreatingInstitute () {
      this.$router.push('/activityCreateInstitute')
    }
  }
}
</script>
<style scoped src='./activitySelectInstituteNSemester.css'>
</style>
