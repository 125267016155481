<template src='./activityManageRoles.html'>
</template>
<script>
export default {
  name: 'activityManageRoles',
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'Manage Roles')
  }
}
</script>
<style scoped src='./activityManageRoles.css'>
</style>
