<template src="./activityDashboard.html"></template>

<script>
import SemesterRepository from "../../Repository/Semester";
import SubjectsRepository from "../../Repository/Subject";
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/Lecture";
import OnlineLectureRepository from "../../Repository/OnlineLecture";
import InfrastructureReferenceRepository from "../../Repository/InfrastructureReference";
import showStatus from "../../NetworkManager/showStatus";
import {
  convertDate,
  formatToSlashSepearated,
  timeStringConverter,
  convertFromYYYYMMDDToDate,
} from "../../Services/Utils/DateTimeFormatter";
import {
  subjectTypes,
  conductableLectureTypes,
} from "../../Constants/Utils/Statics";
import inputContainer from "../../Components/inputContainer";
export default {
  name: "activityDashboard",
  components: {
    inputContainer,
  },
  data() {
    return {
      showOnlineLectures: false,
      namesMapping: {},
      roomNamesMapping: {},
      subjectsMapping: {},
      loading: false,
      rangeEnabled: false,
      date: convertDate(new Date()),
      time: "",
      time2: "",
      search: "",
      headers: [
        {
          text: "Time",
          value: "time",
        },
        {
          text: "Date",
          value: "dateTime",
        },
        {
          text: "Department",
          align: "left",
          value: "department",
        },
        { text: "Course Year", value: "courseYear" },
        { text: "Division", value: "division" },
        { text: "Batch", value: "batch" },
        { text: "Subject", value: "subjectName" },
        { text: "Faculty", value: "name" },
        { text: "Location", value: "roomName" },
        { text: "Lecture Type", value: "status" },
        { text: "Conducted", value: "isConducted" },
      ],
      headers2: [
        {
          text: "Department",
          align: "left",
          value: "department",
        },
        { text: "Course Year", value: "courseYear" },
        { text: "Division", value: "division" },
        { text: "Batch", value: "batch" },
        { text: "Subject", value: "subjectName" },
        { text: "Faculty", value: "name" },
        { text: "Location", value: "locationName" },
        { text: "Lecture Type", value: "status" },
        /* TODO: to solve bugs */
        /* { text: "Conducted", value: "isConducted" }, */
        { text: "Join Lecture", value: "action" },
      ],
      headers3: [
        {
          text: "Department | Course Year | Division",
          align: "left",
          value: "criteria",
        },
        // { text: 'Course Year', value: 'courseYear' },
        // { text: 'Division', value: 'division' },
        { text: "Batch", value: "batch" },
        { text: "Date", value: "dateTime" },
        { text: "Start Time", value: "startTime" },
        { text: "End Time", value: "endTime" },
        { text: "Stop Lecture", value: "actions" },
      ],
      onlineLectures: [],
      hostsOccupied: 0,
      totalHosts: 0,
      plannedLectures: [],
      realTimeLectures: [],
      tree: [],
      selection: [],
      totalConducted: 0,
      totalNonConducted: 0,
      totalRescheduled: 0,
      totalArranged: 0,
      totalCancelled: 0,
      selectedSemester: "",
      selectedSemesterName: "",
      times2: [
        "12:00 AM",
        "01:00 AM",
        "02:00 AM",
        "03:00 AM",
        "04:00 AM",
        "05:00 AM",
        "06:00 AM",
        "07:00 AM",
        "08:00 AM",
        "09:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "01:00 PM",
        "02:00 PM",
        "03:00 PM",
        "04:00 PM",
        "05:00 PM",
        "06:00 PM",
        "07:00 PM",
        "08:00 PM",
        "09:00 PM",
        "10:00 PM",
        "11:00 PM",
      ],
      lecTypeToShow: "Lectures",
      menu: false,
      disabled: false,
      snackType: "success",
      snackText: "",
      snackTime: 2000,
      snackDirection: "top",
      snackbar: false,
      dates: [],
      today: "",
      datesType: "Today",
      datesSelector: false,
      datesSelectionTypes: ["Today", "Custom"],
      selectionType: "leaf",
      menu1: false,
      menu2: false,
      date1: "",
      date2: "",
      reasonOfCancellingLecture: "",
      showReason: false,
      refresh: false,
      refreshTime: 1,
      interval: null,
      showRefreshTime: false,
    };
  },
  created() {
    this.$store.commit(
      "liveData/set_selectedActivityName",
      "Lectures Dashboard"
    );
    this.userRepositoryInstance = new UserRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    const today = new Date();
    this.today =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    this.dates.push(this.today);
    const hrs = today.getHours();
    this.time = ("0" + hrs).slice(-2) + ":00";
    this.semestersList = [];
    this.semesters = [];
    this.lectures = [];
    this.practicals = [];
    this.fetchData();
  },
  computed: {
    dateText() {
      if (convertDate(new Date()) === this.date) {
        return "Today";
      }
      if (this.dates.length === 1) {
        const diff = Math.ceil(
          (this.convertToDateObject(this.date) - new Date()) /
            (1000 * 60 * 60 * 24)
        );

        if (diff === 1) {
          return "Tomorrow";
        }
        if (diff === -1) {
          return "Yesterday";
        }
        return this.date.split("-").join("/");
      } else {
        return this.date;
      }
    },
  },
  methods: {
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        );
        this.semesters.reverse();
        this.semestersList = this.semesters.map((semester) => semester.semName);

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0];
          this.selectSemester(this.semestersList[0]);
        }
      } catch (err) {
        console.log(err);
        this.semesters = [];
        this.semestersList = [];
      }
    },
    selectSemester() {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(
        this.selectedSemesterName
      );
      this.setupDataForActivity();
    },
    async setupDataForActivity() {
      this.disabled = true;
      await this.getMasterDataTree();
      await this.fetchPlannedLectures();
      await this.getRealTimeLectures();
      this.disabled = false;
    },
    async getMasterDataTree() {
      this.loading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        };
        const semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        this.constructAcademicDataTree(semestersList);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async constructAcademicDataTree(semestersList) {
      const academicDataTree = {};

      semestersList.forEach((sem) => {
        if (!academicDataTree[sem.department]) {
          academicDataTree[sem.department] = {};
        }
        if (!academicDataTree[sem.department][sem.courseYear]) {
          academicDataTree[sem.department][sem.courseYear] = [];
        }
        academicDataTree[sem.department][sem.courseYear].push(sem.division);
      });

      let idForDisplay = 0;
      const divisions = [];

      Object.keys(academicDataTree).forEach((department, index) => {
        const departmentObject = {
          id: idForDisplay,
          name: department,
          children: [],
        };

        idForDisplay++;

        Object.keys(academicDataTree[department]).forEach((courseYear) => {
          const divisionsOfACourseYear = {
            id: idForDisplay,
            name: courseYear,
            children: [],
          };

          idForDisplay++;

          academicDataTree[department][courseYear].forEach((division) => {
            // idForDisplay++

            divisionsOfACourseYear.children.push({
              id: idForDisplay,
              department: department,
              courseYear: courseYear,
              name: division,
            });
            this.selection.push({
              id: idForDisplay,
              department: department,
              courseYear: courseYear,
              name: division,
            });
            idForDisplay++;
          });

          departmentObject.children.push(divisionsOfACourseYear);
        });

        divisions.push(departmentObject);
      });

      this.tree = divisions;
      // this.selection = divisions
      this.loading = false;
    },
    enterTime(time) {
      if (
        time.split(" ")[1] === "AM" &&
        time.split(" ")[0].split(":")[0] === "12"
      ) {
        this.time = "00:00";
      } else {
        if (time.split(" ")[1] === "AM") {
          this.time = time.split(" ")[0];
        } else {
          if (
            time.split(" ")[1] === "PM" &&
            time.split(" ")[0].split(":")[0] === "12"
          ) {
            this.time = time.split(" ")[0];
          } else {
            if (time.split(" ")[1] === "PM") {
              this.time =
                parseInt(time.split(" ")[0].split(":")[0]) + 12 + ":00";
            }
          }
        }
      }
      this.getRealTimeLectures();
    },
    async getRealTimeLectures() {
      console.log("Refresh");
      this.loading = true;

      if (this.dates.length === 1 && this.today === this.dates[0]) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          time: this.time.split(":").join("_"),
        };

        this.realTimeLectures = [];

        for (let i = 0; i < this.selection.length; i++) {
          try {
            objToPush.department = this.selection[i].department;
            objToPush.courseYear = this.selection[i].courseYear;
            objToPush.division = this.selection[i].name;
            console.log(objToPush);
            const lectureObjects =
              await this.lectureRepositoryInstance.getRealtimeSchedule(
                objToPush
              );


            const promises = [];

            for (let i = 0; i < lectureObjects.length; i++) {
              if (conductableLectureTypes.includes(lectureObjects[i].status)) {
                this.realTimeLectures.push(lectureObjects[i]);
                promises.push(
                  this.getDetailsOfALecture(
                    this.realTimeLectures[this.realTimeLectures.length - 1]
                  )
                );
              }
            }

            await Promise.allSettled(promises);
          } catch (err) {
            console.log(err);
          }
        }
      }

      this.loading = false;
      this.disabled = false;
    },
    incrementDate() {
      console.log(this.date);
      this.disabled = true;
      console.log(this.date);
      const tomorrow = this.convertToDateObject(
        this.date.split("-").reverse().join("-")
      );
      tomorrow.setDate(tomorrow.getDate() + 1);

      this.date = this.convertToDDMMYYYY(tomorrow);
      this.dates = [this.convertToYYYYMMDD(tomorrow)];
      this.fetchPlannedLectures();
    },
    decrementDate() {
      this.disabled = true;
      const yesterday = this.convertToDateObject(
        this.date.split("-").reverse().join("-")
      );
      yesterday.setDate(yesterday.getDate() - 1);
      console.log("yesterday", yesterday);
      this.date = this.convertToDDMMYYYY(yesterday);
      this.dates = [this.convertToYYYYMMDD(yesterday)];
      this.fetchPlannedLectures();
    },
    closeDialog() {
      this.menu = false;
    },
    changeDate(dates) {
      this.closeDialog();
      this.$refs.menu.save(dates);
      // this.$refs.menu.save(date)
      this.disabled = true;
      this.fetchPlannedLectures();
    },
    async fetchPlannedLectures() {

      this.datesSelector = false;
      this.loading = true;

      this.plannedLectures = [];
      this.lectures = [];
      this.practicals = [];

      console.log(formatToSlashSepearated(this.dates));
      let lectureObjects = [];

      let promises = [];

      this.selection.forEach((element) => {
        this.dates.forEach((date) => {
          console.log({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: element.department,
            courseYear: element.courseYear,
            division: element.name,
            dateOfLecturesNeeded: this.convertToISOString(
              this.convertToDateObject(date)
            ),
          });
          promises.push(
            this.lectureRepositoryInstance.getLecturesOfADivisionForADay({
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: element.department,
              courseYear: element.courseYear,
              division: element.name,
              dateOfLecturesNeeded: this.convertToISOString(
                this.convertToDateObject(date)
              ),
            })
          );
        });
      });
      /*
      this.selection.forEach((element) => {
        element.children.forEach((element2) => {
          element2.children.forEach((element3) => {
            console.log('element3', element3)
            this.dates.forEach((date) => {
              promises.push(this.lectureRepositoryInstance.getLecturesOfADivisionForADay({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                department: element3.department,
                courseYear: element3.courseYear,
                division: element3.name,
                dateOfLecturesNeeded: this.convertToISOString(this.convertToDateObject(date))
              }))
            })
          })
        })
      })
      */

      /*
      const departments = [...new Set(this.selection.map((elem) => elem.name))]
      console.log(departments)

      for (let i = 0; i < departments.length; i++) {
        for (let j = 0; j < this.dates.length; j++) {
          try {
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              dateOfLecturesNeeded: this.convertToISOString(this.convertToDateObject(this.dates[j])),
              department: departments[i]
              // courseYear: ,
              // division: ,
              // dateOfLecturesNeeded:
            }
            // console.log('objToPush', objToPush)
            // promises.push(this.lectureRepositoryInstance.getLecturesOfADepartmentForADay(objToPush))
            promises.push(this.lectureRepositoryInstance.getLecturesOfADivisionForADay(objToPush))
            // console.log('letures', lectures)
          } catch (err) {
            console.log(err)
          }
        }
      }
      */

      promises = await Promise.allSettled(promises);

      promises
        .filter((promise) => promise.value)
        .forEach((promise) => {
          lectureObjects = lectureObjects.concat(promise.value);
        });
      let holidays = 0;
      this.totalConducted = 0;
      this.totalRescheduled = 0;
      this.totalArranged = 0;
      this.totalCancelled = 0;
      for (let i = 0; i < lectureObjects.length; i++) {
        if (lectureObjects[i].batch) {
          this.practicals.push(lectureObjects[i]);
        } else {
          this.lectures.push(lectureObjects[i]);
        }

        this.totalConducted += lectureObjects[i].isConducted ? 1 : 0;
        this.totalRescheduled += lectureObjects[i].status === 2 ? 1 : 0;
        this.totalArranged += lectureObjects[i].status === 4 ? 1 : 0;
        holidays += lectureObjects[i].status === 5 ? 1 : 0;
        this.totalCancelled += lectureObjects[i].status === 6 ? 1 : 0;
      }

      await this.filterLecturesToShow();
      await this.getRealTimeLectures();
      console.log(
        "stats",
        this.plannedLectures.length,
        this.totalConducted,
        this.totalRescheduled,
        this.totalArranged,
        holidays
      );
      this.totalNonConducted =
        this.plannedLectures.length -
        (this.totalConducted +
          this.totalCancelled +
          this.totalRescheduled +
          holidays);
    },
    async filterLecturesToShow() {
      let promises = [];
      console.log(this.lectures);
      console.log(this.practicals);
      if (this.lecTypeToShow === "Lectures") {
        this.plannedLectures = [];
        // for (let i = 0; i < this.lectures.length; i++) {
        //   this.lectures[i] = await this.getDetailsOfALecture(this.lectures[i])
        //   this.plannedLectures.push(this.lectures[i])
        // }
        promises = this.lectures.map((lectureObject) => {
          return this.getDetailsOfALecture(lectureObject);
        });
      }
      if (this.lecTypeToShow === "Practicals") {
        this.plannedLectures = [];
        // for (let i = 0; i < this.practicals.length; i++) {
        //   this.practicals[i] = await this.getDetailsOfALecture(this.practicals[i])
        //   this.plannedLectures.push(this.practicals[i])
        // }
        promises = this.practicals.map((lectureObject) => {
          return this.getDetailsOfALecture(lectureObject);
        });
      }
      if (this.lecTypeToShow === "Both") {
        const allLectures = this.lectures.concat(this.practicals);
        this.plannedLectures = [];
        // for (let i = 0; i < this.practicals.length; i++) {
        //   this.practicals[i] = await this.getDetailsOfALecture(this.practicals[i])
        //   this.plannedLectures.push(this.practicals[i])
        // }
        promises = allLectures.map((lectureObject) => {
          return this.getDetailsOfALecture(lectureObject);
        });
      }

      const allSettledPromises = await Promise.allSettled(promises);

      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          // console.log(allSettledPromises[i].value)
          this.plannedLectures.push(allSettledPromises[i].value);
        }
      }
      console.log(
        Object.keys(this.namesMapping).length,
        Object.keys(this.roomNamesMapping).length,
        Object.keys(this.subjectsMapping).length
      );
      console.log("this.plannedLectures.length", this.plannedLectures.length);
    },
    async joinLecture(lectureObject) {
      try {
        showStatus("Redirecting to online lecture", 1000, "success", this);
        let redirectionUrl = "";
        const objToPush = {
          instituteId: lectureObject.instituteId,
          semId: lectureObject.semId,
          lectureId: lectureObject.lectureId,
          uId: lectureObject.uId,
        };
        try {
          redirectionUrl =
            await this.onlineLectureRepositoryInstance.getRedirectionUrl(
              objToPush
            );
        } catch (err) {
          console.log(err);
          redirectionUrl =
            await this.onlineLectureRepositoryInstance.getLinkOfOnlineLecture(
              objToPush
            );
        }
        this.joinOnlineLecture(redirectionUrl);
      } catch (err) {
        console.log(err);
        if (err.message === "Request failed with status code 404") {
          showStatus("The lecture is not being conducted", 2000, "error", this);
          return;
        }
        showStatus("An error occured", 1000, "error", this);
      }
    },
    async joinOnlineLecture(link) {
      this.showOnlineLectureDialog = true;
      try {
        console.log(link);
        window.open(link, "_blank");
      } catch (err) {
        console.log(err);
        showStatus(
          "Error joining link for online lecture.",
          1000,
          "error",
          this
        );
      }
    },
    async getDetailsOfALecture(lectureObject) {
      try {
        if (!lectureObject.name) {
          // lectureObject.name = await this.userRepositoryInstance.getFullNameOfUser({ uId: lectureObject.uId })
          if (this.namesMapping[lectureObject.uId]) {
            lectureObject.name = this.namesMapping[lectureObject.uId];
          } else {
            lectureObject.name =
              await this.userRepositoryInstance.getFullNameOfUser({
                uId: lectureObject.uId,
              });
            this.namesMapping[lectureObject.uId] = lectureObject.name;
          }
        }
      } catch (err) {
        console.log(err);
      }

      try {
        if (lectureObject.roomId) {
          if (!lectureObject.roomName) {
            // lectureObject.roomName = await this.infrastructureReferenceRepositoryInstance.getRoomName({ instituteId: this.selectedInstitute, roomId: lectureObject.roomId })
            if (this.roomNamesMapping[lectureObject.roomId]) {
              lectureObject.roomName =
                this.roomNamesMapping[lectureObject.roomId];
            } else {
              lectureObject.roomName =
                await this.infrastructureReferenceRepositoryInstance.getRoomName(
                  {
                    instituteId: this.selectedInstitute,
                    roomId: lectureObject.roomId,
                  }
                );
              this.roomNamesMapping[lectureObject.roomId] =
                lectureObject.roomName;
            }
          }
        } else {
          lectureObject.roomName = "";
        }
      } catch (err) {
        console.log(err);
      }

      try {
        if (!lectureObject.subjectName) {
          if (this.subjectsMapping[lectureObject.subjectId]) {
            lectureObject.subjectName =
              this.subjectsMapping[lectureObject.subjectId].subjectName;
            lectureObject.subjectType =
              subjectTypes[
                this.subjectsMapping[lectureObject.subjectId].subjectType
              ];
          } else {
            const subject =
              await this.subjectsRepositoryInstance.getDetailsOfASubject({
                instituteId: lectureObject.instituteId,
                semId: lectureObject.semId,
                subjectId: lectureObject.subjectId,
              });
            lectureObject.subjectName = subject.subjectName;
            lectureObject.subjectType = subjectTypes[subject.subjectType];
            this.subjectsMapping[lectureObject.subjectId] = {
              subjectName: lectureObject.subjectName,
              subjectType: lectureObject.subjectType,
            };
          }
        }
      } catch (err) {
        console.log(err);
      }
      return lectureObject;
    },
    // async filterLectures(status) {
    //   await this.getRealTimeLectures()
    //   let lectures = this.realTimeLectures.filter(
    //     lecture => lecture.status === status
    //   )
    //   this.realTimeLectures = lectures
    // },
    changeTime(int) {
      this.refreshTime += int;
      if (this.refreshTime < 1) {
        this.refreshTime = 1;
      }
    },
    async stopOnlineLecture(lecture) {
      try {
        console.log(lecture);
        const objToPush = lecture;
        await this.onlineLectureRepositoryInstance.updateOnlineLecture(
          objToPush
        );
        this.showOnlineLectures = false;
        showStatus("Online Lecture stopped.", 1000, "success", this);
      } catch (err) {
        console.log(err);
        showStatus("Online Lecture could not be stopped.", 1000, "error", this);
      }
    },
    async filterLectures(status) {
      await this.fetchPlannedLectures();
      const lectures = this.plannedLectures.filter(
        (lecture) => lecture.status === status
      );
      this.plannedLectures = lectures;
    },
    getFormattedTime(time) {
      return timeStringConverter(time);
    },
    gotoManageLectures() {
      console.log(this.date);
      this.$store.dispatch("liveData/setselectedDate", this.date);
      this.$router.push("/activityManageLectures");
    },
    showRefreshTimeFun() {
      clearInterval(this.interval);
      this.refresh = false;
      this.interval = null;
      this.showRefreshTime = true;
    },
    turnOnRefresh() {
      if (this.refreshTime >= 1) {
        const self = this;
        this.interval = setInterval(function () {
          self.fetchPlannedLectures();
        }, 1000 * 60 * parseInt(this.refreshTime));
        console.log(this.interval);
        this.refresh = true;
      }
    },
    turnOffRefresh() {
      this.refresh = false;
      clearInterval(this.interval);
      this.interval = null;
    },
    notifyFaculty(item) {
      console.log(item);
      //   axios.post(apiConfig.notifyFaculty, item).then(res => {
      //     console.log(res)
      //     this.showSnackBar('success', 'Notificaition sent to faculty.', 2000, top)
      //   }).catch(err => {
      //     console.log(err)
      //     this.showSnackBar('error', 'Failed to send Notification.', 2000, top)
      //   })
    },
    selectDates() {
      console.log(this.datesType);
      if (this.datesType === "Custom") {
        let date1 = "";
        let date2 = "";
        if (this.date1) {
          date1 = this.convertToDateObject(this.date1);
        }
        if (this.date2) {
          date2 = this.convertToDateObject(this.date2);
        }
        if (date1) {
          if (date2) {
            if (date2 > date1) {
              const daysCount =
                (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
              console.log(
                this.convertToYYYYMMDD(date2.setDate(date2.getDate() + 1)),
                daysCount
              );
              const tomorrow = date1;
              this.dates = [];
              this.dates.push(this.convertToYYYYMMDD(tomorrow));
              for (let i = 0; i < daysCount; i++) {
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.dates.push(this.convertToYYYYMMDD(tomorrow));
              }
              // do {
              //   let tomorrow = new Date(d)
              //   tomorrow.setDate(tomorrow.getDate() + 1)
              //   console.log(tomorrow)
              //   b = tomorrow
              //   d = convertDate(tomorrow)
              // } while (date2 > b)
              this.date = "" + this.dates.length + " Dates Selected";
              console.log(this.dates);
              this.fetchPlannedLectures();
            } else {
              window.alert("End Date should be greater than Start Date.");
            }
          } else {
            this.dates = [this.date1];
            this.date = this.date1;
            this.fetchPlannedLectures();
          }
        } else {
          window.alert("Select Start Date.");
        }
      }
      if (this.datesType === "Today") {
        const today = new Date();
        this.dates = [
          today.getFullYear() +
            "-" +
            ("0" + (today.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + today.getDate()).slice(-2),
        ];
        this.date = this.convertToYYYYMMDD(new Date());
        this.fetchPlannedLectures();
      }
    },
    showReasonFun(lecture) {
      console.log(lecture);
      this.reasonOfCancellingLecture = lecture.reasonOfCancellingLecture;
      this.showReason = true;
    },
    showSnackBar(type, text, time, direction) {
      this.snackType = type;
      this.snackText = text;
      this.snackTime = time;
      this.snackDirection = direction;
      this.snackbar = true;
    },
    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersList.indexOf(semName);
      return this.semesters[index];
    },
    convertToYYYYMMDD(dateString) {
      return this.convertToDDMMYYYY(dateString).split("-").reverse().join("-");
    },
    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },
    convertToDateObject(dateString) {
      // console.log('dateString', dateString)
      return convertFromYYYYMMDDToDate(dateString);
    },
    convertToISOString(date) {
      const convertedDate = new Date(date.valueOf());
      convertedDate.setHours(5, 30, 0, 0);
      return convertedDate.toISOString();
    },
    async getOnlineLecturesStatus() {
      this.showOnlineLectures = true;
      this.loading = true;
      this.onlineLectures = [];

      const objToPush = {
        instituteId: this.selectedInstitute,
      };

      try {
        const hosts =
          await this.onlineLectureRepositoryInstance.getOnlineLectureHosts(
            objToPush
          );
        this.totalHosts = hosts.length;
        this.hostsOccupied = hosts.filter((elem) => elem.isOccupied).length;
      } catch (err) {
        console.log(err);
      }

      let promises = [];

      try {
        const onlineLectures =
          await this.onlineLectureRepositoryInstance.getAllCurrentlyOngoingLectures(
            objToPush
          );

        promises = onlineLectures.map((onlineLecture) =>
          this.getLecture(onlineLecture.lectureId)
        );
      } catch (err) {
        console.log(err);
      }

      await Promise.allSettled(promises);
      this.loading = false;
    },
    async getLecture(lectureId) {
      try {
        const lecture = await this.lectureRepositoryInstance.getLecture({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          lectureId: lectureId,
        });

        lecture.dateTime = this.convertToDDMMYYYY(lecture.dateTime);
        this.onlineLectures.push(lecture);
      } catch (err) {
        print(err);
      }
    },
  },
};
</script>

<style src="./activityDashboard.css"></style>
