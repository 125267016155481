<template src="./activityManageUsers.html"> </template>
<script>
import UserRepository from '../../Repository/User'
import InstituteUserRepository from '../../Repository/InstituteUser'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
import axios from 'axios'

export default {
  name: 'activityManageUsers',
  components: {
    inputContainer
  },
  data () {
    return {
      file: null,
      showUploadUsersDialog: false,
      loading: false,
      createUserLoading: false,
      search: '',
      addingParent: false,
      editingParent: false,
      addUserDialog: false,
      editUserDialog: false,
      addUserStepNumber: 1,
      selectedUser: '',
      tempFirstName: '',
      tempInstituteProvidedId: '',
      tempMiddleName: '',
      tempLastName: '',
      tempEmails: [],
      tempPhones: [],
      parents: [],
      parentsEdited: [],
      tempEmail: '',
      userName: '',
      tempPhone: '',
      addingEmail: false,
      addingPhone: false,
      tempGender: '',
      tempDateOfBirth: '',
      tempStudentId: '',
      tempBloodGroup: '',
      genders: ['Male', 'Female', 'Other'],
      bloodGroups: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'fullName',
          filterable: true
        },
        {
          text: 'Institute Id',
          sortable: true,
          value: 'instituteProvidedId',
          filterable: true
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'center'
        }
      ],
      users: []
    }
  },
  created () {
    this.userRepositoryInstance = new UserRepository(this)
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Manage Users')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]

    this.fetchData()
  },
  methods: {
     async sendWelcomeMail() {
      try {
        let objToPush = {
            senderMail: this.senderMail,
            senderMailPassword: this.senderMailPassword,
            displayName: this.displayName,
            subjectDescription: this.subjectDescription,
            brandingLogo: this.instituteStudentLogoLink,
            brandingName: this.instituteStudentBrandingName,
            admissionName: this.selectedAdmission.admissionName,
            firstName: this.userObject.firstName,
            lastName: this.userObject.lastName,
            emailId: this.userObject.emailId,
        }
        console.log('objToPush send welcome mail',objToPush);
        const res = await this.MailSenderRepositoryInstance.sendWelcomeMail(
          objToPush
        )
        console.log('MAIL RESPONSE', res)
      } catch (e) {
        console.log('err', e);
      }
    },
    async fetchData () {
      this.users = []
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute
      }

      try {
        const instituteUsers = await this.instituteUserRepositoryInstance.getUsersOfAnInstitute(
          objToPush
        )

        for (let i = 0; i < instituteUsers.length; i++) {
          this.users.push({
            fullName: '',
            instituteProvidedId: instituteUsers[i].instituteProvidedId,
            uId: instituteUsers[i].uId
          })
        }
      } catch (err) {
        console.log(err)
      }

      this.loading = false

      const batchSize = 100

      const promises = []
      for (let i = 0; i < this.users.length; i += batchSize) {
        promises.push(
          this.getFullNameOfUser(
            i,
            i + batchSize < this.users.length
              ? i + batchSize
              : this.users.length
          )
        )
      }

      await Promise.allSettled(promises)
    },
    async openUploadUsersDialog () {
      this.showUploadUsersDialog = true
    },
    async uploadExcel () {
      if (this.file) {
        var formData = new FormData()
        formData.append('instituteId', this.selectedInstitute)
        formData.append('excel', this.file)
        axios
          .post(
            process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + 'excel/user/parse',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(
            response => {
              console.log(response.data)
            },
            error => {
              console.log(error)
            }
          )
          .then(() => {
            this.showUploadUsersDialog = false
          })
      }
    },
    async getFullNameOfUser (firstIndex, lastIndex) {
      console.log(firstIndex, lastIndex)
      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.users[i].uId)
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        )

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(elem => elem.uId === this.users[i].uId)
          this.users[i].fullName = user ? user.fullName : ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    startEditingDetailsOfUser (item) {
      console.log(item)
      this.editUserDialog = true
      this.selectedUser = item
      this.getDataOfUserToEdit()
      this.getParentsOfStudent()
    },
    async getParentsOfStudent () {
      try {
        this.parents = await this.userRepositoryInstance.getParentsOfAUser({
          uId: this.selectedUser.uId
        })
        console.log(this.parents)
      } catch (err) {
        this.parents = []
        console.log(err)
      }

      for (const parent of this.parents) {
        console.log(parent)
        try {
          const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication(
            { uId: parent.uId }
          )
          console.log("emailsAndPhones------------->",emailsAndPhones)
          if(emailsAndPhones.userName !== undefined && emailsAndPhones.userName !== null && emailsAndPhones.userName !== ""){
            this.userName = emailsAndPhones.userName;
          }
          if (emailsAndPhones.emails) {
            emailsAndPhones.emails.forEach(email => {
              if (parent.emails) {
              } else {
                parent.emails = []
              }
              parent.emails.push(email)
            })
          }
          if (emailsAndPhones.phones) {
            emailsAndPhones.phones.forEach(phone => {
              if (parent.phones) {
              } else {
                parent.phones = []
              }
              parent.phones.push(phone)
            })
          }
        } catch (err) {
          console.log(err)
        }
      }
      this.parentsEdited = JSON.parse(JSON.stringify(this.parents))
    },
    async getDataOfUserToEdit () {
      const objToPush = {
        uId: this.selectedUser.uId
      }
      try {
        const user = await this.userRepositoryInstance.getUserProfile(
          objToPush
        )
        this.tempFirstName = user.firstName
        this.tempInstituteProvidedId = this.selectedUser.instituteProvidedId
        this.tempMiddleName = user.middleName
        this.tempLastName = user.lastName
        this.tempDateOfBirth = user.dateOfBirth
          ? this.parseDate(user.dateOfBirth)
          : ''
        this.tempGender = user.gender
        console.log(this.selectedUser)
        this.selectedUser.emails = []
        this.selectedUser.phones = []
        const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication(
          objToPush
        )
        console.log("emailsAndPhones------------->",emailsAndPhones)
        if(emailsAndPhones.userName !== undefined && emailsAndPhones.userName !== null && emailsAndPhones.userName !== ""){
          this.userName = emailsAndPhones.userName;
        }
        if (emailsAndPhones.emails) {
          emailsAndPhones.emails.forEach(email => {
            this.tempEmails.push(email)
            this.selectedUser.emails.push(email)
          })
        }
        if (emailsAndPhones.phones) {
          emailsAndPhones.phones.forEach(phone => {
            this.tempPhones.push(phone)
            this.selectedUser.phones.push(phone)
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    closeEditUserDialog () {
      this.editUserDialog = false
      this.selectedUser = ''
      this.tempFirstName = ''
      this.tempInstituteProvidedId = ''
      this.tempMiddleName = ''
      this.tempLastName = ''
      this.tempEmails = []
      this.tempPhones = []
      this.tempGender = ''
      this.tempDateOfBirth = ''
      this.tempBloodGroup = ''
    },
    async saveEditedDetailsOfUser () {
      if (this.tempFirstName) {
        try {
          await this.userRepositoryInstance.updateFirstName({
            uId: this.selectedUser.uId,
            firstName: this.tempFirstName
          })
        } catch (err) {
          console.log(err)
          showStatus('Error updating first name', 1000, 'error', this)
        }
      }
      if(this.userName !== undefined && this.userName !== null && this.userName !== ""){
        try {
          await this.userRepositoryInstance.addAndUpdateUserName({
            uId: this.selectedUser.uId,
            userName:this.userName
          });
        } catch (err) {
          console.log(err)
        }
      }
      if (this.tempInstituteProvidedId) {
        try {
          await this.instituteUserRepositoryInstance.updateInstituteProvidedId({
            instituteId: this.selectedInstitute,
            uId: this.selectedUser.uId,
            instituteProvidedId: this.tempInstituteProvidedId
          })
        } catch (err) {
          console.log(err)
          showStatus(
            'Error Updating Institute Provided Id.',
            1000,
            'error',
            this
          )
        }
      }
      if (this.tempMiddleName) {
        try {
          await this.userRepositoryInstance.updateMiddleName({
            uId: this.selectedUser.uId,
            middleName: this.tempMiddleName
          })
        } catch (err) {
          console.log(err)
          showStatus('Error updating middle name', 1000, 'error', this)
        }
      }
      if (this.tempLastName) {
        try {
          await this.userRepositoryInstance.updateLastName({
            uId: this.selectedUser.uId,
            lastName: this.tempLastName
          })
        } catch (err) {
          console.log(err)
          showStatus('Error updating last name', 1000, 'error', this)
        }
      }
      if (this.tempGender) {
        try {
          await this.userRepositoryInstance.updateGender({
            uId: this.selectedUser.uId,
            gender: this.tempGender
          })
        } catch (err) {
          console.log(err)
          showStatus('Error updating gender', 1000, 'error', this)
        }
      }
      if (this.tempDateOfBirth) {
        try {
          await this.userRepositoryInstance.updateDateOfBirth({
            uId: this.selectedUser.uId,
            dateOfBirth: this.tempDateOfBirth
          })
        } catch (err) {
          console.log(err)
          showStatus('Error updating date of birth', 1000, 'error', this)
        }
      }

      await this.addAndRemoveEmailsAndPhones()

      this.closeEditUserDialog()
    },
    async addAndRemoveEmailsAndPhones () {
      const emailsToDelete = this.selectedUser.emails.filter(
        email => !this.tempEmails.includes(email)
      )

      for (let i = 0; i < emailsToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deleteEmail({
            uId: this.selectedUser.uId,
            email: emailsToDelete[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const phonesToDelete = this.selectedUser.phones.filter(
        phone => !this.tempPhones.includes(phone)
      )

      for (let i = 0; i < phonesToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deletePhone({
            uId: this.selectedUser.uId,
            phone: phonesToDelete[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const emailsToAdd = this.tempEmails.filter(
        email => !this.selectedUser.emails.includes(email)
      )

      for (let i = 0; i < emailsToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addEmail({
            uId: this.selectedUser.uId,
            email: emailsToAdd[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const phonesToAdd = this.tempPhones.filter(
        phone => !this.selectedUser.phones.includes(phone)
      )

      for (let i = 0; i < phonesToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addPhone({
            uId: this.selectedUser.uId,
            phone: phonesToAdd[i]
          })
        } catch (err) {
          console.log(err)
        }
      }
    },
    parseDate (dateOfBirth) {
      const parsedDate = new Date(dateOfBirth)
      return (
        parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getUTCDay()).slice(-2)
      )
    },
    addEmail () {
      this.tempEmails.push(this.tempEmail)
      this.tempEmail = ''
      this.addingEmail = false
    },
    addPhone () {
      this.tempPhones.push(this.tempPhone)
      this.tempPhone = ''
      this.addingPhone = false
    },
    openAddUserDialog () {
      this.addUserDialog = true
      this.selectedUser = {}
    },
    closeAddUserDialog () {
      this.addUserDialog = false
      this.addUserStepNumber = 1
      this.selectedUser = ''
      this.tempFirstName = ''
      this.tempInstituteProvidedId = ''
      this.tempMiddleName = ''
      this.tempLastName = ''
      this.tempEmails = []
      this.tempPhones = []
      this.tempGender = ''
      this.tempDateOfBirth = ''
      this.tempBloodGroup = ''
    },
    closeAddParentDialog () {
      this.addingParent = false
      this.addUserStepNumber = 1
      this.selectedUser = ''
      this.tempFirstName = ''
      this.tempInstituteProvidedId = ''
      this.tempMiddleName = ''
      this.tempLastName = ''
      this.tempEmails = []
      this.tempPhones = []
      this.tempGender = ''
      this.tempDateOfBirth = ''
      this.tempBloodGroup = ''
    },
    async createUserProfileAndMoveToNextStep () {
      this.createUserLoading = true
      const objToPush = {
        firstName: this.tempFirstName,
        middleName: this.tempMiddleName,
        lastName: this.tempLastName,
        gender: this.tempGender,
        dateOfBirth: this.tempDateOfBirth
      }

      if (this.addingParent) {
        objToPush.childrenUserIds = [this.tempStudentId]
      } else {
        objToPush.instituteProvidedId = this.tempInstituteProvidedId
        objToPush.instituteId = this.selectedInstitute
      }

      try {
        this.selectedUser = await this.userRepositoryInstance.createUser(
          objToPush
        )
        this.addUserStepNumber = 2
        this.createUserLoading = false
      } catch (err) {
        console.log(err)
        showStatus('Error creating user', 1000, 'error', this)
      }
    },
    async createUserEmailsAndPhones () {
      if(this.userName !== undefined && this.userName !== null && this.userName !== ""){
        try {
          await this.userRepositoryInstance.addAndUpdateUserName({
            uId: this.selectedUser.uId,
            userName:this.userName
          });
        } catch (err) {
          console.log(err)
        }
      }

      for (let i = 0; i < this.tempEmails.length; i++) {
        try {
          await this.userRepositoryInstance.addEmail({
            uId: this.selectedUser.uId,
            email: this.tempEmails[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      for (let i = 0; i < this.tempPhones.length; i++) {
        try {
          await this.userRepositoryInstance.addPhone({
            uId: this.selectedUser.uId,
            phone: this.tempPhones[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      this.addUserStepNumber = 3
      showStatus('User Added successfully', 1000, 'success', this)
      this.closeAddUserDialog()
      this.fetchData()
    },
    validateUserProfileFields () {
      if (
        (this.tempFirstName || this.tempMiddleName || this.tempLastName) &&
        this.tempGender &&
        this.tempDateOfBirth
      ) {
        return false
      } else {
        return true
      }
    },
    removeFromArray (item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    }
  }
}
</script>
<style src="./activityManageUsers.css" scoped></style>
