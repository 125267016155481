import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/OnlineLecture'

class OnlineLecture {
  constructor (context) {
    this.context = context
  }

  async getOnlineLectureHosts (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getOnlineLectureHostsOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.hosts
  }

  async getAllCurrentlyOngoingLectures (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllCurrentlyOngoingLectures, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.onlineLectures
  }

  async updateOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.putRequest(ApiNames.updateOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getLinkOfOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLinkOfOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.redirectionUrl
  }

  async getRedirectionUrl (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRedirectionUrl, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.redirectionUrl
  }
}

export default OnlineLecture
