<template src='./activitySyllabusTracking.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import { subjectTypes } from '../../Constants/Utils/Statics'
import smallButton from '../../Components/smallButton'
import UserRepository from '../../Repository/User'
import LectureRepository from '../../Repository/Lecture'
import TopicRepository from '../../Repository/Topic'
import SemesterRepository from '../../Repository/Semester'
import SemesterUserRepository from '../../Repository/SemesterUser'
import TimetableRepository from '../../Repository/Timetable'
import SubjectRepository from '../../Repository/Subject'
import { formatToSlashSepearated, timeStringConverter, convertDate } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from '../../Services/Utils/arrayOps'
export default {
  name: 'activitySyllabusTracking',
  components: {
    inputContainer,
    smallButton
  },
  data () {
    return {
      dataFetched: false,
      stepOneCompleted: false,
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      departments: [],
      selectedDepartment: '',
      selectedDate: '',
      selectedFaculty: '',
      faculties: [],
      facultyNames: [],
      allocations: [],
      // occupiedSlots: [],
      subjectsMapping: {},
      selectedAllocation: {
        name: ''
      },
      dates: [],
      datesBackup: [],
      userData: {},
      addingTopic: false,
      subjectProgress: 0,
      topicsList: [],
      headers: [
        {
          text: 'Name Of Topic',
          value: 'topicName'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        },
        {
          text: 'No. Of Lectures',
          value: 'lectureCount'
        },
        {
          text: '',
          value: 'isSelected'
        }
      ],
      tempTopicName: '',
      selectedTopic: {},
      topicsMapping: {},
      lecturesList: [],
      headers2: [
        {
          text: '',
          value: 'selection'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        }
      ],
      headers3: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Conducted',
          value: 'isConducted'
        },
        {
          text: 'Implementation',
          value: 'action'
        }
      ],
      headers4: [
        {
          text: '',
          value: 'statusDot'
        },
        {
          text: 'Question',
          value: 'question'
        },
        {
          text: 'Average Answer',
          value: 'averageAnswer'
        }
      ],
      selection: [],
      isTopicSelected: false,
      selectedSubject: {},
      processingDate: false,
      showStudentFeedback: false,
      topicFeedbackQuestions: [],
      isFeedbackLaunched: false,
      subjectTypes: subjectTypes,
      count: 0
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'Syllabus Tracker')
    // if (this.prop_subject) {
    //   this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    // } else {
    //   this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    // }
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.topicRepositoryInstance = new TopicRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      this.dataFetched = false
      this.stepOneCompleted = false
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.departments = []
        const divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async selectDate () {
      this.dataFetched = false
      this.selectedDepartment = ''
      this.selectedFaculty = ''
      this.faculties = []
      this.facultyNames = []
      console.log(new Date(this.selectedDate))
    },
    async selectDepartment () {
      this.dataFetched = false
      this.stepOneCompleted = false
      this.faculties = []
      this.facultyNames = []
      this.selectedFaculty = ''
      this.faculties = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment
      })
      let promises = []
      promises = this.faculties.map((faculty) => {
        return this.userRepositoryInstance.getFullNameOfUser({ uId: faculty.uId })
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.facultyNames = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          // console.log(allSettledPromises[i].value)
          this.facultyNames.push(allSettledPromises[i].value)
        }
      }
      console.log(this.facultyNames)
    },
    async selectFaculty () {
      this.dataFetched = false
      this.stepOneCompleted = false
      console.log(this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId
      }
      try {
        const res = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(objToPush)
        console.log(res)
        this.allocations = res
      } catch (err) {
        console.log(err)
        this.allocations = []
      }
      // try {
      //   objToPush.dateOfLecturesNeeded = new Date(this.selectedDate).toISOString()
      //   this.occupiedSlots = await this.lecturesRepositoryInstance.getLecturesForADay(objToPush)
      //   console.log(this.occupiedSlots)
      // } catch (err) {
      //   this.occupiedSlots = []
      //   console.log(err)
      // }
      try {
        // for (let i = 0; i < this.allocations.length; i++) {
        //   await this.getSubjectDetails(this.allocations[i])
        // }
        let promises = []
        promises = this.allocations.map((allocation) => {
          return this.getSubjectDetails(allocation.subjectId)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            // console.log(allSettledPromises[i].value)
            this.subjectsMapping[allSettledPromises[i].value.subjectId] = (allSettledPromises[i].value)
          }
        }
        console.log(this.subjectsMapping, allSettledPromises)
        this.dataFetched = true
      } catch (err) {
        console.log(err)
      }
    },
    async getSubjectDetails (subjectId) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: subjectId
      }
      try {
        const response = await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush)
        console.log(objToPush, {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType
        })
        return {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType
        }
      } catch (err) {
        console.log(err)
        return err
      }
    },
    async selectAllocation (allocation) {
      this.selectedAllocation = {
        name: allocation.subjectId + '_' + allocation.department + '_' + allocation.courseYear + '_' + allocation.division + '_' + allocation.batch,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: allocation.division,
        batch: allocation.batch,
        subjectId: allocation.subjectId
      }
      this.stepOneCompleted = false
      this.selectedSubject = allocation
      await this.fetchData2()
    },
    async fetchData2 () {
      this.topicsList = []
      let topicsList = []
      try {
        topicsList = await this.topicRepositoryInstance.getTopicsOfADivisionOfASubject(this.selectedSubject)
      } catch (err) {
        topicsList = []
        console.log(err)
      }
      console.log(topicsList)
      this.topicsMapping = {}
      topicsList.forEach((topic) => {
        if (!(this.topicsMapping[topic.topicId])) {
          this.topicsMapping[topic.topicId] = []
        }
        this.topicsMapping[topic.topicId].push(topic)
      })
      // Object.values(this.topicsMapping).forEach((topicLectures) => {
      //   this.processTopicLectures(topicLectures)
      // })
      this.count = 0
      for (let i = 0; i < Object.values(this.topicsMapping).length; i++) {
        const topicLectures = Object.values(this.topicsMapping)[i]
        await this.processTopicLectures(topicLectures)
      }
      console.log(this.count)
      this.subjectProgress = ((this.count / Object.keys(this.topicsMapping).length) * 100).toFixed(2)
      this.startAddingTopic()
      this.stepOneCompleted = true
    },
    async processTopicLectures (topicLectures) {
      const obj = {}
      obj.topicId = topicLectures[0].topicId
      obj.topicName = topicLectures[0].topicName
      obj.implementedCount = 0
      obj.conductedCount = 0
      obj.lectureCount = topicLectures.length
      obj.isSelected = false
      obj.lectures = topicLectures
      const dates = []
      const dateStrings = []
      for (let i = 0; i < topicLectures.length; i++) {
        const topicLecture = topicLectures[i]
        try {
          const objToPush = {
            instituteId: topicLecture.instituteId,
            semId: topicLecture.semId,
            lectureId: topicLecture.lectureId
          }
          console.log(objToPush)
          const lecture = await this.lectureRepositoryInstance.getLecture(objToPush)
          const dateString = lecture.dateTime.slice(0, 10)
          const date = new Date(dateString)
          if (!(dates.includes(date.toISOString()))) { dates.push(date.toISOString()) }
          if (!(dateStrings.includes(dateString))) { dateStrings.push(dateString) }
          topicLecture.isConducted = lecture.isConducted
          topicLecture.date = lecture.dateTime
          topicLecture.startTime = lecture.startTime
          topicLecture.endTime = lecture.endTime
          if (topicLecture.isImplemented) { obj.implementedCount++ }
          if (topicLecture.isConducted) { obj.conductedCount++ }
        } catch (err) {
          console.log(err)
        }
      }
      if (obj.implementedCount === topicLectures.length) {
        this.count++
        console.log('implemented Topic Found!')
      }
      dates.sort()
      obj.startDate = dates[0]
      obj.startDateString = timeStringConverter(dates[0].slice(0, 10))
      obj.endDate = dates[0]
      obj.endDateString = timeStringConverter(dates[(dates.length - 1)].slice(0, 10))
      obj.dates = dateStrings
      this.topicsList.push(obj)
    },
    async startAddingTopic () {
      this.isTopicSelected = false
      this.addingTopic = true
    },
    async datesChanged () {
      this.processingDate = true
      console.log(this.userData)
      const removedDates = []
      const addedDates = []
      this.datesBackup.forEach((date) => {
        if (!(this.dates.includes(date))) {
          removedDates.push(date)
        }
      })
      this.dates.forEach((date) => {
        if (!(this.datesBackup.includes(date))) {
          addedDates.push(date)
        }
      })
      this.datesBackup = JSON.parse(JSON.stringify(this.dates))
      console.log(addedDates, removedDates)
      for (let i = 0; i < addedDates.length; i++) {
        const objToPush = {
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          uId: this.userData.uId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          dateOfLecturesNeeded: new Date(addedDates[i]).toISOString()
        }
        try {
          let timetable = await this.lectureRepositoryInstance.getLecturesForADayForAFaculty(objToPush)
          timetable = timetable.filter((tSlot) => tSlot.department === this.selectedSubject.department && tSlot.courseYear === this.selectedSubject.courseYear && tSlot.division === this.selectedSubject.division && tSlot.batch === this.selectedSubject.batch && tSlot.subjectId === this.selectedSubject.subjectId)
          timetable.forEach((tSlot) => {
            tSlot.date = formatToSlashSepearated(tSlot.dateTime.slice(0, 10))
            tSlot.time = timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime)
            this.lecturesList.push(tSlot)
          })
        } catch (err) {
          console.log(err)
        }
      }
      console.log(removedDates)
      removedDates.forEach((date) => {
        this.lecturesList = this.lecturesList.filter((lecture) => (lecture.dateTime).slice(0, 10) !== date)
        this.selection = this.selection.filter((lecture) => (lecture.dateTime).slice(0, 10) !== date)
      })
      this.processingDate = false
    },
    formatToSlashSepearated (item) {
      return formatToSlashSepearated(item)
    },
    timeStringConverter (item) {
      return timeStringConverter(item)
    },
    async selectTopic (value) {
      console.log(value)
      value.isSelected = true
      this.addingTopic = false
      // Call API to check if Topic Feedback is launched
      this.isFeedbackLaunched = false
      this.isTopicSelected = true
      this.selectedTopic = value
    },
    async editTopic (topic) {
      console.log(topic)
    },
    async deleteTopic (topic) {
      console.log(topic)
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicId: topic.topicId
        }
        await this.topicRepositoryInstance.deleteTopic(objToPush)
        this.topicsList = arrayOps.removeFromArray(this.topicsList, this.topicsList.indexOf(topic))
      } catch (err) {
        console.log(err)
      }
    },
    async createTopic () {
      console.log(this.tempTopicName, this.selection)
      // let topicId = ''
      for (let i = 0; i < this.selection.length; i++) {
        try {
          const objToPush = {
            instituteId: this.selection[i].instituteId,
            semId: this.selection[i].semId,
            department: this.selectedSubject.department,
            courseYear: this.selectedSubject.courseYear,
            division: this.selectedSubject.division,
            batch: this.selectedSubject.batch,
            subjectId: this.selectedSubject.subjectId,
            topicName: this.tempTopicName,
            lectureId: this.selection[i].lectureId
          }
          console.log(objToPush)
          const topic = await this.topicRepositoryInstance.createTopic(objToPush)
          console.log(topic)
          // if (!(this.topicsMapping[topic.topicId])) { this.topicsMapping[topic.topicId] = [] }
          // this.topicsMapping[topic.topicId].push(topic)
          // topicId = topic.topicId
          this.fetchData()
        } catch (err) {
          console.log(err)
        }
      }
      // if (this.topicsList.length > 0) {
      //   this.processTopicLectures(this.topicsMapping[topicId])
      //   this.selectedTopic = JSON.parse(JSON.stringify(this.topicsList[this.topicsList.length - 1]))
      // }
    },
    async markAsImplemented (item, isImplemented) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicId: this.selectedTopic.topicId,
          lectureId: item
        }
        if (isImplemented) {
          await this.topicRepositoryInstance.markAsImplemented(objToPush)
        } else {
          await this.topicRepositoryInstance.markAsUnimplemented(objToPush)
        }
        const indexOfLectureTopic = this.selectedTopic.lectures.indexOf(item)
        if (indexOfLectureTopic > -1) {
          this.selectedTopic.lectures[indexOfLectureTopic].isImplemented = isImplemented
        }

        const indexOfTopic = this.topicsList.findIndex((topic) => topic.topicId === this.selectedTopic.topicId)
        if (indexOfTopic > -1) {
          this.topicsList[indexOfTopic].lectures[indexOfLectureTopic].isImplemented = isImplemented
        }
      } catch (err) {
        console.log(err)
      }
    },
    async gotoFeedbackAnalysis (topic) {
      this.$store.commit('liveData/set_selectedTopic', topic)
      this.$router.push('/activityFeedbackAnalysis')
    },
    async fetchStudentFeedback () {
      // Call Api to fetch students feedback for the topic
      const objToPush = JSON.parse(JSON.stringify(this.selectedTopic))
      console.log(objToPush)
      this.topicFeedbackQuestions = []
      this.showStudentFeedback = true
    },
    async launchStudentFeedback () {
      // Call Api to lauch students feedback for the topic
      const objToPush = JSON.parse(JSON.stringify(this.selectedTopic))
      console.log(objToPush)
    },
    convertDate (date) {
      return convertDate(date)
    }
  }
}
</script>

<style src='./activitySyllabusTracking.css'>
</style>
