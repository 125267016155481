<template src='./activityEditSemesterSubjects.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import { subjectTypes } from '../../Constants/Utils/Statics'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
import SubjectRepository from '../../Repository/Subject'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityEditSemesterSubjects',
  components: {
    inputContainer,
    smallButton
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'tree'
  ],
  data () {
    return {
      loading: true,
      departments: [],
      selectedDepartment: '',
      courseYears: [],
      selectedCourseYear: '',
      subjects: [],
      subjectGroups: {},
      addingSubject: false,
      tempSubjectName: '',
      tempGroupName: '',
      tempSubjectCode: '',
      tempTheory: false,
      tempPractical: false,
      tempTutorial: false,
      tempAuditCourse: false,
      tempElective: false,
      tempElectiveType: 1,
      tempSubjectType: '',
      electiveTypes: [1, 2, 3, 4],
      headers: [
        {
          text: 'Subject Name',
          value: 'subjectName'
        },
        {
          text: 'Subject Type',
          value: 'subjectType'
        },
        {
          text: 'Credits',
          value: 'credits'
        },
        {
          text: 'Subject Coordinators',
          value: 'subjectCoordinators'
        },
        {
          text: 'Assigned Faculties',
          value: 'faculties'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      subjectTypes: subjectTypes,
      editingGroupId: '',
      editing: false,
      tempVariantName: '',
      tempCredits: 0,
      editingVariant: false,
      deletingVariant: false,
      tempVariant: {},
      tempSubjectCoordinators: [],
      tempFaculties: [],
      faculties: [],
      headers2: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      search: '',
      selectedInstitute: '',
      selectedSemester: {},
      facultyNames: [],
      addingFaculties: false,
      addingCoordinator: false,
      tempSubjectForDeleting: {}
    }
  },
  created () {
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    if (this.tree) {
      console.log(this.tree)
      for (let i = 0; i < this.tree.length; i++) {
        this.departments.push(this.tree[i].name)
      }
    } else {
      this.departments = []
    }
    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.selectedDepartment = this.prop_selectedDepartment
      this.selectDepartment()
      this.selectedCourseYear = this.prop_selectedCourseYear
      this.selectCourseYear()
    }
    this.loading = false
  },
  methods: {
    addingSubjectStep1 () {
      this.editing = false
      this.tempGroupName = ''
      this.tempSubjectName = ''
      this.tempSubjectCode = ''
      this.tempTheory = false
      this.tempPractical = false
      this.tempTutorial = false
      this.tempAuditCourse = false
      this.tempElective = false
      this.tempElectiveType = 1
      this.addingSubject = true
    },
    selectDepartment () {
      this.loading = true
      this.courseYears = []
      this.selectedCourseYear = ''
      const index = this.departments.indexOf(this.selectedDepartment)
      for (let i = 0; i < this.tree[index].children.length; i++) {
        this.courseYears.push(this.tree[index].children[i].name)
      }
      this.loading = false
    },
    async selectCourseYear () {
      this.loading = true
      this.subjects = []
      this.subjectGroups = {}
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear
      }
      console.log(objToPush)

      try {
        const response = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)
        console.log('response', response)
        this.subjects = response
      } catch (err) {
        showStatus('Could not fetch subjects', 1000, 'error', this)
        console.log(err)
        this.subjects = []
      }

      if (this.faculties.filter((faculty) => faculty.department === this.selectedDepartment).length === 0) {
        console.log(objToPush)
        this.faculties = []
        let facultyObjects = []

        try {
          facultyObjects = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(objToPush) || []
        } catch (err) {
          console.log(err)
        }

        for (let i = 0; i < facultyObjects.length; i++) {
          try {
            const nameOfFaculty = await this.userRepositoryInstance.getFullNameOfUser({ uId: facultyObjects[i].uId })

            if (nameOfFaculty) {
              this.facultyNames[facultyObjects[i].uId] = nameOfFaculty
              this.faculties.push(
                {
                  uId: facultyObjects[i].uId,
                  name: nameOfFaculty,
                  department: facultyObjects[i].department
                }
              )
            }
          } catch (err) {
            console.log(err)
          }
        }
      }

      this.subjectGroups = []

      for (let i = 0; i < this.subjects.length; i++) {
        console.log(this.subjects[i])
        if (!this.subjectGroups[this.subjects[i].groupId]) {
          this.subjectGroups[this.subjects[i].groupId] = []
        }
        this.subjectGroups[this.subjects[i].groupId].push(this.subjects[i])
      }

      this.loading = false
      this.subjectGroups.slice()
      console.log(this.subjects)
    },
    async saveSubject () {
      this.loading = true
      const tempGroupId = new Date().valueOf()
      if (this.editing) {
        for (let i = 0; i < this.subjectGroups[this.editingGroupId].length; i++) {
          try {
            const obj = JSON.parse(JSON.stringify(this.subjectGroups[this.editingGroupId][i]))
            obj.groupName = this.tempGroupName
            console.log(obj)
            const response = await this.subjectRepositoryInstance.updateGroupName(obj)
            showStatus('Changes Updated Successfully.', 1000, 'success', this)
            if (response) {
              this.subjectGroups[this.editingGroupId][i] = obj
            }
          } catch (err) {
            showStatus('Could not connect to server.', 1000, 'error', this)
            console.log(err)
          }
        }
      } else {
        const tempSubjects = []
        if (this.tempTheory) {
          const obj = {}
          obj.instituteId = this.selectedInstitute
          obj.semId = this.selectedSemester.semId
          obj.department = this.selectedDepartment
          obj.courseYear = this.selectedCourseYear
          obj.subjectName = this.tempSubjectName
          obj.subjectCode = this.tempSubjectCode
          obj.subjectType = this.tempElective ? 4 : 0
          obj.groupName = this.tempGroupName
          obj.groupId = tempGroupId
          obj.credits = 0
          obj.subjectCoordinators = []
          obj.faculties = []
          obj.electiveType = this.tempElective ? this.tempElectiveType : undefined
          tempSubjects.push(obj)
        }
        if (this.tempPractical) {
          const obj = {}
          obj.instituteId = this.selectedInstitute
          obj.semId = this.selectedSemester.semId
          obj.department = this.selectedDepartment
          obj.courseYear = this.selectedCourseYear
          obj.subjectName = this.tempSubjectName
          obj.subjectCode = this.tempSubjectCode
          obj.subjectType = this.tempElective ? 5 : 1
          obj.groupName = this.tempGroupName
          obj.groupId = tempGroupId
          obj.credits = 0
          obj.subjectCoordinators = []
          obj.faculties = []
          obj.electiveType = this.tempElective ? this.tempElectiveType : undefined
          tempSubjects.push(obj)
        }
        if (this.tempTutorial) {
          const obj = {}
          obj.instituteId = this.selectedInstitute
          obj.semId = this.selectedSemester.semId
          obj.department = this.selectedDepartment
          obj.courseYear = this.selectedCourseYear
          obj.subjectName = this.tempSubjectName
          obj.subjectCode = this.tempSubjectCode
          obj.subjectType = this.tempElective ? 6 : 2
          obj.groupName = this.tempGroupName
          obj.groupId = tempGroupId
          obj.credits = 0
          obj.subjectCoordinators = []
          obj.faculties = []
          obj.electiveType = this.tempElective ? this.tempElectiveType : undefined
          tempSubjects.push(obj)
        }
        if (this.tempAuditCourse) {
          const obj = {}
          obj.instituteId = this.selectedInstitute
          obj.semId = this.selectedSemester.semId
          obj.department = this.selectedDepartment
          obj.courseYear = this.selectedCourseYear
          obj.subjectName = this.tempSubjectName
          obj.subjectCode = this.tempSubjectCode
          obj.subjectType = this.tempElective ? 7 : 3
          obj.groupName = this.tempGroupName
          obj.groupId = tempGroupId
          obj.credits = 0
          obj.subjectCoordinators = []
          obj.faculties = []
          obj.electiveType = this.tempElective ? this.tempElectiveType : undefined
          tempSubjects.push(obj)
        }
        console.log(tempSubjects)
        for (let i = 0; i < tempSubjects.length; i++) {
          try {
            const response = await this.subjectRepositoryInstance.createSubject(tempSubjects[i])
            showStatus('Subject Created Successfully.', 500, 'success', this)
            if (!this.subjectGroups[this.tempSubjectName]) {
              this.subjectGroups[this.tempSubjectName] = []
            }
            this.subjectGroups[this.tempSubjectName].push(response)
          } catch (err) {
            showStatus('Could not connect to server.', 1000, 'error', this)
            console.log(err)
          }
        }
        this.subjectGroups.slice()
      }
      await this.selectCourseYear()
      this.loading = false
      this.addingSubject = false
    },
    editSubjectVariant (sub) {
      console.log(sub)
      this.tempVariant = sub
      this.tempVariantName = sub.subjectName
      this.tempCredits = sub.credits
      this.tempSubjectType = this.subjectTypes[sub.subjectType]
      this.tempSubjectCoordinators = JSON.parse(JSON.stringify(sub.subjectCoordinators))
      this.tempFaculties = JSON.parse(JSON.stringify(sub.faculties))
      this.editingVariant = true
    },
    deleteSubjectVariant (sub) {
      this.tempSubjectForDeleting = JSON.parse(JSON.stringify(sub))
      this.deletingVariant = true
    },
    showNameForId (id) {
      return id
    },
    startEditing (id) {
      this.editingGroupId = id
      this.tempGroupName = this.subjectGroups[id][0].groupName
      this.editing = true
      this.addingSubject = true
    },
    async removeSubjectCoordinator (item) {
      console.log(item)
      arrayOps.removeFromArray(this.tempSubjectCoordinators, item)
    },
    removeTeacher (item) {
      console.log(item)
      arrayOps.removeFromArray(this.tempFaculties, item)
    },
    assignCoordinator (item) {
      console.log(item)
      this.tempSubjectCoordinators.push(item.uId)
      this.addingCoordinator = false
    },
    assignTeacher (item) {
      this.tempFaculties.push(item.uId)
      this.addingFaculties = false
    },
    async saveVariantChanges () {
      this.loading = true
      const indexOfSubject = this.subjectGroups[this.tempVariant.groupId].findIndex((subject) =>
        subject.subjectType === this.tempVariant.subjectType &&
        subject.subjectCode === this.tempVariant.subjectCode &&
        subject.subjectId === this.tempVariant.subjectId
      )
      if (indexOfSubject > -1) {
        await this.updateSubjectName(indexOfSubject)
        await this.updateSubjectCredits(indexOfSubject)
        await this.updateSubjectCoordinators(indexOfSubject)
        await this.updateFacultiesTeachingASubject(indexOfSubject)
        await this.updateSubjectType(indexOfSubject)
        this.editingVariant = false
      }
      this.loading = false
    },
    async deleteVariant () {
      console.log(this.deletingVariant, this.tempSubjectForDeleting)
      await this.subjectRepositoryInstance.deleteSubject(this.tempSubjectForDeleting)
      this.deletingVariant = false
      this.selectCourseYear()
    },
    async updateSubjectName (indexOfSubject) {
      try {
        if (this.tempVariant.subjectName.trim() !== this.tempVariantName.trim()) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            oldSubjectName: this.tempVariant.subjectName.trim(),
            newSubjectName: this.tempVariantName.trim()
          }
          await this.subjectRepositoryInstance.updateSubjectName(objToPush)
          showStatus('Changes Updated Successfully.', 1000, 'success', this)
          this.subjectGroups[this.tempVariant.groupId][indexOfSubject].subjectName = this.tempVariantName
        }
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
    },
    async updateSubjectCredits (indexOfSubject) {
      try {
        if (this.tempVariant.credits !== this.tempCredits) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            credits: this.tempCredits
          }
          await this.subjectRepositoryInstance.updateSubjectCredits(objToPush)
          showStatus('Changes Updated Successfully.', 1000, 'success', this)
          this.subjectGroups[this.tempVariant.groupId][indexOfSubject].credits = this.tempCredits
        }
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
    },
    async updateSubjectCoordinators (indexOfSubject) {
      try {
        if (!arrayOps.compareArrays(this.tempVariant.subjectCoordinators, this.tempSubjectCoordinators)) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            subjectCoordinators: this.tempSubjectCoordinators
          }
          await this.subjectRepositoryInstance.updateSubjectCoordinators(objToPush)
          showStatus('Changes Updated Successfully.', 1000, 'success', this)
          this.subjectGroups[this.tempVariant.groupId][indexOfSubject].subjectCoordinators = this.tempSubjectCoordinators
        }
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
    },
    async updateFacultiesTeachingASubject (indexOfSubject) {
      try {
        if (!arrayOps.compareArrays(this.tempVariant.faculties, this.tempFaculties)) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            faculties: this.tempFaculties
          }
          await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(objToPush)
          showStatus('Changes Updated Successfully.', 1000, 'success', this)
          this.subjectGroups[this.tempVariant.groupId][indexOfSubject].faculties = this.tempFaculties
        }
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
    },
    async updateSubjectType (indexOfSubject) {
      try {
        const subjectType = this.subjectTypes.indexOf(this.tempSubjectType)
        if (!this.tempVariant.subjectType !== subjectType) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            oldSubjectType: this.tempVariant.subjectType,
            newSubjectType: subjectType
          }
          await this.subjectRepositoryInstance.updateSubjectType(objToPush)
          showStatus('Changes Updated Successfully.', 1000, 'success', this)
          this.subjectGroups[this.tempVariant.groupId][indexOfSubject].subjectType = subjectType
        }
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
    }
  }
}
</script>
<style src='./activityEditSemesterSubjects.css' scoped>
</style>
