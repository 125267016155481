export default {
  getCurrentSemesterUserForAUser: '/semesterUser/getCurrentSemesterUserForAUser',
  createSemesterUser: '/semesterUser/createSemesterUser',
  getFacultiesOfADepartment: '/semesterUser/getFacultiesOfADepartment',
  getFacultiesOfAInstitute: '/semesterUser/getFacultiesOfAInstitute',
  getStudentsOfADivision: '/semesterUser/getStudentsOfADivision',
  getStudentsOfACourseYear: '/semesterUser/getStudentsOfACourseYear',
  getStudentsOfADepartment: '/semesterUser/getStudentsOfADepartment',
  getStudentsOfAInstitute: '/semesterUser/getStudentsOfAInstitute',
  updateDepartment: '/semesterUser/updateDepartment',
  updateCourseYear: '/semesterUser/updateCourseYear',
  updateDivision: '/semesterUser/updateDivision',
  updateBatch: '/semesterUser/updateBatch',
  updateRollNumber: '/semesterUser/updateRollNumber',
  updateExamSeatNumber: '/semesterUser/updateExamSeatNumber',
  updateSubjectsOfAStudent: '/semesterUser/updateSubjects',
  deleteSemesterUser: '/semesterUser/deleteSemesterUser',
  getUsersNotInAnySemester: '/semesterUser/getUsersNotInAnySemester',
  importFaculties: '/semesterUser/importFaculties',
  importStudentsOfADivision: '/semesterUser/importStudentsOfADivision'
}
