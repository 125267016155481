<template src='./activityFeedbackAnalysis.html'>
</template>

<script>
export default {
  name: 'activityFeedbackAnalysis',
  data () {
    return {
      selectedTopic: {}
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'Feedback Analysis')
    this.selectedTopic = this.$store.getters['liveData/get_selectedTopic']
    this.fetchData()
  },
  methods: {
    async fetchData () {
      console.log(this.selectedTopic)
      // Call API to get if feedback is launched for topic
      // If yes, call API to get feedback analysis
    }
  }
}
</script>

<style src='./activityFeedbackAnalysis.css'>
</style>
