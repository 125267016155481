export const subjectTypes = [
  'Normal Theory',
  'Normal Practical',
  'Normal Tutorial',
  'Normal Audit Course',
  'Elective Theory',
  'Elective Practical',
  'Elective Tutorial',
  'Elective Audit Course',
  'Course Survey'
]

export const conductableLectureTypes = [0, 1, 3, 4]

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const questionTypes = ['Simple Question', 'Single Answer MCQ', 'Multiselect MCQ']
export const questionTypesTemp = ['Single Answer MCQ']

export const optionEnabled = ['Single Answer MCQ', 'Multiselect MCQ']

export const ownershipStatuses = ['']

export const instituteTypes = ['']

export const semesterTypes = ['Current', 'Upcoming', 'Past']
