export default {
  createSemester: '/semester/createSemester',
  getNamesOfSemesters: '/semester/getNamesOfSemesters',
  getSemesters :'/semester/getSemesters',
  getStatus: '/semester/getStatus',
  getClassbySemesters: '/semester/getClassbySemesters',
  getCoursebySemesters: '/semester/getSemesters',
  getDivisionsOfASemester: '/semester/getDivisionsOfASemester',
  getEditingPermissionOfASemester: '/semester/getEditingPermissionOfASemester',
  getDatesOfADivisionOfASemester: '/semester/getDatesOfADivisionOfASemester',
  getShiftOfADivisionOfASemester: '/semester/getShiftOfADivisionOfASemester',
  getHolidaysOfADepartmentOfASemester: '/semester/getHolidaysOfADepartmentOfASemester',
  getNumberOfBatchesOfADivisionOfASemester: '/semester/getNumberOfBatchesOfADivisionOfASemester',
  getClassTeacherOfADivisionOfASemester: '/semester/getClassTeacherOfADivisionOfASemester',
  updateSemesterDatesForADivision: '/semester/updateSemesterDatesForADivision',
  updateSemesterDatesForACourseYear: '/semester/updateSemesterDatesForACourseYear',
  updateSemesterDatesForADepartment: '/semester/updateSemesterDatesForADepartment',
  updateHolidaysForADivision: '/semester/updateHolidaysForADivision',
  updateHolidaysForACourseYear: '/semester/updateHolidaysForACourseYear',
  updateHolidaysForADepartment: '/semester/updateHolidaysForADepartment',
  updateShiftForADivision: '/semester/updateShiftForADivision',
  updateShiftForACourseYear: '/semester/updateShiftForACourseYear',
  updateShiftForADepartment: '/semester/updateShiftForADepartment',
  updateNumberOfBatchesForADivision: '/semester/updateNumberOfBatchesForADivision',
  updateNumberOfBatchesForACourseYear: '/semester/updateNumberOfBatchesForACourseYear',
  updateNumberOfBatchesForADepartment: '/semester/updateNumberOfBatchesForADepartment',
  updateClassTeacherForADivision: '/semester/updateClassTeacherForADivision'
}
