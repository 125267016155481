<template>
  <div>
    <v-progress-linear
      color="purple"
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <v-radio-group v-model="userType" row>
      <v-radio label="Students" value="students" color="purple"></v-radio>
      <v-radio label="Faculty" value="faculty" color="purple"></v-radio>
      <!-- <v-radio label="Both" value="both" color="purple"></v-radio> -->
    </v-radio-group>

    <div style="display:flex;flex-direction:row;align-items:center">
      <div class="subtitle-2 grey--text">SEMESTER TO IMPORT FROM</div>
      <div style="flex-grow:1"></div>
      <div style="width:300px">
        <v-select
          color="purple"
          label="Select Semester"
          :items="Object.keys(semesterNamesToIds)"
          v-model="semId"
          @change="getMasterDataTree"
        ></v-select>
      </div>
    </div>

    <div style="display:flex;flex-direction:row;align-items:center">
      <div class="subtitle-2 grey--text">ACADEMIC SELECTION</div>
      <div style="flex-grow:1"></div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div style="width:300px">
            <div v-on="on" class="body-1" style="cursor:pointer">
              Select Academic Filter
            </div>
          </div>
        </template>
        <v-row style="background-color:#fff;width:250px">
          <v-col>
            <!-- <v-treeview
              v-model="selection"
              :items="tree"
              selection-type="all"
              selectable
              return-object
            ></v-treeview> -->
            <v-treeview
              v-model="selection"
              :items="tree"
              :selection-type='selectionType'
              selectable
              return-object
            ></v-treeview>
          </v-col>
        </v-row>
      </v-menu>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { apiUrl } from "../../Services/Config/apiConfig";

import SemesterRepository from '../../../Repository/Semester'
export default {
  props: ['semesters'],
  data () {
    return {
      selectedInstitute: '',
      selectionType: 'leaf',
      userType: 'students',
      semId: '',
      loading: false,
      selection: [],
      tree: []
    }
  },
  created () {
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.semesterNamesToIds = {}
    for (let i = 0; i < this.semesters.length; i++) {
      this.semesterNamesToIds[this.semesters[i].semName] = this.semesters[i].semId
    }
  },
  methods: {
    getData () {
      const obj = {}
      obj.userType = this.userType
      obj.semId = this.semesterNamesToIds[this.semId]
      obj.selection = this.selection
      return obj
    },
    async getMasterDataTree () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.semesterNamesToIds[this.semId]
        }
        const semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        this.constructAcademicDataTree(semestersList)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async constructAcademicDataTree (semestersList) {
      const academicDataTree = {}

      semestersList.forEach((sem) => {
        if (!academicDataTree[sem.department]) {
          academicDataTree[sem.department] = {}
        }
        if (!academicDataTree[sem.department][sem.courseYear]) {
          academicDataTree[sem.department][sem.courseYear] = []
        }
        academicDataTree[sem.department][sem.courseYear].push(sem.division)
      })

      let idForDisplay = 0
      this.departments = []
      this.courseYears = []
      this.divisions = []

      Object.keys(academicDataTree).forEach((department, index) => {
        this.departments.push({
          name: department,
          department: department,
          id: index
        })

        const departmentObject = {
          id: idForDisplay,
          name: department,
          children: []
        }

        const courseYearsOfADepartment = {
          id: idForDisplay,
          name: department,
          department: department,
          children: []
        }

        idForDisplay++

        Object.keys(academicDataTree[department]).forEach((courseYear) => {
          const divisionsOfACourseYear = {
            id: idForDisplay,
            name: courseYear,
            children: []
          }

          const courseYearObject = {
            id: idForDisplay,
            name: courseYear,
            department: department,
            courseYear: courseYear
          }

          idForDisplay++

          academicDataTree[department][courseYear].forEach((division) => {
            // idForDisplay++

            divisionsOfACourseYear.children.push({
              id: idForDisplay++,
              department: department,
              courseYear: courseYear,
              name: division
            })
          })

          departmentObject.children.push(divisionsOfACourseYear)
          courseYearsOfADepartment.children.push(courseYearObject)
        })

        this.divisions.push(departmentObject)
        this.courseYears.push(courseYearsOfADepartment)
      })

      console.log(this.divisions, this.courseYears, this.departments)
      this.tree = this.divisions
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
