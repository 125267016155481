import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ShiftReference'

class ShiftReference {
  constructor (context) {
    this.context = context
  }

  async createShiftDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createShift, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shift
  }

  async getShiftDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShifts, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shifts
  }

  async getShiftDetailsOfAShift (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShift, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shift
  }

  async getShiftNames (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShiftNames, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shiftNames
  }

  async getShiftName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShiftName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shiftName
  }

  async updateShiftName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateShiftName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shiftName
  }

  async updateWorkingDays (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateWorkingDays, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.workingDays
  }

  async updateTimeSlots (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateTimeSlots, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timeSlots
  }

  async deleteShiftDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteShift, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getShift (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShift, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shift
  }
}

export default ShiftReference
