<template src='./activityTransferUsers.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import UserRepository from '../../Repository/User'
import InstituteUserRepository from '../../Repository/InstituteUser'
import SemesterUserRepository from '../../Repository/SemesterUser'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityTransferUsers',
  components: {
    inputContainer
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  data () {
    return {
      studentsToTransfer: {},
      askForTransfer: false,
      loading: false,
      transferring: true,
      stepOneCompleted: false,
      modes: ['New Users', 'Semester Users'],
      userTypes: ['Faculties', 'Students'],
      selectedMode: 'New Users',
      selectedUserType: 'Faculties',
      selectedFromSemester: {},
      selectedSemester: {},
      selectedInstitute: '',
      selectedFromSemesterName: '',
      fromDepartments: [],
      selectedFromDepartment: '',
      fromCourseYears: [],
      selectedFromCourseYear: '',
      fromDivisions: [],
      selectedFromDivision: '',
      toDepartments: [],
      selectedToDepartment: '',
      toCourseYears: [],
      selectedToCourseYear: '',
      toDivisions: [],
      selectedToDivision: '',
      semestersObjectsList: [],
      semestersNamesList: [],
      semesterNamesToObjectsMapping: {},
      academicDataTree1: {},
      academicDataTree2: {},
      fromUsers: [],
      toUsers: [],
      headers: [
        {
          text: 'ID',
          value: 'instituteProvidedId'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  created () {
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    console.log(this.tree, this.selectedSemester)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semestersObjectsList = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semestersObjectsList.reverse()
        this.semestersNamesList = this.semestersObjectsList.map((semester) => semester.semName)
        this.semestersNamesList = arrayOps.removeFromArray(this.semestersNamesList, this.semestersNamesList.indexOf(this.selectedSemester.semName))
        this.selectedToDepartment = ''
        this.selectedToCourseYear = ''
        this.toCourseYears = []
        this.selectedToDivision = ''
        this.toDivisions = []
        const obj = await this.getAcademicTreeForSemester(this.selectedSemester.semId)
        this.academicDataTree2 = obj.academicDataTree
        this.toDepartments = obj.departments
      } catch (err) {
        console.log(err)
        this.semestersObjectsList = []
        this.semestersNamesList = []
        this.toDepartments = []
      }
      this.loading = false
    },
    async completeStepOne () {
      this.loading = true
      this.fromUsers = []
      this.toUsers = []
      this.selectedFromSemesterName = ''
      this.selectedFromDepartment = ''
      this.fromDepartments = []
      this.selectedFromCourseYear = ''
      this.fromCourseYears = []
      this.selectedFromDivision = ''
      this.fromDivisions = []
      this.selectedToDepartment = ''
      this.selectedToCourseYear = ''
      this.toCourseYears = []
      this.selectedToDivision = ''
      this.toDivisions = []
      if (this.selectedMode === 'New Users') {
        const uIds = await this.semesterUserRepositoryInstance.getUsersNotInAnySemester({
          instituteId: this.selectedInstitute
        })
        console.log(uIds)
        const promises = uIds.map((uId) => {
          return this.getDetailsOfUId(uId)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        this.fromUsers = []
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            console.log(allSettledPromises[i].value)
            this.fromUsers.push(allSettledPromises[i].value)
          }
        }
      }
      this.stepOneCompleted = true
      this.loading = false
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersNamesList.findIndex(item => item.semName === semName)
      return this.semestersObjectsList[index]
    },
    async setupDataForActivity () {},
    async getDepartments1 () {
      this.loading = true
      this.selectedFromDepartment = ''
      this.selectedFromCourseYear = ''
      this.fromCourseYears = []
      this.selectedFromDivision = ''
      this.fromDivisions = []
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      // const semObject = this.semestersObjectsList[index]
      const semObject = this.semestersObjectsList.find(element => element.semName === this.selectedFromSemesterName)
      const obj = await this.getAcademicTreeForSemester(semObject.semId)
      this.academicDataTree1 = obj.academicDataTree
      this.fromDepartments = obj.departments
      this.loading = false
    },
    async getCourseYears1 () {
      this.loading = true
      this.selectedFromCourseYear = ''
      this.selectedFromDivision = ''
      this.fromDivisions = []
      this.fromCourseYears = Object.keys(this.academicDataTree1[this.selectedFromDepartment])
      this.loading = false
    },
    async getDivisions1 () {
      this.loading = true
      this.selectedFromDivision = ''
      this.fromDivisions = this.academicDataTree1[this.selectedFromDepartment][this.selectedFromCourseYear]
      console.log(this.fromDivisions)
      this.loading = false
    },
    async getCourseYears2 () {
      this.loading = true
      this.selectedToCourseYear = ''
      this.selectedToDivision = ''
      this.toDivisions = []
      this.toCourseYears = Object.keys(this.academicDataTree2[this.selectedToDepartment])
      this.loading = false
    },
    async getDivisions2 () {
      this.loading = true
      this.selectedToDivision = ''
      this.toDivisions = this.academicDataTree2[this.selectedToDepartment][this.selectedToCourseYear]
      console.log(this.toDivisions)
      this.loading = false
    },
    async fetchFaculties () {
      this.loading = true
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      const semObject = this.semestersObjectsList.find(element => element.semName === this.selectedFromSemesterName)
      // const semObject = this.semestersObjectsList[index]
      const facultyIds = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: semObject.semId,
        department: this.selectedFromDepartment
      })
      console.log(facultyIds)
      const promises = facultyIds.map((userObject) => {
        return this.getDetailsOfUId(userObject.uId)
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.fromUsers = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          console.log(allSettledPromises[i].value)
          this.fromUsers.push(allSettledPromises[i].value)
        }
      }
      this.loading = false
    },
    async fetchStudents () {
      this.loading = true
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      const semObject = this.semestersObjectsList.find(element => element.semName === this.selectedFromSemesterName)
      console.log(this.selectedFromSemesterName, semObject, this.semestersNamesList, this.semestersObjectsList)
      const studentIds = await this.semesterUserRepositoryInstance.getStudentsOfADivision({
        instituteId: this.selectedInstitute,
        semId: semObject.semId,
        department: this.selectedFromDepartment,
        courseYear: this.selectedFromCourseYear,
        division: this.selectedFromDivision
      })
      console.log(studentIds)
      const promises = studentIds.map((userObject) => {
        return this.getDetailsOfUId(userObject.uId)
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.fromUsers = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          console.log(allSettledPromises[i].value)
          this.fromUsers.push(allSettledPromises[i].value)
        }
      }
      this.loading = false
    },
    async getDetailsOfUId (uId) {
      const nameObj = await this.getFullNameOfUser({ uId: uId })
      const instituteProvidedIdObj = await this.getInstituteProvidedIdOfUser({ uId: uId })
      return {
        uId: uId,
        name: nameObj.name,
        instituteProvidedId: instituteProvidedIdObj.instituteProvidedId
      }
    },
    async getFullNameOfUser (userObject) {
      try {
        if (!userObject.name) {
          userObject.name = await this.userRepositoryInstance.getFullNameOfUser({ uId: userObject.uId })
        }
      } catch (err) {
        console.log(err)
        userObject.name = ''
      }
      return userObject
    },
    async getInstituteProvidedIdOfUser (userObject) {
      try {
        if (!userObject.instituteProvidedId) {
          userObject.instituteProvidedId = await this.instituteUserRepositoryInstance.getInstituteProvidedId({
            instituteId: this.selectedInstitute,
            uId: userObject.uId
          })
        }
      } catch (err) {
        console.log(err)
        userObject.instituteProvidedId = '--'
      }
      return userObject
    },
    async getAcademicTreeForSemester (semId) {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: semId
        }
        const semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        const academicDataTree = {}
        semestersList.forEach((sem) => {
          if (!academicDataTree[sem.department]) {
            academicDataTree[sem.department] = {}
          }
          if (!academicDataTree[sem.department][sem.courseYear]) {
            academicDataTree[sem.department][sem.courseYear] = []
          }
          academicDataTree[sem.department][sem.courseYear].push(sem.division)
        })
        console.log(academicDataTree)
        const departments = Object.keys(academicDataTree)
        return {
          academicDataTree: academicDataTree,
          departments: departments
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    removeUser (item) {
      this.toUsers = arrayOps.removeFromArray(this.toUsers, this.toUsers.indexOf(item))
    },
    async transferStudents (transferRollNoAndExamNo) {
      this.askForTransfer = false
      if (transferRollNoAndExamNo) {
        // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
        // const semObject = this.semestersObjectsList[index]
        const semObject = this.semestersObjectsList.find(element => element.semName === this.selectedFromSemesterName)
        // console.log(this.semestersObjectsList, semObject, this.selectedFromSemesterName)
        this.studentsToTransfer.previousSemId = semObject.semId
        this.studentsToTransfer.maintainRollNumbers = transferRollNoAndExamNo
      }
      console.log(this.studentsToTransfer)
      try {
        await this.semesterUserRepositoryInstance.importStudentsOfADivision(this.studentsToTransfer)
        this.transferring = false
        showStatus('Transfer successful', 1000, 'success', this)
        this.loading = true
        this.completeStepOne()
      } catch (err) {
        console.log(err)
      }
    },
    async transferUsersIntoSemester () {
      this.transferring = true
      if (this.selectedMode !== 'New Users') {
        if (this.selectedUserType === 'Students') {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uIdsArray: this.toUsers.map((user) => user.uId),
            department: this.selectedToDepartment,
            courseYear: this.selectedToCourseYear,
            division: this.selectedToDivision
          }
          this.studentsToTransfer = objToPush
          this.askForTransfer = true
          // try {
          //   await this.semesterUserRepositoryInstance.importStudentsOfADivision(objToPush)
          // } catch (err) {
          //   console.log(err)
          // }
        } else {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uIdsArray: this.toUsers.map((user) => user.uId),
            department: this.selectedToDepartment
          }
          try {
            await this.semesterUserRepositoryInstance.importFaculties(objToPush)
            this.transferring = false
            showStatus('Transfer successful', 1000, 'success', this)
            this.loading = true
            this.completeStepOne()
          } catch (err) {
            console.log(err)
          }
        }
      } else {
        for (let i = 0; i < this.toUsers.length; i++) {
          try {
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.toUsers[i].uId
            }
            objToPush.department = this.selectedToDepartment
            if (this.selectedUserType === 'Students') {
              objToPush.courseYear = this.selectedToCourseYear
              objToPush.division = this.selectedToDivision
              objToPush.subjects = []
            }
            await this.semesterUserRepositoryInstance.createSemesterUser(objToPush)
          } catch (err) {
            console.log(err)
          }
        }
        this.transferring = false
        showStatus('Transfer successful', 1000, 'success', this)
        this.loading = true
        this.completeStepOne()
      }
    },
    pushAllUsers () {
      for (const user of this.fromUsers) {
        if (!this.toUsers.find((elem) => elem.uId === user.uId)) {
          this.toUsers.push(user)
        }
      }
    },
    removeAllUsers () {
      this.toUsers = []
    }
  }
}
</script>

<style src='./activityTransferUsers.css'>
</style>
