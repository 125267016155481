<template src='./activityEditFacultyAllocation.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import { subjectTypes } from '../../Constants/Utils/Statics'
import arrayOps from '../../Services/Utils/arrayOps'
import UserRepository from '../../Repository/User'
import SubjectRepository from '../../Repository/Subject'
import SemesterUserRepository from '../../Repository/SemesterUser'
export default {
  name: 'activityEditFacultyAllocation',
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer,
    smallButton
  },
  data () {
    return {
      loading: false,
      selectedDepartment: '',
      selectedCourseYear: '',
      // selectedDivision: '',
      departments: [],
      courseYears: [],
      // divisions: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      faculties: [],
      facultyAllocation: [],
      search: '',
      headers2: [
        {
          text: 'Subject',
          value: 'groupName'
        },
        {
          text: 'Variant Name',
          value: 'subjectName'
        },
        {
          text: 'Subject Type',
          value: 'subjectType'
        },
        {
          text: 'Faculties Assigned',
          value: 'faculties'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      subjectTypes: subjectTypes,
      tempSubjectIndex: -1,
      showFacultyListDialog: false
    }
  },
  created () {
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']

    this.userRepositoryInstance = new UserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)

    if (this.tree) {
      this.departments = this.tree.map((child) => child.name)
    } else {
      this.departments = []
    }

    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.selectedDepartment = this.prop_selectedDepartment
      this.selectDepartment()
      this.selectedCourseYear = this.prop_selectedCourseYear
      // this.selectedDivision = this.prop_selectedDivision
      // this.selectDivision()
      this.selectCourseYear()
    }
    this.fetchFaculties()
  },
  methods: {
    selectDepartment () {
      this.courseYears = []
      // this.divisions = []
      this.selectedCourseYear = ''
      // this.selectedDivision = ''
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      this.courseYears = this.tree[indexOfDepartment].children.map((child) => child.name)
    },
    async selectCourseYear () {
      // this.divisions = []
      // this.selectedDivision = ''
      // const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      // const indexOfCourseYear = this.courseYears.indexOf(this.selectedCourseYear)
      // this.divisions = this.tree[indexOfDepartment].children[indexOfCourseYear].children.map((child) => child.name)

      this.facultyAllocation = []
      // this.showFacultyListDialog = true

      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear
        // division: this.selectedDivision
      }

      console.log(objToPush)

      try {
        this.facultyAllocation = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)

        console.log(this.facultyAllocation)
      } catch (err) {
        console.log(err)
      }
    },
    // async selectDivision () {
    //   this.facultyAllocation = []
    //   // this.showFacultyListDialog = true

    //   const objToPush = {
    //     instituteId: this.selectedInstitute,
    //     semId: this.selectedSemester.semId,
    //     department: this.selectedDepartment,
    //     courseYear: this.selectedCourseYear,
    //     division: this.selectedDivision
    //   }

    //   console.log(objToPush)

    //   try {
    //     this.facultyAllocation = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)

    //     console.log(this.facultyAllocation)
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    async removeFaculty (facultyId, subject) {
      console.log(facultyId, subject)
      const indexOfFaculty = subject.faculties.indexOf(facultyId)
      if (indexOfFaculty > -1) {
        subject.faculties = arrayOps.removeFromArray(subject.faculties, indexOfFaculty)

        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: subject.subjectId,
            subjectCode: subject.subjectCode,
            subjectType: subject.subjectType,
            faculties: subject.faculties
          }
          console.log(objToPush)
          await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(objToPush)
        } catch (err) {
          console.log(err)
          subject.faculties.push(facultyId)
          subject.faculties = [...new Set(subject.faculties)]
        }
      }
    },

    startAddingFaculties (item) {
      this.tempSubjectIndex = this.facultyAllocation.indexOf(item)
      console.log(this.tempSubjectIndex)
      this.showFacultyListDialog = true
    },

    async addSubjectTeacher (item) {
      console.log(item)
      if (this.facultyAllocation[this.tempSubjectIndex].faculties.indexOf(item.uId) === -1) {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.facultyAllocation[this.tempSubjectIndex].subjectId,
            subjectCode: this.facultyAllocation[this.tempSubjectIndex].subjectCode,
            subjectType: this.facultyAllocation[this.tempSubjectIndex].subjectType,
            faculties: this.facultyAllocation[this.tempSubjectIndex].faculties.concat(item.uId)
          }

          await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(objToPush)

          this.facultyAllocation[this.tempSubjectIndex].faculties.push(item.uId)
        } catch (err) {
          console.log(err)
        }
      }
    },

    getNameOfFaculty (facultyId) {
      const indexOfFaculty = this.faculties.findIndex((faculty) => faculty.uId === facultyId)
      if (indexOfFaculty > -1) {
        return this.faculties[indexOfFaculty].name
      } else {
        console.log('No name found for ', facultyId)
        return ''
      }
    },

    async fetchFaculties () {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }

      console.log(objToPush)
      this.faculties = []

      const facultyObjects = await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(objToPush) || []

      for (let i = 0; i < facultyObjects.length; i++) {
        const nameOfFaculty = await this.userRepositoryInstance.getFullNameOfUser({ uId: facultyObjects[i].uId })

        if (nameOfFaculty) {
          this.faculties.push(
            {
              uId: facultyObjects[i].uId,
              name: nameOfFaculty
            }
          )
        }
      }

      console.log(this.faculties)
      this.loading = false
    }
  }
}
</script>
<style src='./activityEditFacultyAllocation.css' scoped>
</style>
