import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/InfrastructureReference'

class InfrastructureReference {
  constructor (context) {
    this.context = context
  }

  async createRoom (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createRoom, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.room
  }

  async createBuilding (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createBuilding, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.building
  }

  async getBuildings (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getBuildings, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.buildings
  }

  async getRooms (data) {

    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRooms, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rooms
  }

  async getRoomName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRoomName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.roomName
  }

  async getRoomsOfADepartment (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRoomsOfADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rooms
  }

  async updateRoomName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateRoomName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.roomName
  }

  async updateRoomDepartment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateRoomDepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.department
  }

  async updateSharingDepartments (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSharingDepartments, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.sharingDepartments
  }

  async updateType (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateType, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.type
  }

  async deleteRoom (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteRoom, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteBuilding (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteBuilding, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default InfrastructureReference
