<template src="./activityInstitutesBasicDetails.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import {
  instituteTypes,
  ownershipStatuses
} from '../../Constants/Utils/Statics'
import InstituteRepository from '../../Repository/Institute'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'

import auth from '../../Services/Firebase/auth'
import NetworkManager from '../../NetworkManager'
import InstituteAPIs from '../../ApiNames/Institute'

var firestorage = null

export default {
  name: 'activityInstitutesBasicDetails',
  props: ['creating'],
  components: {
    inputContainer
  },
  data () {
    return {
      loading: false,
      userData: {},
      instituteName: '',
      instituteAddress: '',
      universityName: '',
      universityAddress: '',
      phoneNumbers: [],
      emails: [],
      establishmentYear: '',
      instituteType: '',
      website: '',
      ownershipStatus: '',
      instituteTypes: instituteTypes,
      ownershipStatuses: ownershipStatuses,
      selectedInstitute: '',
      selectedSemester: '',
      addingPhoneNumber: false,
      addingEmail: false,
      tempEmail: '',
      tempPhoneNumber: '',
      adminLogo: [],
      facultyLogo: [],
      studentLogo: [],
      adminLogoName: '',
      studentLogoName:'',
      facultyLogoName:'',
      uploadTask: ''
    }
  },

  created () {
    // firebase stuff
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()

    this.loading = true
    console.log(this.creating)
    this.instituteRepositoryInstance = new InstituteRepository(this)

    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Institute Basic Details'
    )
    this.userData = this.$store.getters['user/get_userData']
    this.loading = false

    if (this.creating) {
    } else {
      this.selectedInstitute = this.$store.getters[
        'instituteData/get_selectedInstitute'
      ]
      console.log(this.selectedInstitute)

      this.fetchData()
      this.fetchBrandingDetails(this.selectedInstitute)
    }
  },

  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        'state_changed',
        sp => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log({ downloadURL })
            this.updateAdminBrandingLogo(this.selectedInstitute, downloadURL)
          })
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log({ downloadURL })
            this.updateFacultyBrandingLogo(this.selectedInstitute, downloadURL)
          })
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log({ downloadURL })
            this.updateStudentBrandingLogo(this.selectedInstitute, downloadURL)
          })
        }
      )
    }
  },

  methods: {
    async onAdminLogoSelect (file) {
      await this.uploadAdminLogo(file)
    },

    async onFacultyLogoSelect (file) {
      await this.uploadFacultyLogo(file)
    },
    async onStudentLogoSelect (file) {
      await this.uploadStudentLogo(file)
    },

    async fetchBrandingDetails (instituteId) {
      try {
        const res = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId }
        )
        this.adminLogoName = res.instituteBrandingName
      } catch (error) {
        console.log({ error })
      }
    },

    async uploadAdminLogo (file) {
      if(file !== undefined && file !== null && file !==''){
        const size = parseFloat(file.size / 1024)
        if (size > 300) {
          console.log('File size should be less than 300KB')
          return
        }
        this.uploadTask = firestorage.ref('logo/' + file.name).put(file)
        this.adminLogo = file
      }else{
        this.adminLogo = "";
      }
    },

    async uploadFacultyLogo (file) {
      if(file !== undefined && file !== null && file !=='') {
        const size = parseFloat(file.size / 1024)
        if (size > 300) {
          console.log('File size should be less than 300KB')
          return
        }
        this.uploadTask = firestorage.ref('logo/' + file.name).put(file)
        this.facultyLogo = file
      }else{
        this.facultyLogo = "";
      }
    },

    async uploadStudentLogo (file) {
      if(file !== undefined && file !== null && file !=='') {
        const size = parseFloat(file.size / 1024)
        if (size > 300) {
          console.log('File size should be less than 300KB')
          return
        }
        this.uploadTask = firestorage.ref('logo/' + file.name).put(file)
        this.studentLogo = file
      }else{
        this.studentLogo = "";
      }
    },

    async updateAdminBrandingName () {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteAdminBrandingName,
          {
            instituteId: this.selectedInstitute,
            instituteBrandingName: this.adminLogoName
          }
        )
        console.log('>> branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateFacultyBrandingName () {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteFacultyBrandingName,
          {
            instituteId: this.selectedInstitute,
            instituteFacultyBrandingName: this.facultyLogoName
          }
        )
        console.log('>> branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateStudentBrandingName () {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteStudentBrandingName,
          {
            instituteId: this.selectedInstitute,
            instituteStudentBrandingName: this.studentLogoName
          }
        )
        console.log('>> branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateAdminBrandingLogo (instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteAdminLogo,
          {
            instituteId: instituteId,
            instituteLogoLink: url
          }
        )
        console.log('>> branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateFacultyBrandingLogo (instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteFacultyLogo,
          {
            instituteId: instituteId,
            instituteFacultyLogoLink: url
          }
        )
        console.log('>> branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateStudentBrandingLogo (instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteStudentLogo,
          {
            instituteId: instituteId,
            instituteStudentLogoLink: url
          }
        )
        console.log('>> branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async fetchData () {
      this.loading = true
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.selectedInstitute
      }
      try {
        this.institute = await this.instituteRepositoryInstance.getInstituteInformation(
          objToPush
        )
        this.instituteName = this.institute.instituteName
        this.instituteAddress = this.institute.instituteAddress
        this.universityName = this.institute.affiliatingUniversityName
        this.universityAddress = this.institute.affiliatingUniversityAddress
        this.phoneNumbers = this.institute.institutePhoneNumber.slice()
        this.emails = this.institute.instituteEmail.slice()
        this.establishmentYear = this.institute.establishmentYear
        this.instituteType = this.institute.institutionType
        this.ownershipStatus = this.institute.ownershipStatus
        this.adminLogo = this.institute.instituteLogoLink
        this.adminLogoName = this.institute.instituteBrandingName
        this.facultyLogo = this.institute.instituteFacultyLogoLink
        this.facultyLogoName = this.institute.instituteFacultyBrandingName
        this.studentLogo = this.institute.instituteStudentLogoLink
        this.studentLogoName = this.institute.instituteStudentBrandingName
        console.log(this.institute)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },

    async updateDetails () {
      this.loading = true
      if ((this.instituteName !== this.institute.instituteName) && this.instituteName !== undefined && this.instituteName !== null && this.instituteName !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteName: this.instituteName
        }
        try {
          this.instituteName = await this.instituteRepositoryInstance.updateInstituteName(
            objToPush
          )
          this.institute.instituteName = this.instituteName
        } catch (err) {
          // this.instituteName = this.institute.instituteName
          showStatus('Could not update Institute Name.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.instituteAddress !== this.institute.instituteAddress) && this.instituteAddress !== undefined && this.instituteAddress !== null && this.instituteAddress !== "" ) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteAddress: this.instituteAddress
        }
        try {
          this.instituteAddress = await this.instituteRepositoryInstance.updateInstituteAddress(
            objToPush
          )
          this.institute.instituteAddress = this.instituteAddress
        } catch (err) {
          // this.instituteAddress = this.institute.instituteAddress
          showStatus(
            'Could not update Institute Address.',
            1000,
            'error',
            this
          )
          console.log(err)
        }
      }
      if ((this.universityName !== this.institute.affiliatingUniversityName) && this.universityName !== undefined && this.universityName !== null && this.universityName !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          universityName: this.universityName
        }
        try {
          this.universityName = await this.instituteRepositoryInstance.updateAffliatingUniversityName(
            objToPush
          )
          this.institute.affiliatingUniversityName = this.universityName
        } catch (err) {
          // this.universityName = this.institute.affiliatingUniversityName
          showStatus('Could not update University Name.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.universityAddress !== this.institute.affiliatingUniversityAddress) && this.universityAddress !== undefined && this.universityAddress !== null && this.universityAddress !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          universityAddress: this.universityAddress
        }
        try {
          this.universityAddress = await this.instituteRepositoryInstance.updateAffliatingUniversityAddress(
            objToPush
          )
          this.institute.affiliatingUniversityAddress = this.universityAddress
        } catch (err) {
          // this.universityAddress = this.institute.affiliatingUniversityAddress
          showStatus(
            'Could not update University Address.',
            1000,
            'error',
            this
          )
          console.log(err)
        }
      }
      if (
        !arrayOps.compareArrays(
          this.phoneNumbers,
          this.institute.institutePhoneNumber
        )
      ) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          institutePhoneNumber: this.phoneNumbers
        }
        try {
          this.phoneNumbers = await this.instituteRepositoryInstance.updateInstitutePhoneNumber(
            objToPush
          )
          this.institute.institutePhoneNumber = this.phoneNumbers
        } catch (err) {
          // this.phoneNumbers = this.institute.institutePhoneNumber
          showStatus('Could not update phone numbers.', 1000, 'error', this)
          console.log(err)
        }
      }
      console.log(this.emails, this.institute.instituteEmail)
      if (!arrayOps.compareArrays(this.emails, this.institute.instituteEmail)) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteEmail: this.emails
        }
        try {
          this.emails = await this.instituteRepositoryInstance.updateInstituteEmail(
            objToPush
          )
          this.institute.instituteEmail = this.emails
        } catch (err) {
          // this.emails = this.institute.instituteEmail
          showStatus('Could not update emails.', 1000, 'error', this)
          console.log(err)
        }
      }
      if (this.establishmentYear !== this.institute.establishmentYear && this.establishmentYear !== undefined && this.establishmentYear !== null && this.establishmentYear !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          establishmentYear: this.establishmentYear
        }
        try {
          this.establishmentYear = await this.instituteRepositoryInstance.updateEstablishmentYear(
            objToPush
          )
          this.institute.establishmentYear = this.establishmentYear
        } catch (err) {
          // this.establishmentYear = this.institute.establishmentYear
          showStatus(
            'Could not update establishment year.',
            1000,
            'error',
            this
          )
          console.log(err)
        }
      }
      if ( (this.instituteType !== this.institute.institutionType ) && this.instituteType !== undefined && this.instituteType !== null && this.instituteType !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteType: this.instituteType
        }
        try {
          this.instituteType = await this.instituteRepositoryInstance.updateInstituteType(
            objToPush
          )
          this.institute.institutionType = this.instituteType
        } catch (err) {
          // this.instituteType = this.institute.institutionType
          showStatus('Could not update institute type.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.ownershipStatus !== this.institute.ownershipStatus) && this.ownershipStatus !== undefined && this.ownershipStatus !== null && this.ownershipStatus !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          ownershipStatus: this.ownershipStatus
        }
        try {
          this.ownershipStatus = await this.instituteRepositoryInstance.updateOwnershipStatus(
            objToPush
          )
          this.institute.ownershipStatus = this.ownershipStatus
        } catch (err) {
          // this.ownershipStatus = this.institute.ownershipStatus
          showStatus('Could not update ownership status.', 1000, 'error', this)
          console.log(err)
        }
      }
      if (( this.website !== this.institute.instituteWebsite ) && this.website !== undefined && this.website !== null && this.website !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteWebsite: this.website
        }
        try {
          this.website = await this.instituteRepositoryInstance.updateInstituteWebsite(
            objToPush
          )
          this.institute.instituteWebsite = this.website
        } catch (err) {
          // this.website = this.institute.instituteWebsite
          showStatus('Could not update website.', 1000, 'error', this)
          console.log(err)
        }
      }

      if (this.adminLogoName.length > 0) {
        await this.updateAdminBrandingName()
      }
      if (this.facultyLogoName.length > 0) {
        await this.updateFacultyBrandingName()
      }
      if (this.studentLogoName.length > 0) {
        await this.updateStudentBrandingName()
      }
      if (this.adminLogo.length > 0) {
        await this.uploadAdminLogo()
      }
      if (this.facultyLogo.length > 0) {
        await this.uploadFacultyLogo()
      }
      if (this.studentLogo.length > 0) {
        await this.uploadStudentLogo()
      }
      showStatus('All Changes Updated Successfully.', 1000, 'success', this)
      this.loading = false
    },

    revertChanges () {
      this.website = this.institute.instituteWebsite
      this.ownershipStatus = this.institute.ownershipStatus
      this.instituteType = this.institute.institutionType
      this.establishmentYear = this.institute.establishmentYear
      this.emails = this.institute.instituteEmail
      this.phoneNumbers = this.institute.institutePhoneNumber
      this.universityAddress = this.institute.affiliatingUniversityAddress
      this.universityName = this.institute.affiliatingUniversityName
      this.instituteAddress = this.institute.instituteAddress
      this.instituteName = this.institute.instituteName
    },
    createInstitute () {},
    removeFromArray (item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },
    addEmail () {
      this.emails.push(this.tempEmail)
      this.tempEmail = ''
      this.addingEmail = false
    },
    addPhoneNumber () {
      this.phoneNumbers.push(this.tempPhoneNumber)
      this.tempPhoneNumber = ''
      this.addingPhoneNumber = false
    }
  }
}
</script>
<style scoped src="./activityInstitutesBasicDetails.css"></style>
