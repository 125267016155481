<template src="./activityExistingAdmissionForm.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SemesterRepository from '../../Repository/Semester'
import arrayOps from '../../Services/Utils/arrayOps'
import auth from "@/Services/Firebase/auth";
var firestorage = null
export default {
  name: 'activityExistingAdmissionForm',
  components: {
    inputContainer
  },
  props: ['prop_user'],
  data() {
    return {
      nameOfFather: '',
      profileImageUrl: '',
      fatherMobileNumber: '',
      occupationOfFather: '',
      nameOfMother: '',
      subjectWise: false,
      motherMobileNumber: '',
      occupationOfMother: '',
      correspondenceFlatNo: '',
      correspondenceLandmark: '',
      correspondenceCity: '',
      correspondenceState: '',
      correspondencePincode: '',
      permanentFlatNo: '',
      permanentLandmark: '',
      permanentCity: '',
      permanentState: '',
      permanentPincode: '',
      dob: '',
      gender: '',
      previousschoolName: '',
      previousclassName: '',
      previouspercentage: '',
      currentschoolName: '',
      currentclassName: '',
      discountPercentage: '',
      descriptionForPayment: '',
      razorpaySecrete: '',
      razorpayAccessToken: '',
      razorPayNumber: '',
      brandingLogo: '',
      brandingName: '',
      uploadTask: '',
      admissionUser: '',
      imageName: '',
      imageButton: false,
      showToStudents: false,
      installmentCheck: '',
      durationCheck: '',
      subjectsList: [],
      subjectsLists: [],
      semestersList: [],
      coursename: [],
      groupedsubjectsListArr: [],
      semestersLists: [],
      selectedCourseYear: '',
      selectedDivision: '',
      selectedClassName: '',
      classname: [],
      selectedSemester: '',
      selectedInstitute: '',
      updating: false,
      existingUser: {},
      tempPayments: [],
      paymentsOptions: [],
      userObject: {},
      selectedTab: 0,
      firstName: '',
      middleName: '',
      lastName: '',
      parentFullName: '',
      emailId: '',
      parentEmailId: '',
      mobileNumber: '',
      parentMobileNumber: '',
      address: '',
      guardianFullName: '',
      guardianEmailId: '',
      guardianMobileNumber: '',
      guardianAddress: '',
      schoolName: '',
      className: '',
      percentage: '',
      search: '',
      paymentOption: '',
      installmentOption: '',
      isForDiscount: false,
      paymentMethod: '',
      disabledPaymentMethod: false,
      installmentPaymentDetails: [],
      extraFormFields: [],
      subjects: [],
      subjectsBatchMappping: {},
      selectedBatches: [],
      isBasicBatchSelected: 'false',
      selectedSPBatch: {},
      groupedsubjectsList: {},
      finalSubjectList: [],
      instalmentDuration: 0,
      selectedSemesterByStore: '',
      admissionList: [],
      selectedAdmission: [],
      sourceEmailId: '',
      admissionFormActivity: []
    }
  },
  created() {
    this.userObject = this.prop_user
    console.log(' this.userObject', this.userObject.batches);
    this.disabledPaymentMethod = false;
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    if (this.prop_user) {
      this.selectedInstitute = this.prop_user.instituteId
      this.profileImageUrl = this.prop_user.profileImageUrl
      this.adharCardUrl = this.prop_user.adharCardUrl
      this.selectedSemester = this.prop_user.semId
      this.selectedDepartment = this.prop_user.department
      this.selectedCourseYear = this.prop_user.courseYear
      this.selectedDivision = this.prop_user.division
      this.firstName = this.prop_user.firstName
      this.middleName = this.prop_user.middleName
      this.lastName = this.prop_user.lastName
      this.emailId = this.prop_user.emailId
      this.gender = this.prop_user.gender
      this.sourceEmailId = this.prop_user.emailId
      this.mobileNumber = this.prop_user.mobileNumber
      this.extraFormFields = this.prop_user.extraFormFields
      console.log('this.prop_user.extraFormFields', this.prop_user.extraFormFields);
      this.nameOfFather = this.prop_user.nameOfFather
      this.fatherMobileNumber = this.prop_user.fatherMobileNumber
      this.occupationOfFather = this.prop_user.occupationOfFather
      this.nameOfMother = this.prop_user.nameOfMother
      this.motherMobileNumber = this.prop_user.motherMobileNumber
      this.occupationOfMother = this.prop_user.occupationOfMother

      this.correspondenceFlatNo = this.prop_user.correspondenceFlatNo
      this.correspondenceLandmark = this.prop_user.correspondenceLandmark
      this.correspondenceCity = this.prop_user.correspondenceCity
      this.correspondenceState = this.prop_user.correspondenceState
      this.correspondencePincode = this.prop_user.correspondencePincode
      this.permanentFlatNo = this.prop_user.permanentFlatNo
      this.permanentLandmark = this.prop_user.permanentLandmark
      this.permanentCity = this.prop_user.permanentCity
      this.permanentState = this.prop_user.permanentState
      this.permanentPincode = this.prop_user.permanentPincode

      this.dob = this.prop_user.dob
      this.gender = this.prop_user.gender
      this.previousschoolName = this.prop_user.previousschoolName
      this.previousclassName = this.prop_user.previousclassName
      this.previouspercentage = this.prop_user.previouspercentage
      this.currentschoolName = this.prop_user.currentschoolName
      this.currentclassName = this.prop_user.currentclassName
      this.guardianFullName = this.prop_user.guardianFullName
      this.guardianEmailId = this.prop_user.guardianEmailId
      this.guardianMobileNumber = this.prop_user.guardianMobileNumber
      this.guardianAddress = this.prop_user.guardianAddress
      this.updating = true
    }
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.subjectsList = this.$store.getters['liveData/get_admissionSubjects']
    this.selectedSemesterByStore = this.$store.getters[
      'liveData/get_admissionSemester'
    ]

    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.brandingName = this.$store.getters['liveData/get_brandingName']
    this.brandingLogo = this.$store.getters['liveData/get_brandingLogo']
    this.razorPayNumber = this.$store.getters['liveData/get_razorPayNumber']
    this.razorpayAccessToken = this.$store.getters['liveData/get_razorpayAccessToken']
    this.razorpaySecrete = this.$store.getters['liveData/get_razorpaySecrete']
    this.descriptionForPayment = this.$store.getters['liveData/get_descriptionForPayment']
    this.getSemesters();
    this.getadmissionUser()

  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        'state_changed',
        sp => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          console.log("HSHSHSHHS-->")
          this.imageName = this.imageName;
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("this.payInstallmentDetails.paymentImage-->", downloadURL)
            this.image = downloadURL;
          });

        }
      )
    }
  },
  methods: {
    download(file) {
      window.location.href = file;
    },
    async onFacultyLogoSelect(file) {
      await this.uploadFacultyLogo(file)
    },
    async onUploadExtraFieldFile(formField) {
      console.log('formField', formField);
      this.selectedformField = formField
      await this.uploadExtraFormField(formField.value[0])
    },
    async uploadExtraFormField(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        this.extraFormFile = file.name
        this.extraFormFileUploadTask = firestorage
          .ref('admissionImage/' + file.name)
          .put(file)
      } else {
        this.image = ''
      }
    },
    async getadmissionUser() {
      try {
        const res = await this.admissionUserRepositoryInstance.getAdmissionUser({
          emailId: this.prop_user.emailId
        });
        this.admissionUser = res.admissionUser
        if (!res.admissionUser.ispersonnelDiscountGiven) {
          this.isForDiscount = !res.admissionUser.ispersonnelDiscountGiven
          this.paymentOption = this.admissionUser.paymentOption
          this.installmentOption = this.admissionUser.payments.length
          this.handleInstallmentChange(this.admissionUser.installments)
        }
      }
      catch (e) {
        console.log('err', e);
      }
    },
    async checkPaymentMethod() {
      if (this.paymentOption == 'Online Payment') {
        this.disabledPaymentMethod = true;
      } else {
        this.disabledPaymentMethod = false;
      }
    },

    async uploadFacultyLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        if (size > 500) {
          this.imageButton = true;
          return
        }
        this.imageButton = false;
        this.imageName = file.name;
        this.uploadTask = firestorage.ref('admissionImage/' + file.name).put(file);
      } else {
        this.imageButton = false;
        this.image = "";
      }
    },

    gotoNewAdmissionUser() {
      this.$router.push({
        name: 'activityAdmissionNewUsers',
        params: {
          prop_user: this.prop_user
        }
      });
    },
    async fetchAdmissionDetailsSelect() {
      console.log('prop_user', this.prop_user);
      this.admissionList = [this.prop_user];
      let finalAdmissionList = [];
      console.log('admissionList', this.admissionList);
      this.admissionList.forEach(admission => {
        console.log('semestersList', this.semestersList);
        this.semestersList.forEach(sem => {
          if (admission.semId === sem.semId) {
            admission.semName = sem.semName
            admission.admissionName = admission.semName + '-' + admission.department + '-' + admission.courseName
            finalAdmissionList.push(admission);
          }
        });
      });
      this.admissionList = finalAdmissionList;
      this.selectedAdmission = this.prop_user;
      this.fetchAdmissionDetails();
    },

    async checkAdmissionExitsOrNot() {

      this.admissionList.forEach(admission => {
        if (admission.semd == this.selectedSemester.semId && admission.department == this.selectedClassName && admission.courseYear == this.selectedCourseName) {
          this.selectedAdmission = admission;
          this.selectedSemester.semId = admission.semId;
          this.selectedClassName = admission.department;
          this.selectedCourseName = admission.courseYear;
        } else {
          alert("There is No admission found in this semester Please change semesters");
          this.selectedSemester = '';
          this.selectedClassName = '';
          this.selectedCourseName = "";
        }
      });
    },

    async fetchAdmissionDetails() {
      this.selectedAdmission.semId = this.prop_user.semId
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataForEdit({
        instituteId: this.selectedAdmission.instituteId,
        semId: this.selectedAdmission.semId,
        admissionId: this.selectedAdmission.admissionId
      });
      this.selectedCourseYear = res.admissionData[0].department;
      this.selectedClassName = res.admissionData[0].courseName;
      this.isAllSubjectCheck = res.admissionData[0].subjectCompulsory;
      if (res.admissionPayment.length) {
        console.log('res.admissionPayment', res.admissionPayment);
        this.admissionPaymentId = res.admissionPayment[0].admissionPaymentId;
        console.log('this.admissionUser', this.admissionUser.installmentPattern[0]);
        this.installmentCheck = this.isForDiscount ? res.admissionPayment[0].instalmentsNumber : this.admissionUser.installmentPattern.length + 1; //if personnel discounted

        this.instalmentDuration = res.admissionPayment[0].instalmentDuration;
        this.handleInstallmentChangeNew(this.installmentCheck, this.instalmentDuration)
      }
      if (res.admissionFormActivity.length) {
        this.admissionFormActivity = res.admissionFormActivity[0].formActivity
        this.admissionFormActivity.map((form) => {
          this.extraFormFields.map((f) => {
            console.log('form.id, f.id', form.id, f.id);
            if (form.id === f.id && form.id > 34) {
              f.status = form.status
              f.isToShow = form.isToShow
              f.status = form.status
              console.log('form.id, f.id', form.id, f.id);
            }
          })
        })
      }
      if (res.subjectLists.length) {
        this.subjectsLists = this.userObject.batches;
        console.log('this.subjectsLists', this.subjectsLists);
        if (this.subjectsLists[0].feesCriteria === 'subjectWise' || !this.subjectsLists[0].feesCriteria) {
          this.subjectWise = true
        } else {
          this.subjectWise = false
          this.groupedsubjectsListArr = this.subjectsLists
        }
      }
    },

    async gotoNextStep() {
      this.selectedTab = 1
      this.gotoPayment()
    },

    async gotoPayment() {
      let totalAmount = 0
      this.userObject.batches.map(subjectPayemnt => {
        subjectPayemnt.date = new Date();
        totalAmount = totalAmount + parseInt(subjectPayemnt.totalFees)
      });
      this.userObject.initialPayment = totalAmount

      // this.userObject.initialPayment = this.userObject.totalPayment

      // if (this.updating) {
      //   const existingUser = JSON.parse(JSON.stringify(this.existingUser))
      //   existingUser.instituteId = this.userObject.instituteId
      //   existingUser.admissionId = this.userObject.admissionId
      //   existingUser.image = this.userObject.image,
      //   existingUser.semId = this.userObject.semId
      //   existingUser.department = this.userObject.department
      //   existingUser.courseYear = this.userObject.courseYear
      //   existingUser.division = this.userObject.division
      //   existingUser.emailId = this.userObject.emailId
      //   existingUser.fullName = this.userObject.fullName
      //   existingUser.firstName= this.userObject.firstName,
      //   existingUser.middleName = this.userObject.middleName,
      //   existingUser.lastName = this.userObject.lastName,
      //   existingUser.emailId = this.userObject.emailId,
      //   existingUser.mobileNumber = this.userObject.mobileNumber
      //   existingUser.parentFullName = this.userObject.parentFullName
      //   existingUser.parentEmailId = this.userObject.parentEmailId
      //   existingUser.parentMobileNumber = this.userObject.parentMobileNumber
      //   existingUser.address = this.userObject.address
      //   existingUser.guardianFullName = this.userObject.guardianFullName,
      //   existingUser.guardianEmailId = this.userObject.guardianEmailId,
      //   existingUser.guardianMobileNumber = this.userObject.guardianMobileNumber,
      //   existingUser.guardianAddress = this.userObject.guardianAddress,
      //   existingUser.schoolName = this.userObject.schoolName
      //   existingUser.className = this.userObject.className
      //   existingUser.percentage = this.userObject.percentage
      //   existingUser.batches = this.userObject.batches,
      //   //existingUser.batches = this.userObject.batches
      //   existingUser.totalPayment = this.userObject.totalPayment
      //   existingUser.sourceEmailId = this.userObject.sourceEmailId
      //   await this.admissionUserRepositoryInstance.updateAdmissionUser(
      //     existingUser
      //   )
      //   this.existingUser = existingUser

      // } else {
      //   const res = await this.admissionUserRepositoryInstance.createAdmissionUser(
      //     this.userObject
      //   )
      //   console.log(JSON.stringify(res))
      // this.existingUser = res.admissionUser
      // this.updating = true
    },

    dateConverter(fullDate) {
      let date = new Date(fullDate)
      const dateString = date.toISOString()
      return dateString
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/')
    },
    async finalConfirmation() {
      this.userObject.paymentOption = this.paymentOption
      this.userObject.installments = this.installmentOption.instalmentsNumber
      this.userObject.paymentMethod = this.paymentMethod
      this.userObject.payments = this.tempPayments
      this.userObject.isConfirmed = true
      this.userObject.ispersonnelDiscountGiven = false

      await this.admissionUserRepositoryInstance.updateAdmissionUser(
        this.userObject
      )
      this.existingUser = this.userObject
      this.backToAdmissionHome();
    },

    backToAdmissionHome() {
      this.$router.push({
        name: 'activityAdmissionNewUsers',
        params: {
          prop_user: this.prop_user
        }
      });
    },
    handleInstallmentChangeNew(installmentCheck, durationCheck) {
      const date = new Date();
      for (let i = 1; i <= installmentCheck; i++) {
        let instalmentPaymentOption = {};
        instalmentPaymentOption.instalmentsNumber = i;
        instalmentPaymentOption.installmentId = i;
        instalmentPaymentOption.paymentDate = '';
        instalmentPaymentOption.paymentMethod = '';
        instalmentPaymentOption.paymentImage = '';
        instalmentPaymentOption.paymentAmount = '';
        instalmentPaymentOption.paymentTransactionId = '';
        instalmentPaymentOption.instalmentsName = i == 1 ? 'One Time Payment' : i + '  ' + 'Installments';
        instalmentPaymentOption.date = date;
        instalmentPaymentOption.instalmentDuration = durationCheck;
        instalmentPaymentOption.isPaidStatus = 0;
        this.paymentsOptions.push(instalmentPaymentOption)
      }
    },

    handleInstallmentChange(instalmentsNumber) {
      this.tempPayments = []
      const date = new Date()

      if (!this.isForDiscount) {
        //installment Discounted
        let today = date.toLocaleDateString()

        console.log('instalmentsNumber.instalmentsNumber.instalmentsNumber', instalmentsNumber.instalmentsNumber);
        this.userObject.totalPayment = this.admissionUser.totalPayment
        let finalAmount
        let dueDate
        this.userObject.paymentDiscount = this.admissionUser.discountPercentage
        let discountedInstallmentPattern = this.admissionUser.installmentPattern[instalmentsNumber.instalmentsNumber - 2]
        for (let i = 1; i <= instalmentsNumber.instalmentsNumber; i++) {
          finalAmount = (instalmentsNumber.instalmentsNumber == 1) ? this.userObject.totalPayment : discountedInstallmentPattern[i - 1].installmentFeeAmount
          dueDate = (instalmentsNumber.instalmentsNumber == 1) ? today : discountedInstallmentPattern[i - 1].dueDate
          let instalmentPaymentOption = {}
          instalmentPaymentOption.instalmentsNumber = i
          instalmentPaymentOption.installmentId = i
          instalmentPaymentOption.paymentDate = ''
          instalmentPaymentOption.paymentMethod = ''
          instalmentPaymentOption.paymentImage = ''
          instalmentPaymentOption.paymentAmount = ''
          instalmentPaymentOption.paymentTransactionId = ''
          instalmentPaymentOption.instalmentsName = (instalmentsNumber.instalmentsNumber == 1) ? 'One Time Payment' : 'Installment' + i
          instalmentPaymentOption.dueDate = dueDate

          instalmentPaymentOption.isPaidStatus = 0
          instalmentPaymentOption.status = 'unpaid'
          instalmentPaymentOption.amount = finalAmount
          instalmentPaymentOption.amount = instalmentPaymentOption.amount.toFixed(2)
          this.tempPayments.push(instalmentPaymentOption)
        }
        //to pay installment 
      } else {
        this.tempPayments = this.admissionUser.payments
        for (let i = 0; i <= instalmentsNumber; i++) {
          let installmentsName = instalmentsNumber == 0 ? 'One Time Payment' : (i + 1 + '  ' + 'Installments')
          this.tempPayments[i].instalmentsName = installmentsName
          this.tempPayments[i].instalmentsNumber = i + 1
        }
      }

    },
    async payInstallment(amt, installmentNo) {
      if (amt <= 0) {
        amt = 1
      }
      const amount = (amt * 100).toString()
      let myObject = {}
      console.log('this.razorpayAccessToken', this.razorpayAccessToken);
      myObject.amount = amount
      myObject.razorpayAccessToken = this.razorpayAccessToken
      myObject.razorpaySecrete = this.razorpaySecrete
      const res = await new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'

        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }

        document.body.appendChild(script)
      })

      if (!res) {
        alert('Razorpay SDK Failed to load')
        return
      }

      const data = await this.admissionUserRepositoryInstance.getOrderId(
        myObject
      )
      const options = {
        key: this.razorpayAccessToken, // Enter the Key ID generated from the Dashboard
        name: this.instituteStudentBrandingName,
        currency: data.currency,
        amount: data.amount,
        order_id: data.id,
        description: this.descriptionForPayment,
        image: this.instituteStudentLogoLink,
        handler: async (response) => {
          // Validate payment at server - using webhooks is a better idea.
          this.tempPayments.forEach((installment) => {
            if (installment.instalmentsNumber === installmentNo) {
              installment.status = 'paid'
              installment.paymentTransactionId = response.razorpay_payment_id
              installment.isPaidStatus = 1
              installment.paymentAmount = amt
              installment.paymentMethod = "Online",
                installment.paymentDate = new Date()
            }
          })
          const objToPush = {
            admissionUserId: this.existingUser.admissionUserId,
            installmentId: installmentNo,
            payInstallmentDetails: {
              paymentDate: new Date(),
              paymentAmount: amt,
              paymentMethod: "Online",
              paymentImage: " ",
              paymentTransactionId: response.razorpay_payment_id
            }
          }
          const res = await this.admissionUserRepositoryInstance.submitPaymentInstallment(
            objToPush
          )
          console.log(res)
          this.status = true
          alert('Payment Successfully Completed')
        },
      }

      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      paymentObject.on('payment.failed', function (response) {
        console.log(response.error)
      })
    },

    async getCoursebySemesters() {
      this.selectedClassName = '';
      this.selectedCourseName = '';
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.coursename = await this.semesterRepositoryInstance.getCoursebySemesters(
          objToPush
        )
      }
      catch (err) {
        console.log(err)
      }
    },

    async changeFunction() {
      this.selectedClassName = '';
      this.selectedCourseName = '';
    },

    async getClassbySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.classname = await this.semesterRepositoryInstance.getCoursebySemesters(objToPush);
      } catch (err) {
        console.log(err)
      }
    },

    async changeFunction1() {
      this.selectedClassName = '';
    },

    async addTotalfee(item) {
      if (item !== undefined && item !== null) {
        var subject = this.subjectsList.find(x => x.subjectId == item.subjectId);
        if (subject) {
          subject.totalFees = item.totalFees;
          this.subjectsList = this.subjectsList;
        }
      }
    },

    async addBasicfee(item) {
      if (item !== undefined && item !== null) {
        var subject = this.subjectsList.find(x => x.subjectId == item.subjectId);
        if (subject) {
          subject.basicFees = item.basicFees;
          this.subjectsList = this.subjectsList;
        }
      }
    },

    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse();
        this.semestersList = this.semesters;
        this.fetchAdmissionDetailsSelect();
      } catch (err) {
        console.log(err)
      }
    },
  }
}
</script>
<style scoped src="./activityExistingAdmissionForm.css">

</style>
