import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Topic'

class SubjectsRepository {
  constructor (context) {
    this.context = context
  }

  async createTopic (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTopic, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topic
  }

  async getTopicsOfADivisionOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfADivisionOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async markAsImplemented (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.markAsImplemented, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.isImplemented
  }

  async markAsUnimplemented (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.markAsUnimplemented, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.isImplemented
  }

  async deleteTopic (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteTopic, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default SubjectsRepository
