<template>
  <v-card elevation="0">
    <v-card-title>
      <!-- <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field> -->
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      show-select
      item-key="uId"
      :single-select="false"
      v-model="selectedUsers"
      height="300px"
      :items-per-page=-1
    >
      <template v-slot:item.uId="{ item }">
        {{userMappings[item.uId]}}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// import axios from "axios";
// import { apiUrl } from "../../Services/Config/apiConfig";
import UserRepository from '../../../Repository/User'
export default {
  props: ['users'],
  data () {
    return {
      selectedUsers: [],
      search: '',
      headers: [
        {
          text: 'Roll Number',
          align: 'left',
          sortable: true,
          value: 'rollNumber',
          filterable: true
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'uId',
          filterable: true
        }
      ],
      userMappings: {}
    }
  },
  mounted () {
    this.userRepositoryInstance = new UserRepository(this)
    this.getNames()
  },
  methods: {
    async getNames () {
      this.userMappings = {}
      for (let i = 0; i < this.users.length; i++) {
        const nameOfFaculty = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.users[i].uId })

        if (nameOfFaculty) {
          this.userMappings[this.users[i].uId] = nameOfFaculty
        }
      }
    },
    getData () {
      return this.selectedUsers
    }
  }
}
</script>

<style scoped>
</style>
