<template src="./activitySetup.html"> </template>
<script>
import auth from '../../Services/Firebase/auth'
import UserRepository from '../../Repository/User'
import showStatus from '../../NetworkManager/showStatus'
import { ROLES } from '../../Constants/index'
export default {
  name: 'activitySetup',
  data () {
    return {
      user: null,
      admissionMails: [
        'kaustubhatre18@gmail.com',
        'ambitions.kba@gmail.com',
        'inprospecttechnologiesofficial@gmail.com',
        'tech.inprospect@gmail.com',
        'virajlagad@gmail.com',
        'maardav.oturckar@gmail.com',
        'pravinjagtap2151@gmail.com',
        'pravinjagtap791@gmail.com'
      ]
    }
  },
  created () {
    this.user = this.$store.getters['user/get_user']
    this.userRepositoryInstance = new UserRepository(this)
    const authToken = this.$store.getters['user/get_authToken']

    if (this.user) {
      const objToPush = {
        registrationToken: '',
        authToken: authToken,
        uuId: this.user.uid,
        email: this.user.email,
        phone: this.user.phoneNumber ? this.user.phoneNumber.substring(3) : null,
        role: ROLES.ADMIN
      }
      this.$store.commit('liveData/set_showAdmissionModule', this.admissionMails.includes(this.user.email))
      this.userRepositoryInstance.loginAdmissionUser(objToPush).then(res => {
        showStatus('Login Successful!', 1000, 'success', this)
        console.log(res)
        this.$router.push('/activitySelectInstituteNSemester')
      }).catch(err => {
        if (err.response.status === 401) {
          alert("You don't have access to this portal")
        }else if(err.response.status == 404){
          console.log("USER--->",JSON.stringify(this.user))
           const obj={
               firstName: this.user.displayName !== undefined && this.user.displayName !== undefined !== null && this.user.displayName !== ""? this.user.displayName.split(' ').slice(0, -1).join(' '):"Admission",
               lastName: this.user.displayName !== undefined && this.user.displayName !== undefined !== null && this.user.displayName !== ""? this.user.displayName.split(' ').slice(-1).join(' '):"User",
               email: this.user.email,
               authToken:authToken,
               uuId: this.user.uid
           }
           if(this.user.phoneNumber !== undefined && this.user.phoneNumber !== null && this.user.phoneNumber !== "")obj.mobile=this.user.phoneNumber
           console.log(JSON.stringify(obj),JSON.stringify(objToPush))
           this.userRepositoryInstance.createAdmissionUser(obj).then(res=>{
            this.$router.push('/activitySelectInstituteNSemester')
           }).catch(err=>{
             auth.logout(this)
           })
        }else{
          auth.logout(this)
        }
      })
    } else {
      this.$router.push('/activityWelcome')
    }
  }
}
</script>
<style scoped src="./activitySetup.css"></style>
