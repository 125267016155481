import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/NBA'

class NBARepository {
  constructor (context) {
    this.context = context
  }

  async getNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async getDepartmentNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDepartmentNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async updateNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async updateDepartmentNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateDepartmentNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }
}

export default NBARepository
