<template src="./activityAdmission.html"> </template>
<script>
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SubjectsRepository from '../../Repository/Subject'
import {convertDate, convertToISTTime} from "../../Services/Utils/DateTimeFormatter";
export default {
  name: 'activityAdmission',
  components: {
    inputContainer
  },
  data () {
    return {
      admissionList: [],
    }
  },
  created () {
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['liveData/get_admissionInstitute']
    this.fetchAdmissionDetails();
  },
  methods: {

    gotoAdmissionNewUsers (admission) {
        this.$router.push({
        name: 'activityAdmissionNewUsers',
        params: {
          prop_admissionDataStudent:admission
        }
      })
    },

    async createAdmission (admission) {
      this.$router.push({
        name: 'activityAdmissionForm',
        params: {
          prop_admissionData:admission
        }
      })
    },

    // fetch Admission Table
    async fetchAdmissionDetails () {
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataForStudent({
        status: true,
        instituteId: this.selectedInstitute,
      });
      this.admissionList = res;
    },


    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },

    convertToIstTime(dateString) {
      return convertToISTTime(dateString);
    },

  }
}
</script>
<style src="./activityAdmission.css" scoped></style>
