import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/SemesterUser'

class SemesterUser {
  constructor (context) {
    this.context = context
  }

  async getCurrentSemesterUserForAUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCurrentSemesterUserForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getUsersNotInAnySemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getUsersNotInAnySemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.uIds
  }

  async createSemesterUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getFacultiesOfADepartment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesOfADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getFacultiesOfAInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfADivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfACourseYear (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfADepartment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfAInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async updateDepartment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateDepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.department
  }

  async updateCourseYear (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateCourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.courseYear
  }

  async updateDivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.division
  }

  async updateBatch (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateBatch, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.batch
  }

  async updateRollNumber (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateRollNumber, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rollNumber
  }

  async updateExamSeatNumber (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateExamSeatNumber, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examSeatNumber
  }

  async updateSubjectsOfAStudentInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjectsOfAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async importFaculties (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.importFaculties, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async importStudentsOfADivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.importStudentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async deleteSemesterUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default SemesterUser
