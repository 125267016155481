<template src='./activityEditSemesterBatches.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityEditSemesterBatches',
  data () {
    return {
      loading: false,
      selectedSemester: '',
      items: [],
      selection: [],
      selectionType: 'leaf',
      tempBatchCount: 1,
      tableItems: [],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Batch Count',
          value: 'batches'
        }
      ]
    }
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer
  },
  created () {
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.items = this.tree

    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.items
        .filter((item) => item.name === this.prop_selectedDepartment)
        .forEach((department) => {
          department.children
            .filter((child) => child.name === this.prop_selectedCourseYear)
            .forEach((courseYear) => {
              courseYear.children
                .filter((child) => child.name === this.prop_selectedDivision)
                .forEach((division) => {
                  this.selection.push(division)
                })
            })
        })
    }
    this.loading = false
    this.fetchDetails()
  },
  methods: {
    async fetchDetails () {
      this.loading = true
      console.log(this.selection)
      this.tableItems = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        const item = {
          department: this.selection[i].department,
          courseYear: this.selection[i].courseYear,
          division: this.selection[i].name,
          batches: ''
        }

        this.tableItems.push(item)

        objToPush.department = item.department
        objToPush.courseYear = item.courseYear
        objToPush.division = item.division

        try {
          item.batches = await this.semesterRepositoryInstance.getNumberOfBatchesOfADivisionInASemester(objToPush)
          console.log(item.batches)
          item.batches = item.batches.toString()
        } catch (err) {
          console.log(err)
        }
      }
      this.loading = false
      // console.log(this.tableItems)
    },

    async setNumberOfBatches () {
      this.loading = true
      console.log(this.selection)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        objToPush.department = this.selection[i].department
        objToPush.courseYear = this.selection[i].courseYear
        objToPush.division = this.selection[i].name
        objToPush.numberOfBatches = parseInt(this.tempBatchCount)

        try {
          const numberOfBatches = await this.semesterRepositoryInstance.updateNumberOfBatchesOfADivisionOfASemester(objToPush)
          showStatus('Batch count updated successfully!', 500, 'success', this)
          const indexOfTableItem = this.tableItems.findIndex((item) => {
            return item.department === this.selection[i].department &&
            item.courseYear === this.selection[i].courseYear &&
            item.division === this.selection[i].name
          })

          if (indexOfTableItem > -1) {
            this.tableItems[indexOfTableItem].batches = numberOfBatches
          }
        } catch (err) {
          showStatus('Cannot connect to server!', 1500, 'error', this)
          console.log(err)
        }
      }
      this.loading = false
      // console.log(this.tableItems)
    }
  },
  destroyed () {
    console.log('Destroyed')
  }
}
</script>
<style src='./activityEditSemesterBatches.css' scoped>
</style>
