<template src='./activityManageTopicFeedbackTemplates.html'>

</template>

<script>
import activityTopicFeedbackTemplate from '../activityTopicFeedbackTemplate/activityTopicFeedbackTemplate'
import activityTopicFeedbackActionTemplate from '../activityTopicFeedbackActionTemplate/activityTopicFeedbackActionTemplate'

export default {
  name: 'activityManageTopicFeedbackTemplates',
  components: {
    activityTopicFeedbackTemplate,
    activityTopicFeedbackActionTemplate
  },
  watch: {
    tab: function () {
      console.log(this.tab)
    }
  },
  data () {
    return {
      tab: 0,
      items: ['Feedback Template', 'Feedback Action Template']
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'Manage Topic Feedback Templates')
    console.log(this.tab)
  },
  methods: {
  }
}
</script>

<style src='./activityManageTopicFeedbackTemplates.css'>

</style>
