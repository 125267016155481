<template src="./activityReferenceAcademicDetails.html"> </template>
<script>
import arrayOps from '../../Services/Utils/arrayOps'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'

export default {
  name: 'activityReferenceAcademicDetails',
  components: {
    inputContainer
  },
  data () {
    return {
      loading: false,
      userData: {},
      selectedInstitute: '',
      departments: [],
      selectedDepartment: '',
      tempDepartment: '',
      showAddDepartmentDialog: false,
      showRemoveDepartmentDialog: false,
      courseYears: [],
      selectedCourseYear: '',
      tempCourseYear: '',
      showAddCourseYearDialog: false,
      showRemoveCourseYearDialog: false,
      divisions: [],
      tempDivision: '',
      showAddDivisionDialog: false,
      showRemoveDivisionDialog: false,
      errorInDepartmentName: false,
      errorInCourseYearName: false,
      errorInDivisionName: false
    }
  },
  created () {
    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Reference Academic Details'
    )
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(
      this
    )
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      if (!this.selectedDepartment) {
        await this.fetchDepartments()
      } else if (this.selectedDepartment && !this.selectedCourseYear) {
        await this.fetchCourseYears()
      } else if (this.selectedDepartment && this.selectedCourseYear) {
        await this.fetchDivisions()
      }
    },
    async fetchDepartments () {
      this.loading = true
      this.departments = []
      this.courseYears = []
      this.divisions = []
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.departments = await this.academicReferenceRepositoryInstance.getDepartments(
          objToPush
        )
        console.log(this.departments)
      } catch (err) {
        this.departments = []
        console.log(err)
      }
      this.loading = false
    },
    async fetchCourseYears () {
      this.loading = true
      this.courseYears = []
      this.divisions = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        department: this.selectedDepartment
      }
      this.courseYears = []
      this.divisions = []
      try {
        this.courseYears = await this.academicReferenceRepositoryInstance.getCourseYears(
          objToPush
        )
        console.log(this.courseYears)
      } catch (err) {
        this.courseYears = []
        console.log(err)
      }
      this.loading = false
    },
    async fetchDivisions () {
      this.loading = true
      this.divisions = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear
      }
      try {
        this.divisions = await this.academicReferenceRepositoryInstance.getDivisions(
          objToPush
        )
        console.log(this.divisions)
        if (this.divisions) {
          this.divisions.sort()
        }
      } catch (err) {
        this.divisions = []
        console.log(err)
      }
      this.loading = false
    },
    startAddDepartment () {
      this.tempDepartment = ''
      this.showAddDepartmentDialog = true
    },
    async selectDepartment (department) {
      if (this.selectedDepartment !== department) {
        this.selectedDepartment = department
        this.selectedCourseYear = ''
        this.fetchCourseYears()
      }
    },
    startRemovingDepartment (department) {
      this.showRemoveDepartmentDialog = true
      this.tempDepartment = department
    },
    cancelAddingDepartment () {
      this.errorInDepartmentName = false
      this.tempDepartment = ''
      this.showAddDepartmentDialog = false
    },
    saveAddingDepartment () {
      this.loading = true
      this.errorInDepartmentName = false
      this.tempDepartment = this.tempDepartment.trim()
      if (
        this.departments.indexOf(this.tempDepartment) === -1 &&
        this.tempDepartment !== ''
      ) {
        this.departments.push(this.tempDepartment)
        this.selectedCourseYear = ''
        this.courseYears = []
        this.divisions = []
        this.selectedDepartment = this.tempDepartment
        this.cancelAddingDepartment()
      } else {
        // Alert about department already exists
        this.errorInDepartmentName = true
      }
      this.loading = false
    },
    cancelRemoveDepartment () {
      this.tempDepartment = ''
      this.showRemoveDepartmentDialog = false
    },
    async removeDepartment () {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        department: this.selectedDepartment
      }
      try {
        await this.academicReferenceRepositoryInstance.removeDepartment(
          objToPush
        )
        arrayOps.removeFromArray(
          this.departments,
          this.departments.indexOf(this.selectedDepartment)
        )
        this.courseYears = []
        this.divisions = []
        this.selectedCourseYear = ''
        this.tempCourseYear = ''
        this.tempDepartment = ''
        this.showRemoveDepartmentDialog = false
        showStatus('Department removed successfully.', 1000, 'success', this)
      } catch (err) {
        showStatus(
          'Cannot connect to server, please try later.',
          1000,
          'error',
          this
        )
        console.log(err)
      }
      this.loading = false
    },
    startAddCourseYear () {
      this.tempCourseYear = ''
      this.showAddCourseYearDialog = true
    },
    async selectCourseYear (courseYear) {
      if (this.selectedCourseYear !== courseYear) {
        this.selectedCourseYear = courseYear
        this.fetchDivisions()
      }
    },
    startRemovingCourseYear (courseYear) {
      this.showRemoveCourseYearDialog = true
      this.tempCourseYear = courseYear
    },
    cancelAddingCourseYear () {
      this.errorInCourseYearName = false
      this.tempCourseYear = ''
      this.showAddCourseYearDialog = false
    },
    saveAddingCourseYear () {
      this.loading = true
      this.errorInCourseYearName = false
      this.tempCourseYear = this.tempCourseYear.trim()
      if (
        this.courseYears.indexOf(this.tempCourseYear) === -1 &&
        this.tempCourseYear !== ''
      ) {
        this.courseYears.push(this.tempCourseYear)
        this.divisions = []
        this.selectedCourseYear = this.tempCourseYear
        this.cancelAddingCourseYear()
      } else {
        // Alert about year already exists
        this.errorInCourseYearName = true
      }
      this.loading = false
    },
    cancelRemoveCourseYear () {
      this.tempCourseYear = ''
      this.showRemoveCourseYearDialog = false
    },
    async removeCourseYear () {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear
      }
      try {
        await this.academicReferenceRepositoryInstance.removeCourseYear(
          objToPush
        )
        arrayOps.removeFromArray(
          this.courseYears,
          this.courseYears.indexOf(this.tempCourseYear)
        )
        this.divisions = []
        this.tempDivision = ''
        this.tempCourseYear = ''
        this.showRemoveCourseYearDialog = false
        showStatus('Course Year removed successfully', 1000, 'success', this)
      } catch (err) {
        showStatus(
          'Cannot connect to server, please try later.',
          1000,
          'error',
          this
        )
        console.log(err)
      }
      this.loading = false
    },
    startAddDivision () {
      this.tempDivision = ''
      this.showAddDivisionDialog = true
    },
    startRemovingDivision (division) {
      this.showRemoveDivisionDialog = true
      this.tempDivision = division
    },
    cancelAddingDivision () {
      this.errorInDivisionName = false
      this.tempDivision = ''
      this.showAddDivisionDialog = false
    },
    async saveAddingDivision () {
      this.loading = true
      this.errorInDivisionName = false
      this.tempDivision = this.tempDivision.trim()
      if (
        this.divisions.indexOf(this.tempDivision) === -1 &&
        this.tempDivision !== ''
      ) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.tempDivision
        }
        try {
          await this.academicReferenceRepositoryInstance.addDivision(objToPush)
          this.divisions.push(this.tempDivision)
          this.tempDivision = ''
          this.cancelAddingDivision()
          showStatus('Division added successfully.', 1000, 'success', this)
        } catch (err) {
          console.log(err)
        }
      } else {
        // Alert about division already exists
        this.errorInDivisionName = true
      }
      this.loading = false
    },
    cancelRemoveDivision () {
      this.tempDivision = ''
      this.showRemoveDivisionDialog = false
    },
    async removeDivision () {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.tempDivision
      }
      try {
        await this.academicReferenceRepositoryInstance.removeDivision(
          objToPush
        )
        this.divisions = arrayOps.removeFromArray(
          this.divisions,
          this.divisions.indexOf(this.tempDivision)
        )
        this.tempDivision = ''
        this.showRemoveDivisionDialog = false
        showStatus('Division removed successfully', 1000, 'success', this)
      } catch (err) {
        showStatus(
          'Cannot connect to server, please try later.',
          1000,
          'error',
          this
        )
        console.log(err)
        window.alert('Something went wrong! Please try again.')
      }
      this.loading = false
    }
  }
}
</script>
<style scoped src="./activityReferenceAcademicDetails.css"></style>
