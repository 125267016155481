<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select :items="Object.keys(semesterNamesToIds)" @change='selectSemester' v-model="semId" label="Semester" color="purple"></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          :items="departments"
          v-model="department"
          @change='selectDepartment'
          label="Department"
          color="purple"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          :items="courseYears"
          v-model="courseYear"
          @change='selectCourseYear'
          label="Course Year"
          color="purple"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select :items="divisions" v-model="division" label="Division" color="purple"></v-select>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      :color="!error ? 'success' : 'red'"
    >{{ !error ? "Successful!" : "Oops!" }}</v-snackbar>
  </div>
</template>

<script>
// import axios from "axios";
// import { apiUrl } from "../../Services/Config/apiConfig";
import SemesterRepository from '../../../Repository/Semester'
export default {
  props: ['semesters'],
  data () {
    return {
      semId: '',
      department: '',
      courseYear: '',
      division: '',
      departments: [],
      courseYears: [],
      divisions: [],
      snackbar: false,
      error: false,
      loading: false,
      selectedInstitute: null,
      semestersList: [],
      academicDataTree: {},
      semesterNamesToIds: {}
    }
  },
  created () {
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.semesterNamesToIds = {}
    for (let i = 0; i < this.semesters.length; i++) {
      this.semesterNamesToIds[this.semesters[i].semName] = this.semesters[i].semId
    }
  },
  methods: {
    async fetchData () {
    },
    getData () {
      const obj = {}
      obj.semIdNew = this.semesterNamesToIds[this.semId]
      obj.department = this.department
      obj.courseYear = this.courseYear
      obj.division = this.division
      return obj
    },
    async selectSemester () {
      this.departments = []
      this.department = ''
      this.courseYears = []
      this.courseYear = ''
      this.divisions = []
      this.division = ''
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.semesterNamesToIds[this.semId]
      }
      this.semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
      this.semestersList.forEach((sem) => {
        if (!this.academicDataTree[sem.department]) {
          this.academicDataTree[sem.department] = {}
        }
        if (!this.academicDataTree[sem.department][sem.courseYear]) {
          this.academicDataTree[sem.department][sem.courseYear] = []
        }
        this.academicDataTree[sem.department][sem.courseYear].push(sem.division)
      })
      this.departments = Object.keys(this.academicDataTree)
    },
    selectDepartment () {
      this.courseYears = []
      this.courseYear = ''
      this.divisions = []
      this.division = ''
      this.courseYears = Object.keys(this.academicDataTree[this.department])
    },
    selectCourseYear () {
      this.divisions = []
      this.division = ''
      this.divisions = this.academicDataTree[this.department][this.courseYear]
    }
  }
}
</script>

<style scoped>
</style>
