var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityViewSemesters"},[_c('v-card',{staticStyle:{"padding":"20px","box-shadow":"2px 2px 20px rgba(0, 0, 0, 0.1)","border-radius":"20px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" Semester Name ")]),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('inputContainer',[_c('v-select',{attrs:{"solo":"","flat":"","background-color":"transparent","label":"Choose A Semester","items":_vm.semestersList},on:{"change":_vm.getEditingPermissionOfASemester},model:{value:(_vm.selectedSemester),callback:function ($$v) {_vm.selectedSemester=$$v},expression:"selectedSemester"}})],1)],1),_c('v-col',[_c('v-btn',{staticStyle:{"color":"white","height":"37px","width":"90px","margin-top":"5px","margin-right":"10px"},attrs:{"disabled":_vm.selectedSemester === "","color":"#FF4F1F"},on:{"click":_vm.selectSemester}},[_vm._v("View")]),_c('v-btn',{staticStyle:{"height":"37px","width":"90px","margin-top":"5px"},attrs:{"outlined":""},on:{"click":_vm.gotoEditSemester}},[_vm._v("Edit")])],1)],1)],1)])],1)],1),_c('v-card',{staticStyle:{"padding":"20px","margin-top":"20px","box-shadow":"2px 2px 20px rgba(0, 0, 0, 0.1)","border-radius":"20px"}},[_c('v-card-title',[_vm._v(" Course Year Details "),_c('v-spacer'),_c('inputContainer',{staticStyle:{"margin-left":"20px"}},[_c('v-text-field',{attrs:{"label":"Search Details...","solo":"","flat":"","background-color":"transparent"},model:{value:(_vm.search1),callback:function ($$v) {_vm.search1=$$v},expression:"search1"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.semesterCourseYears,"headers":_vm.headers2,"search":_vm.search1},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.department))])]}},{key:"item.courseYear",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.courseYear))])]}},{key:"item.subjects",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.subjects),function(subject,i){return _c('v-chip',{key:i,staticStyle:{"margin":"4px","margin-bottom":"8px"}},[_vm._v(_vm._s(subject.subjectName)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',[_c('smallButton',{attrs:{"icon":"mdi-pencil","click":_vm.gotoEditSemesterDivision,"object":item}})],1)]}}])})],1)],1),_c('v-card',{staticStyle:{"padding":"20px","margin-top":"20px","box-shadow":"2px 2px 20px rgba(0, 0, 0, 0.1)","border-radius":"20px"}},[_c('v-card-title',[_vm._v(" Divisions Details "),_c('v-spacer'),_c('inputContainer',{staticStyle:{"margin-left":"20px"}},[_c('v-text-field',{attrs:{"label":"Search Details...","solo":"","flat":"","background-color":"transparent"},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.semesterDivisions,"headers":_vm.headers,"search":_vm.search2},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.department))])]}},{key:"item.courseYear",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.courseYear))])]}},{key:"item.division",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.division))])]}},{key:"item.classTeacher",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.facultyIdToFacultyNameMapping[item.classTeacher] || ""))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('center',[(item.startDate)?_c('div',[_vm._v(_vm._s(_vm.convertDate(item.startDate)))]):_c('div',[_vm._v("--")])])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('center',[(item.endDate)?_c('div',[_vm._v(_vm._s(_vm.convertDate(item.endDate)))]):_c('div',[_vm._v("--")])])]}},{key:"item.holidays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.holidays ? item.holidays.length : '--')+" ")]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.shift))])]}},{key:"item.batches",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.numberOfBatches))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.editingPermission)?_c('div',_vm._g({staticStyle:{"box-shadow":"2px 2px 10px rgba(0, 0, 0, 0.2)","padding":"2px 4px 4px 4px","border-radius":"5px","cursor":"pointer","margin-right":"5px"}},on),[_c('center',[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()]}}],null,true)},[_c('v-list',_vm._l((_vm.divisionOperations),function(operation,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.gotoEditSemesterDivision(operation, item)}}},[_vm._v(_vm._s(operation))])],1)}),1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }