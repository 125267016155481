export default {
  createAdmissionUser: '/admissionUser/createAdmissionUser',
  getAdmissionUsers: '/admissionUser/getAdmissionUsers',
  getAdmissionUsersById: '/admissionUser/getAdmissionUsersById',
  getAdmissionUser: '/admissionUser/getAdmissionUser',
  updateAdmissionUser: '/admissionUser/updateAdmissionUser',
  updateAdmissionUserPayment: '/admissionUser/updateAdmissionUserPayment',
  deleteAdmissionUser: '/admissionUser/deleteAdmissionUser',
  addUserDetails: '/admissionUser/addUserDetails',
  saveAdmission :'/admission/createAdmission',
  savePayment:'/admission/createAdmissionPayment',
  saveSubject:'/admission/createAdmissionSubject',
  getAdmissionDataBySem:'/admission/getAdmissionDataBySem',
  getAdmissionDataForEdit:'/admission/getAdmissionDataBYInstituteAndSem',
  saveEditAdmission:'/admission/updateAdmission',
  saveEditPayment:'/admission/updateAdmissionPayment',
  saveEditSubject:'/admission/updateAdmissionSubject',
  getOrderId: '/admission/generateOrder',
  submitPaymentInstallment:'/admission/submitPaymentInstallment',
  getAdmissionDataForStudent:'/admission/getAdmissionDataForStudent',
  getAdmissionDataByAdmissionId:'/admission/getAdmissionDataByAdmissionId',
  getAdmissionUsersByadmissionUserId:'/admissionUser/getAdmissionUsersByadmissionUserId'
}
