<template src="./activityAdmissionNewUsers.html"></template>
<script>
import UserRepository from '../../Repository/User'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import SemesterRepository from '../../Repository/Semester'
export default {
  name: 'activityAdmissionNewUsers',
  components: {
    inputContainer,
  },
  props: ['prop_admissionDataStudent'],
  data() {
    return {
      loading: false,
      search: '',
      selectedAdmission: {},
      headers: [
        {
          text: 'Date Of Admission',
          align: 'left',
          sortable: true,
          value: 'dateOfAdmission',
          filterable: true,
        },
        {
          text: 'StudentName',
          align: 'left',
          sortable: true,
          value: 'firstName',
          filterable: true,
        },
        {
          text: 'Subjects / Courses',
          align: 'left',
          sortable: true,
          value: 'subjects',
          filterable: true,
        },
        {
          text: 'Payment Installments',
          sortable: true,
          value: 'installments',
          filterable: true,
        },
        {
          text: 'Payment Type',
          sortable: true,
          value: 'paymentType',
          filterable: true,
        },
        // {
        //   text: 'Status',
        //   align: 'left',
        //   sortable: true,
        //   value: 'isConfirmed',
        //   filterable: true
        // },
        // {
        //   text: 'actions',
        //   value: 'actions'
        // },
        {
          text: 'Actions',
          value: 'action',
          align: 'center',
        },
      ],
      admissionList: [],
      users: [],
    }
  },
  created() {
    console.log('prop_admissionDataStudent', this.prop_admissionDataStudent)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    this.fetchData()
  },
  methods: {
    async goToAdmissionBack() {
      this.$router.push({
        name: 'activityAdmission',
      })
    },

    async gotoForm(user) {
      user.semId = this.prop_admissionDataStudent.semId
      console.log('user from gotoForm', user)
      this.$router.push({
        name: 'activityExistingAdmissionForm',
        params: {
          prop_user: user,
        },
      })
    },
    async gotoReceipt(user) {
      this.$router.push({
        name: 'activityFeeReceipt',
        params: {
          prop_user: user,
          prop_admissionDataStudent: this.prop_admissionDataStudent
        },
      })
    },
    async fetchData() {
      const res =
        await this.admissionUserRepositoryInstance.getAdmissionUsersById({
          admissionId: this.prop_admissionDataStudent.admissionId,
          sourceEmailId: this.userData.email,
        })
      this.users = res.admissionUsers
      console.log('users', this.users)
      this.processUsers()
      this.loading = false
    },

    async processUsers() {
      this.users.forEach((user) => {
        user.batches.forEach((batch) => {
          user[batch.subject] = true
        })
      })
      this.showUsers = JSON.parse(JSON.stringify(this.users))
    },

    parseDate(dateOfBirth) {
      const parsedDate = new Date(dateOfBirth)
      return (
        parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getUTCDay()).slice(-2)
      )
    },

    dateConverter(date) {
      date = new Date(date)
      const dateString = date.toISOString()
      return dateString.substring(0, 10).split('-').reverse().join('/')
    },
  },
}
</script>
<style src="./activityAdmissionNewUsers.css" scoped></style>
