var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityEditFacultyAllocation"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"margin-top":"20px","padding":"20px","box-shadow":"2px 2px 20px rgba(0, 0, 0, 0.1)","height":"calc(100% - 20px)"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('inputContainer',{staticStyle:{"margin-bottom":"10px"}},[_c('v-select',{attrs:{"label":"Select Department","solo":"","flat":"","background-color":"transparent","items":_vm.departments},on:{"change":_vm.selectDepartment},model:{value:(_vm.selectedDepartment),callback:function ($$v) {_vm.selectedDepartment=$$v},expression:"selectedDepartment"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('inputContainer',{staticStyle:{"margin-bottom":"10px"}},[_c('v-select',{attrs:{"disabled":_vm.selectedDepartment === "","label":"Select Course Year","solo":"","flat":"","background-color":"transparent","items":_vm.courseYears},on:{"change":_vm.selectCourseYear},model:{value:(_vm.selectedCourseYear),callback:function ($$v) {_vm.selectedCourseYear=$$v},expression:"selectedCourseYear"}})],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"#050992","indeterminate":""}}):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.facultyAllocation,"headers":_vm.headers2,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.subjectType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.subjectTypes[item.subjectType])+" ")]}},{key:"item.faculties",fn:function(ref){
var item = ref.item;
return _vm._l((item.faculties),function(fac,i){return _c('div',[(_vm.getNameOfFaculty(fac) !== '')?_c('v-chip',{key:fac,attrs:{"close":""},on:{"click:close":function($event){return _vm.removeFaculty(fac, item)}}},[_vm._v(_vm._s(_vm.getNameOfFaculty(fac))+" ")]):_vm._e()],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',[_c('smallButton',{attrs:{"icon":"mdi-plus","click":_vm.startAddingFaculties,"object":item}})],1)]}}])})],1)],1)],1),_c('v-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFacultyListDialog),expression:"showFacultyListDialog"}],attrs:{"width":"60vw"},model:{value:(_vm.showFacultyListDialog),callback:function ($$v) {_vm.showFacultyListDialog=$$v},expression:"showFacultyListDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Faculty List "),_c('v-spacer'),_c('inputContainer',[_c('v-text-field',{attrs:{"label":"Search Faculty","solo":"","flat":"","background-color":"transparent"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.faculties,"headers":_vm.headers,"search":_vm.search,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',[_c('smallButton',{attrs:{"icon":"mdi-plus","click":_vm.addSubjectTeacher,"object":item}})],1)]}}])}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"height":"37px","width":"90px","margin-right":"5px"},attrs:{"color":"#FF4F1F","outlined":""},on:{"click":function($event){_vm.showFacultyListDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }