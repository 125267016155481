<template src="./activityAdmissionForm.html"></template>
<script>
import inputContainer from '../../Components/inputContainer'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SemesterRepository from '../../Repository/Semester'
import MailSenderRepository from '../../Repository/EmailSender'
import arrayOps from '../../Services/Utils/arrayOps'
import auth from '@/Services/Firebase/auth'
import inProspectsLogo from '../../Assets/Images/studium.png'
import Vue from 'vue'
import {
  Vradiogroup,
  VApp,
  Vradio,
  /* other imports ... */
} from 'vuetify'
var firestorage = null
export default {
  name: 'activityAdmissionForm',
  components: {
    inputContainer,
  },
  props: ['prop_user', 'prop_admissionData'],
  data() {
    return {
      updateForm: false,
      countryCode: '',
      nameOfFather: '',
      fatherMobileNumber: '',
      occupationOfFather: '',
      nameOfMother: '',
      motherMobileNumber: '',
      occupationOfMother: '',
      correspondenceFlatNo: '',
      correspondenceLandmark: '',
      correspondenceCity: '',
      correspondenceState: '',
      correspondencePincode: '',
      permanentFlatNo: '',
      permanentLandmark: '',
      permanentCity: '',
      permanentState: '',
      permanentPincode: '',
      dob: '',
      gender: '',
      previousschoolName: '',
      previousclassName: '',
      previouspercentage: '',
      currentschoolName: '',
      currentclassName: '',
      group1: '',
      brandingName: '',
      groupedsubjectsList: {},
      brandingLogo: '',
      status: false,
      admissionPayment: [],
      isAllSubjectCheck: 0,
      showToStudents: false,
      subjectWise: true,
      installmentCheck: '',
      durationCheck: '',
      subjectsList: [],
      subjectsLists: [],
      semestersList: [],
      coursename: [],
      semestersLists: [],
      selectedCourseYear: '',
      selectedDivision: '',
      selectedClassName: '',
      classname: [],
      selectedSemester: '',
      selectedInstitute: '',
      updating: false,
      existingUser: {},
      myDataObj: {},
      tempPayments: [],
      paymentsOptions: [],
      userObject: {},
      selectedTab: 0,
      firstName: '',
      middleName: '',
      lastName: '',
      parentFullName: '',
      emailId: '',
      parentEmailId: '',
      mobileNumber: '',
      parentMobileNumber: '',
      address: '',
      guardianFullName: '',
      guardianEmailId: '',
      guardianMobileNumber: '',
      guardianAddress: '',
      emailBody: '',
      schoolName: '',
      className: '',
      percentage: '',
      search: '',
      paymentOption: '',
      installmentOption: '',
      paymentMethod: '',
      installmentPaymentDetails: [],
      extraFormFields: [],
      subjects: [],
      subjectsBatchMappping: {},
      selectedBatches: [],
      isBasicBatchSelected: 'false',
      selectedSPBatch: {},
      finalSubjectList: [],
      tempExtraFields: [],
      groupedsubjectsListArr: [],
      instalmentDuration: 0,
      selectedSemesterByStore: '',
      admissionList: [],
      selectedAdmission: '',
      admissionFormActivity: {},
      profileUploadTask: '',
      countExtrafile: 0,
      adharUploadTask: '',
      extraFormFileUploadTask: '',
      profileImageButton: false,
      adharImageButton: false,
      profileImageUrl: '',
      adharCardUrl: '',
      profileImage: '',
      tempFormFieldUrl: '',
      adharImageUploaded: 0,
      extraFormFileUploaded: 0,
      progress: 0,
      adharCard: '',
      inProspectsLogo: inProspectsLogo,
      razorPayNumber: '',
      razorpayAccessToken: '',
      tempFormFieldUrl: '',
      razorpaySecrete: '',
      descriptionForPayment: '',
      rules: [
        (v) => !!v || 'Required',
        (v) => (v = 10 || 'Number should be 10 digit'),
      ],
    }
  },
  async created() {
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    console.log('this.prop_user', this.prop_user);
    if (this.prop_user) {
      this.selectedInstitute = this.prop_user.instituteId
      this.selectedSemester = this.prop_user.semId
      this.profileImageUrl = this.prop_user.profileImageUrl
      this.selectedDepartment = this.prop_user.department
      this.selectedCourseYear = this.prop_user.courseYear
      this.selectedDivision = this.prop_user.division
      this.firstName = this.prop_user.firstName
      this.middleName = this.prop_user.middleName
      this.lastName = this.prop_user.lastName
      this.emailId = this.prop_user.emailId
      this.mobileNumber = this.prop_user.mobileNumber
      this.parentFullName = this.prop_user.parentFullName
      this.parentEmailId = this.prop_user.parentEmailId
      this.parentMobileNumber = this.prop_user.parentMobileNumber
      this.address = this.prop_user.address
      this.guardianFullName = this.prop_user.guardianFullName
      this.guardianEmailId = this.prop_user.guardianEmailId
      this.guardianMobileNumber = this.prop_user.guardianMobileNumber
      this.guardianAddress = this.prop_user.guardianAddress
      this.schoolName = this.prop_user.schoolName
      this.className = this.prop_user.className
      this.percentage = this.prop_user.percentage
      this.updating = true
      this.userObject.admissionUserId = this.prop_user.admissionUserId
    }
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.MailSenderRepositoryInstance = new MailSenderRepository(this)
    this.selectedSemesterByStore =
      this.$store.getters['liveData/get_admissionSemester']
    this.selectedInstitute =
      this.$store.getters['liveData/get_admissionInstitute']
    this.userData = this.$store.getters['user/get_userData']
    this.brandingName = this.$store.getters['liveData/get_brandingName']
    this.brandingLogo = this.$store.getters['liveData/get_brandingLogo']
    this.razorPayNumber = this.$store.getters['liveData/get_razorPayNumber']
    this.razorpayAccessToken =
      this.$store.getters['liveData/get_razorpayAccessToken']
    this.razorpaySecrete = this.$store.getters['liveData/get_razorpaySecrete']
    this.descriptionForPayment =
      this.$store.getters['liveData/get_descriptionForPayment']
    this.instituteStudentBrandingName =
      this.$store.getters['liveData/get_instituteStudentBrandingName']
    this.instituteStudentLogoLink =
      this.$store.getters['liveData/get_instituteStudentLogoLink']
    this.senderMail = this.$store.getters['liveData/get_senderMail']
    this.senderMailPassword =
      this.$store.getters['liveData/get_senderMailPassword']
    this.displayName = this.$store.getters['liveData/get_displayName']
    this.subjectDescription =
      this.$store.getters['liveData/get_subjectDescription']
    this.emailBody = this.$store.getters['liveData/get_emailBody']
    const res = await this.admissionUserRepositoryInstance.getAdmissionDataByAdmissionId({
      status: true,
      instituteId: this.selectedInstitute,
      admissionId: this.prop_admissionData.admissionId,
    });
    this.prop_admissionData.admissionFormActivity[0].formActivity.map((form) => {
      if (form.id > 34) {
        if (form.type === 'Checkbox') {
          form.value = []
        }
        if (form.type === 'File') {
          form.uploadProgress = 0
        }
        this.extraFormFields.push(form)
      }
    })
    console.log('this.extraFormFields', this.extraFormFields);
    this.fetchAdmissionDetailsSelect()
    // this.sendWhatsapp()
  },

  watch: {
    profileUploadTask: function () {
      this.profileUploadTask.on(
        'state_changed',
        (sp) => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          this.profileImage = this.profileImage
          this.profileUploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('Profile pic url', downloadURL)
            this.profileImageUrl = downloadURL
          })
        }
      )
    },
    adharUploadTask: function () {
      console.log('hello adharUploadTask');
      this.adharUploadTask.on(
        'state_changed',
        (sp) => {
          console.log('adharUploadTask', Math.floor((sp.bytesTransferred / sp.totalBytes) * 100));
          this.adharImageUploaded = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100)
        },
        null,
        () => {
          this.adharCard = this.adharCard
          this.adharUploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("adharcard upload url", downloadURL)
            this.adharCardUrl = downloadURL
          })
        }
      )
    },
    extraFormFileUploadTask: function () {
      console.log('hello extraFormFileUploadTask');
      this.extraFormFileUploadTask.on(
        'state_changed',
        (sp) => {
          this.progress = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100)
          this.updateFileProgress(this.progress)
        },
        null,
        () => {
          this.extraFormFileUploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            const index = this.extraFormFields.indexOf(this.selectedformField)
            this.extraFormFields[index].value = downloadURL
            console.log("extraFormFileUploadTask", this.extraFormFields)
          })
        }
      )
    },
  },

  methods: {
    updateFileProgress(progress) {
      console.log('progress', progress);
      const index = this.extraFormFields.indexOf(this.selectedformField)
      this.extraFormFields[index].uploadProgress = progress
    },
    getExrtafieldUrl(url, index) {
      console.log('index', index);
      this.extraFormFields[index].value = url
      console.log('this.extraFormFields[index].value', this.extraFormFields[index].value);
      // this.extraFormFields[index]
    },
    showCheckBox(n) {
      console.log('checkbox details', n);
      console.log(n.subjectName, 'is', n.isCompulsory);
    },
    async onUploadProfilePicture(file) {
      await this.uploadProfilePicture(file)
    },
    async onUploadAdharcard(file) {
      console.log('hello', file);
      await this.uploadAdharcard(file[0])
    },
    async sendWelcomeMail() {
      try {
        let objToPush = {
          senderMail: this.senderMail,
          senderMailPassword: this.senderMailPassword,
          displayName: this.displayName,
          subjectDescription: this.subjectDescription,
          brandingLogo: this.instituteStudentLogoLink,
          brandingName: this.instituteStudentBrandingName,
          admissionName: this.selectedAdmission.admissionName,
          firstName: this.userObject.firstName,
          lastName: this.userObject.lastName,
          emailId: this.userObject.emailId,
          emailBody: this.emailBody,
        }
        const res = await this.MailSenderRepositoryInstance.sendWelcomeMail(
          objToPush
        )
        console.log('MAIL RESPONSE', res)
      } catch (e) {
        console.log('err', e)
      }
    },
    async onUploadExtraFieldFile(formField) {
      console.log('formField', formField);
      this.selectedformField = formField
      await this.uploadExtraFormField(formField.file)
    },
    async uploadExtraFormField(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        this.extraFormFile = file.name
        this.extraFormFileUploadTask = firestorage
          .ref('admissionImage/' + file.name)
          .put(file)
      } else {
        this.image = ''
      }
    },
    async uploadProfilePicture(file) {
      console.log('uploadProfilePicture', file);
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        if (size > 500) {
          this.profileImageButton = true
          return
        }
        this.profileImageButton = false
        this.profileImage = file.name
        this.profileUploadTask = firestorage
          .ref('admissionImage/' + file.name)
          .put(file)
      } else {
        this.profileImageButton = false
        this.image = ''
      }
    },
    async uploadAdharcard(file) {
      console.log('file', file);
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        if (size > 500) {
          this.adharImageButton = true
          return
        }
        this.adharImageButton = false
        this.profileImage = file.name
        this.adharUploadTask = firestorage
          .ref('admissionAdharImage/' + file.name)
          .put(file)
      } else {
        this.adharImageButton = false
        this.image = ''
      }
    },

    async goToAdmissionBack() {
      this.$router.push({
        name: 'activityAdmission',
      })
    },

    async fetchAdmissionDetailsSelect() {
      let data = this.prop_admissionData
      this.admissionPayment = data.admissionPayment[0]
      this.selectedAdmission = data
      this.selectedSemester = data.semId
      this.selectedClassName = data.department
      this.selectedCourseName = data.courseYear
      data.admissionName =
        data.semName + '-' + data.department + '-' + data.courseName
      this.admissionList.push(data)
      this.fetchAdmissionDetails()
    },

    async checkAdmissionExitsOrNot() {
      this.admissionList.forEach((admission) => {
        if (
          admission.semd == this.selectedSemester.semId &&
          admission.department == this.selectedClassName &&
          admission.courseYear == this.selectedCourseName
        ) {
          this.selectedAdmission = admission
          this.selectedSemester.semId = admission.semId
          this.selectedClassName = admission.department
          this.selectedCourseName = admission.courseYear
        } else {
          alert(
            'There is No admission found in this semester Please change semesters'
          )
          this.selectedSemester = ''
          this.selectedClassName = ''
          this.selectedCourseName = ''
        }
      })
    },

    async fetchAdmissionDetails() {
      if (this.prop_admissionData.admissionPayment.length) {
        this.admissionPaymentId =
          this.prop_admissionData.admissionPayment[0].admissionPaymentId
        this.installmentCheck =
          this.prop_admissionData.admissionPayment[0].instalmentsNumber
        this.instalmentDuration =
          this.prop_admissionData.admissionPayment[0].instalmentDuration
        this.handleInstallmentChangeNew(
          this.installmentCheck,
          this.instalmentDuration
        )
      }
      if (this.prop_admissionData.subjectLists.length) {
        let subjects = []
        subjects = this.prop_admissionData.subjectLists
        this.subjectsLists = this.prop_admissionData.subjectLists
        console.log('this.subjectsLists', this.subjectsLists);
        if (this.subjectsLists[0].feesCriteria === 'subjectWise' || !this.subjectsLists[0].feesCriteria) {
          this.subjectWise = true
          this.groupedsubjectsList = subjects.reduce((group, subject, i) => {
            const { groupName } = subject;
            const { subjectName } = subject;
            console.log('subjectName', subjectName);
            if (groupName !== undefined && groupName !== "" && groupName !== null) {
              group[groupName] = group[groupName] ?? [];
              group[groupName].push(subject);
              return group;
            } else {
              group[subjectName] = group[subjectName] ?? [];
              group[subjectName].push(subject);
              return group;
            }
          }, {});
          for (const [key, value] of Object.entries(this.groupedsubjectsList)) {
            value.map((obj) => {
              if (obj.isCompulsory === 1) {
                value.group = obj.subjectName
              }
            })
          }
        } else {
          this.subjectWise = false
          this.groupedsubjectsListArr = this.subjectsLists
        }
      }
      if (this.prop_admissionData.admissionFormActivity.length) {
        this.admissionFormActivity =
          this.prop_admissionData.admissionFormActivity[0].formActivity
      }
    },

    async gotoNextStep() {
      let subjectArr = []
      let finalSubjectList = []
      if (this.subjectWise === true) {
        console.log('this.groupedsubjectsList', this.groupedsubjectsList);
        for (const [key, value] of Object.entries(this.groupedsubjectsList)) {
          if (value.group !== undefined) {
            subjectArr.push(value.group)
          }
        }
        this.subjectsLists.map((subject) => {
          subjectArr.map((subName) => {
            if (subject.subjectName === subName) {
              finalSubjectList.push(subject)
            }
          })
        })
      } else {
        finalSubjectList =this.groupedsubjectsListArr
      }
      for (let i = 0; i < this.extraFormFields.length; i++) {
        const form = this.extraFormFields[i];
        console.log('form.value', form.value);
        if (form.type === 'Checkbox' || form.type === 'Dropdown') {
          if (form.isToShow === 1 && form.status === 1 && (form.value.length === 0 || form.value === undefined)) {
            return alert(`${form.name} is Missing...`)
          }
        } else {
          if (form.isToShow === 1 && form.status === 1 && (form.value === '' || form.value === undefined)) {
            return alert(`${form.name} is Missing...`)
          }
        }
      }
      //TODO: Dynamic alerts
      // let allfieldsFilled = true
      // this.admissionFormActivity.forEach((field, i) => {
      //   let j = i + 1
      //   console.log("this is field", this[field.name]);
      //   if (field.isToShow === 1 && field.id === j && field.status === 1) {
      //     console.log('field', field);
      //     alert(`Enter field '${field.name}' first`)
      //     allfieldsFilled = false
      //     return
      //   }
      // })
      console.log('finalSubjectList', finalSubjectList);
      if (
        this.admissionFormActivity[0].isToShow == 1 &&
        this.admissionFormActivity[0].id == 1 &&
        this.admissionFormActivity[0].status == 1 &&
        this.firstName === ''
      ) {
        alert('Enter your First Name..!')
        return
      } else if (
        this.admissionFormActivity[1].isToShow == 1 &&
        this.admissionFormActivity[1].id == 2 &&
        this.admissionFormActivity[1].status == 1 &&
        this.middleName === ''
      ) {
        alert('Enter your Middle Name..!')
        return
      } else if (
        this.admissionFormActivity[2].isToShow == 1 &&
        this.admissionFormActivity[2].id == 3 &&
        this.admissionFormActivity[2].status == 1 &&
        this.lastName === ''
      ) {
        alert('Enter your Last Name..!')
        return
      } else if (
        this.admissionFormActivity[3].isToShow == 1 &&
        this.admissionFormActivity[3].id == 4 &&
        this.admissionFormActivity[3].status == 1 &&
        this.mobileNumber === ''
      ) {
        alert('Enter your mobile number..!')
        return
      } else if (
        this.admissionFormActivity[4].isToShow == 1 &&
        this.admissionFormActivity[4].id == 5 &&
        this.admissionFormActivity[4].status == 1 &&
        this.emailId === ''
      ) {
        alert('Enter your Email Address..!')
        return
      } else if (
        this.admissionFormActivity[5].isToShow == 1 &&
        this.admissionFormActivity[5].id == 6 &&
        this.admissionFormActivity[5].id == 1 &&
        this.gender === ''
      ) {
        alert("Enter your gender..!")
        return
      } else if (
        this.admissionFormActivity[6].isToShow == 1 &&
        this.admissionFormActivity[6].id == 7 &&
        this.admissionFormActivity[6].status == 1 &&
        this.dob === ''
      ) {
        alert("Enter your Date of Birth..!")
        return
      } else if (
        this.admissionFormActivity[7].isToShow == 1 &&
        this.admissionFormActivity[7].id == 8 &&
        this.admissionFormActivity[7].status == 1 &&
        this.nameOfFather === ''
      ) {
        alert("Enter your Father's name..!")
        return
      } else if (
        this.admissionFormActivity[8].isToShow == 1 &&
        this.admissionFormActivity[8].id == 9 &&
        this.admissionFormActivity[8].status == 1 &&
        this.fatherMobileNumber === ''
      ) {
        alert('Enter your Father Mobile Number!')
        return
      } else if (
        this.admissionFormActivity[9].isToShow == 1 &&
        this.admissionFormActivity[9].id == 10 &&
        this.admissionFormActivity[9].status == 1 &&
        this.occupationOfFather === ''
      ) {
        alert('Enter your Occupation of Father..!')
        return
      } else if (
        this.admissionFormActivity[10].isToShow == 1 &&
        this.admissionFormActivity[10].id == 11 &&
        this.admissionFormActivity[10].status == 1 &&
        this.nameOfMother === ''
      ) {
        alert("Enter your Mother's Name..!")
        return
      } else if (
        this.admissionFormActivity[11].isToShow == 1 &&
        this.admissionFormActivity[11].id == 12 &&
        this.admissionFormActivity[11].status == 1 &&
        this.motherMobileNumber == ''
      ) {
        alert('Enter your Mother Mobile Number..!')
        return
      } else if (
        this.admissionFormActivity[12].isToShow == 1 &&
        this.admissionFormActivity[12].id == 13 &&
        this.admissionFormActivity[12].status == 1 &&
        this.occupationOfMother == ''
      ) {
        alert("Enter your Mother's Occupation..!")
        return
      } else if (
        this.admissionFormActivity[13].isToShow == 1 &&
        this.admissionFormActivity[13].id == 14 &&
        this.admissionFormActivity[13].status == 1 &&
        this.correspondenceAddress == ''
      ) {
        alert('Enter your Correspondence Flat No/Apt No/StreetNo..!')
        return
      } else if (
        this.admissionFormActivity[14].isToShow == 1 &&
        this.admissionFormActivity[14].id == 15 &&
        this.admissionFormActivity[14].status == 1 &&
        this.correspondenceLandmark == ''
      ) {
        alert('Enter your Correspondence Landmark/Location nearby..!')
        return
      } else if (
        this.admissionFormActivity[15].isToShow == 1 &&
        this.admissionFormActivity[15].id == 16 &&
        this.admissionFormActivity[15].status == 1 &&
        this.correspondenceCity == ''
      ) {
        alert('Enter your Correspondence City..!')
        return
      } else if (
        this.admissionFormActivity[16].isToShow == 1 &&
        this.admissionFormActivity[16].id == 17 &&
        this.admissionFormActivity[16].status == 1 &&
        this.correspondenceState == ''
      ) {
        alert('Enter your Correspondence State..!')
        return
      } else if (
        this.admissionFormActivity[17].isToShow == 1 &&
        this.admissionFormActivity[17].id == 18 &&
        this.admissionFormActivity[17].status == 1 &&
        this.correspondencePincode == ''
      ) {
        alert('Enter your Correspondence Pincode..!')
        return
      } else if (
        this.admissionFormActivity[18].isToShow == 1 &&
        this.admissionFormActivity[18].id == 19 &&
        this.admissionFormActivity[18].status == 1 &&
        this.permanentFlatNo == ''
      ) {
        alert('Enter your Permanent Flat No/Apt No/StreetNo..!')
        return
      } else if (
        this.admissionFormActivity[19].isToShow == 1 &&
        this.admissionFormActivity[19].id == 20 &&
        this.admissionFormActivity[19].status == 1 &&
        this.permanentLandmark == ''
      ) {
        alert('Enter your Permanent Landmark/Location nearby..!')
        return
      } else if (
        this.admissionFormActivity[20].isToShow == 1 &&
        this.admissionFormActivity[20].id == 21 &&
        this.admissionFormActivity[20].status == 1 &&
        this.permanentCity == ''
      ) {
        alert('Enter your Permanent City..!')
        return
      } else if (
        this.admissionFormActivity[21].isToShow == 1 &&
        this.admissionFormActivity[21].id == 22 &&
        this.admissionFormActivity[21].status == 1 &&
        this.permanentState == ''
      ) {
        alert('Enter your Permanent State..!')
        return
      } else if (
        this.admissionFormActivity[22].isToShow == 1 &&
        this.admissionFormActivity[22].id == 23 &&
        this.admissionFormActivity[22].status == 1 &&
        this.permanentPincode == ''
      ) {
        alert('Enter your Permanent Pincode..!')
        return
      } else if (
        this.admissionFormActivity[23].isToShow == 1 &&
        this.admissionFormActivity[23].id == 24 &&
        this.admissionFormActivity[23].status == 1 &&
        this.guardianFullName == ''
      ) {
        alert('Enter your Guardian Full name..!')
        return
      } else if (
        this.admissionFormActivity[24].isToShow == 1 &&
        this.admissionFormActivity[24].id == 25 &&
        this.admissionFormActivity[24].status == 1 &&
        this.guardianEmailId == ''
      ) {
        alert('Enter your Guardian EmailId..!')
        return
      } else if (
        this.admissionFormActivity[25].isToShow == 1 &&
        this.admissionFormActivity[25].id == 26 &&
        this.admissionFormActivity[25].status == 1 &&
        this.guardianMobileNumber == ''
      ) {
        alert('Enter your Guardian Mobile Number..!')
        return
      } else if (
        this.admissionFormActivity[26].isToShow == 1 &&
        this.admissionFormActivity[26].id == 27 &&
        this.admissionFormActivity[26].status == 1 &&
        this.guardianAddress == ''
      ) {
        alert('Enter your Guardian Address..!')
        return
      } else if (
        this.admissionFormActivity[27].isToShow == 1 &&
        this.admissionFormActivity[27].id == 28 &&
        this.admissionFormActivity[27].status == 1 &&
        this.previousschoolName == ''
      ) {
        alert('Enter your Previous School Name..!')
        return
      } else if (
        this.admissionFormActivity[28].isToShow == 1 &&
        this.admissionFormActivity[28].id == 29 &&
        this.admissionFormActivity[28].status == 1 &&
        this.previousclassName == ''
      ) {
        alert('Enter your Previous Class Name..!')
        return
      } else if (
        this.admissionFormActivity[29].isToShow == 1 &&
        this.admissionFormActivity[29].id == 30 &&
        this.admissionFormActivity[29].status == 1 &&
        this.previouspercentage == ''
      ) {
        alert('Enter your Previous Percentage..!')
        return
      } else if (
        this.admissionFormActivity[30].isToShow == 1 &&
        this.admissionFormActivity[30].id == 31 &&
        this.admissionFormActivity[30].status == 1 &&
        this.currentschoolName == ''
      ) {
        alert('Enter your Current School Name..!')
        return
      } else if (
        this.admissionFormActivity[31].isToShow == 1 &&
        this.admissionFormActivity[31].id == 32 &&
        this.admissionFormActivity[31].status == 1 &&
        this.currentclassName == ''
      ) {
        alert('Enter your Current Class Name..!')
        return
      } else if (
        this.admissionFormActivity[32].isToShow == 1 &&
        this.admissionFormActivity[32].id == 33 &&
        this.admissionFormActivity[32].status == 1 &&
        this.profileImage == ''
      ) {
        alert('Upload Your Profile Photo..!')
        return
      } else if (
        this.admissionFormActivity[33].isToShow == 1 &&
        this.admissionFormActivity[33].id == 34 &&
        this.admissionFormActivity[33].status == 1 &&
        this.adharCard == ''
      ) {
        alert('Upload Your Adharcard Photo..!')
        return
      } else {
        this.finalSubjectList = this.subjectsLists.filter(
          (subject) => subject.isCompulsory == true
        ),

          this.userObject = {
            ...this.userObject,
            admissionId: this.selectedAdmission.admissionId,
            instituteId: this.selectedInstitute,
            profileImageUrl: this.profileImageUrl,
            adharCardUrl: this.adharCardUrl,
            sourceEmailId: this.userData.email,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: this.selectedDivision,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            emailId: this.emailId,
            mobileNumber: this.countryCode + this.mobileNumber,
            nameOfFather: this.nameOfFather,
            fatherMobileNumber: this.fatherMobileNumber,
            occupationOfFather: this.occupationOfFather,
            nameOfMother: this.nameOfMother,
            motherMobileNumber: this.motherMobileNumber,
            occupationOfMother: this.occupationOfMother,
            correspondenceFlatNo: this.correspondenceFlatNo,
            correspondenceLandmark: this.correspondenceLandmark,
            correspondenceCity: this.correspondenceCity,
            correspondenceState: this.correspondenceState,
            correspondencePincode: this.correspondencePincode,
            permanentFlatNo: this.permanentFlatNo,
            permanentLandmark: this.permanentLandmark,
            permanentCity: this.permanentCity,
            permanentState: this.permanentState,
            permanentPincode: this.permanentPincode,
            dob: this.dob,
            gender: this.gender,
            guardianFullName: this.guardianFullName,
            guardianEmailId: this.guardianEmailId,
            guardianMobileNumber: this.guardianMobileNumber,
            guardianAddress: this.guardianAddress,
            previousschoolName: this.previousschoolName,
            previousclassName: this.previousclassName,
            previouspercentage: this.previouspercentage,
            currentschoolName: this.currentschoolName,
            currentclassName: this.currentclassName,
            batches: finalSubjectList,
            isConfirmed: false,
            totalPayment: 0,
            payments: [],
            paymentOptions: [],
            installments: '',
            paymentMethod: '',
          }
        this.userObject.extraFormFields = []
        this.extraFormFields.map((form) => {
          const objToPush = {
            value: form.value,
            name: form.name,
            type: form.type,
            options: form.options,
            id: form.id
          }
          this.userObject.extraFormFields.push(objToPush)
        })
        finalSubjectList.map((subject) => {
          subject.date = new Date()
          console.log('this.userObject.totalPayment', this.userObject.totalPayment);
          this.userObject.totalPayment = (parseFloat(this.userObject.totalPayment) + parseFloat(subject.totalFees)).toFixed(5)
        })
        this.gotoPayment()
      }
    },

    async gotoPayment() {
      // this.userObject.batches.map((subjectPayemnt) => {
      //   subjectPayemnt.date = new Date()
      //   this.userObject.totalPayment += parseInt(subjectPayemnt.totalFees)
      // })
      this.userObject.initialPayment = this.userObject.totalPayment
      const existingUser = JSON.parse(JSON.stringify(this.existingUser))
      existingUser.instituteId = this.userObject.instituteId
      existingUser.admissionId = this.userObject.admissionId
      existingUser.semId = this.userObject.semId
      existingUser.extraFormFields = this.userObject.extraFormFields
      existingUser.adharCardUrl = this.userObject.adharCardUrl
      existingUser.profileImageUrl = this.userObject.profileImageUrl
      existingUser.department = this.userObject.department
      existingUser.courseYear = this.userObject.courseYear
      existingUser.division = this.userObject.division
      existingUser.emailId = this.userObject.emailId
      existingUser.fullName = this.userObject.fullName
      existingUser.firstName = this.userObject.firstName
      existingUser.middleName = this.userObject.middleName
      existingUser.lastName = this.userObject.lastName
      existingUser.emailId = this.userObject.emailId
      existingUser.mobileNumber = this.userObject.mobileNumber
      existingUser.nameOfFather = this.userObject.nameOfFather
      existingUser.fatherMobileNumber = this.userObject.fatherMobileNumber
      existingUser.occupationOfFather = this.userObject.occupationOfFather
      existingUser.nameOfMother = this.userObject.nameOfMother
      existingUser.motherMobileNumber = this.userObject.motherMobileNumber
      existingUser.occupationOfMother = this.userObject.occupationOfMother
      existingUser.correspondenceFlatNo = this.userObject.correspondenceFlatNo
      existingUser.correspondenceLandmark = this.userObject.correspondenceLandmark
      existingUser.correspondenceCity = this.userObject.correspondenceCity
      existingUser.correspondenceState = this.userObject.correspondenceState
      existingUser.correspondencePincode = this.userObject.correspondencePincode
      existingUser.permanentFlatNo = this.userObject.permanentFlatNo
      existingUser.permanentLandmark = this.userObject.permanentLandmark
      existingUser.permanentCity = this.userObject.permanentCity
      existingUser.permanentState = this.userObject.permanentState
      existingUser.permanentPincode = this.userObject.permanentPincode
      existingUser.dob = this.userObject.dob
      existingUser.gender = this.userObject.gender
      existingUser.previousschoolName = this.userObject.previousschoolName
      existingUser.previousclassName = this.userObject.previousclassName
      existingUser.previouspercentage = this.userObject.previouspercentage
      existingUser.currentschoolName = this.userObject.currentschoolName
      existingUser.currentclassName = this.userObject.currentclassName
      existingUser.guardianFullName = this.userObject.guardianFullName
      existingUser.guardianEmailId = this.userObject.guardianEmailId
      existingUser.guardianMobileNumber = this.userObject.guardianMobileNumber
      existingUser.guardianAddress = this.userObject.guardianAddress
      existingUser.sourceEmailId = this.userObject.parentEmailId
      existingUser.totalPayment = this.userObject.totalPayment
      existingUser.admissionUserId = this.userObject.admissionUserId ? this.userObject.admissionUserId : ''


      if (this.subjectWise === true) {
        existingUser.batches = this.userObject.batches
      } else {
        this.userObject.batches = this.groupedsubjectsListArr
        // this.userObject.subjectName = this.groupedsubjectsList.courseName
      }

      if (this.updateForm === false) {
        console.log('this.userObject', this.userObject);
       const response = await this.admissionUserRepositoryInstance.createAdmissionUser(
          this.userObject
        )
        this.userObject.admissionUserId = response.admissionUser.admissionUserId
        this.updateForm = true
      } else {
        this.existingUser = existingUser
        let res = await this.admissionUserRepositoryInstance.updateAdmissionUser(
            this.existingUser
          )
        console.log('updating form', res)
      }
      this.selectedTab = 1
    },

    dateConverter(date) {
      const dateString = date.toISOString()
      return dateString.substring(0, 10).split('-').reverse().join('/')
    },

    async finalConfirmation() {
      this.userObject.paymentOption = this.paymentOption
      this.userObject.installments = this.installmentOption.instalmentsNumber
      this.userObject.paymentMethod = this.paymentMethod
      this.userObject.payments = this.tempPayments
      this.userObject.isConfirmed = true
      await this.admissionUserRepositoryInstance.updateAdmissionUser(
        this.userObject
      )
      this.sendWelcomeMail()
      this.sendWhatsapp()
      this.existingUser = this.userObject
      this.$router.go(-1)
      this.goToAdmissionBack()
    },
    async sendWhatsapp() {
      let mobileNo = this.userObject.mobileNumber
      let msg = `Hello ${this.userObject.firstName}+${this.userObject.lastName},%0a
Your admission for ${this.instituteStudentBrandingName} has been received successfully. %0a
%0a
Your admission is now subject to approval from your institute’s admin. We will notify your admission’s confirmation via WhatsApp.
Please stay tuned for a fruitful learning journey ahead!
%0a %0a
Thanks and Regards,%0a
${this.instituteStudentBrandingName}`
      // let msg = `Hello ${this.userObject.firstName}+${this.userObject.lastName}%0aCongratulations! Your admission%0afor ${this.instituteStudentBrandingName} has been confirmed!%0a %0a We have sent further information%0a about how to get started on your %0aregistered email address.%0a We hope you have a smooth onboarding process and assure you growth, learning and insight!%0a%0a%0a Thanks and Regards,%0a${this.instituteStudentBrandingName}`
      let response = await fetch(
        `http://wts.vision360solutions.co.in/api/sendText?token=6204a22c7f6a4eb2f0b0e3e7&phone=${mobileNo}&message=${msg}`
      )
    },
    handleInstallmentChangeNew(installmentCheck, durationCheck) {
      const date = new Date()
      for (let i = 1; i <= installmentCheck; i++) {
        let instalmentPaymentOption = {}
        instalmentPaymentOption.instalmentsNumber = i
        instalmentPaymentOption.installmentId = i
        instalmentPaymentOption.paymentDate = ''
        instalmentPaymentOption.paymentMethod = ''
        instalmentPaymentOption.paymentImage = ''
        instalmentPaymentOption.paymentAmount = ''
        instalmentPaymentOption.paymentTransactionId = ''
        instalmentPaymentOption.instalmentsName =
          i == 1 ? 'One Time Payment' : i + '  ' + 'Installment'
        instalmentPaymentOption.date = date
        instalmentPaymentOption.instalmentDuration = durationCheck
        instalmentPaymentOption.isPaidStatus = 0
        this.paymentsOptions.push(instalmentPaymentOption)
      }
    },

    handleInstallmentChange(installment) {
      this.tempPayments = []
      let finalAmount = 0
      const date = new Date()
      let installmentDiscount = this.admissionPayment.installmentDiscount
      let oneTimePaymentDisc = this.admissionPayment.oneTimePaymentDisc
      let installmentPattern =
        this.admissionPayment.installmentPattern[
        installment.instalmentsNumber - 2
        ] //current installment pattern from database

      for (let i = 1; i <= installment.instalmentsNumber; i++) {
        let instalmentPaymentOption = {}
        let installmentAmount =
          installment.instalmentsNumber === 1  ? this.userObject.initialPayment / installment.instalmentsNumber : installmentPattern[i - 1].installmentFeeAmount
        if (installment.instalmentsNumber === 1) {
          if (oneTimePaymentDisc != null) {
            this.userObject.paymentDiscount = oneTimePaymentDisc + '%'
            this.userObject.totalPayment = this.userObject.initialPayment - (this.userObject.initialPayment / 100) * oneTimePaymentDisc
            finalAmount = this.userObject.totalPayment  ? this.userObject.totalPayment : installmentAmount
          } else {
            this.userObject.paymentDiscount = ''
            this.userObject.totalPayment = this.userObject.initialPayment
            finalAmount = this.userObject.initialPayment
          }
        } else {
          if (installmentDiscount != null) {
            this.userObject.paymentDiscount = installmentDiscount + '%'
            this.userObject.totalPayment = this.userObject.initialPayment -  (this.userObject.initialPayment / 100) * installmentDiscount
            finalAmount =  this.userObject.totalPayment / installment.instalmentsNumber
          } else {
            this.userObject.paymentDiscount = ''
            finalAmount = installmentAmount ? installmentAmount : this.userObject.initialPayment / installment.instalmentsNumber
          }
        }

        instalmentPaymentOption.instalmentsNumber = i
        instalmentPaymentOption.installmentId = i
        instalmentPaymentOption.paymentDate = ''
        instalmentPaymentOption.paymentMethod = ''
        instalmentPaymentOption.paymentImage = ''
        instalmentPaymentOption.paymentAmount = ''
        instalmentPaymentOption.paymentTransactionId = ''
        instalmentPaymentOption.instalmentsName =
          installment.instalmentsNumber == 1
            ? 'One Time Payment'
            : 'Installment' + i
        instalmentPaymentOption.dueDate =
          i == 1
            ? new Date()
            : new Date(
              date.setDate(
                date.getDate() + parseInt(installment.instalmentDuration)
              )
            )
        instalmentPaymentOption.isPaidStatus = 0
        instalmentPaymentOption.status = 'unpaid'
        instalmentPaymentOption.amount = finalAmount
        instalmentPaymentOption.amount = instalmentPaymentOption.amount
        this.tempPayments.push(instalmentPaymentOption)
      }
    },

    async getCoursebySemesters() {
      this.selectedClassName = ''
      this.selectedCourseName = ''
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        }
        this.coursename =
          await this.semesterRepositoryInstance.getCoursebySemesters(objToPush)
      } catch (err) {
        console.log(err)
      }
    },

    async getClassbySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        }
        this.classname =
          await this.semesterRepositoryInstance.getCoursebySemesters(objToPush)
      } catch (err) {
        console.log(err)
      }
    },

    async addTotalfee(item) {
      if (item !== undefined && item !== null) {
        var subject = this.subjectsList.find(
          (x) => x.subjectId == item.subjectId
        )
        if (subject) {
          subject.totalFees = item.totalFees
          this.subjectsList = this.subjectsList
        }
      }
    },

    async addBasicfee(item) {
      if (item !== undefined && item !== null) {
        var subject = this.subjectsList.find(
          (x) => x.subjectId == item.subjectId
        )
        if (subject) {
          subject.basicFees = item.basicFees
          this.subjectsList = this.subjectsList
        }
      }
    },

    async getSemesters() {
      try {
        const objToPush = { instituteId: this.selectedInstitute }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semesters.reverse()
        this.semestersList = this.semesters
      } catch (err) {
        console.log(err)
      }
    },
    async payInstallment(amt, installmentNo) {
      if (amt <= 0) {
        amt = 1
      }
      const amount = (amt * 100).toString()
      let myObject = {}
      myObject.amount = amount
      myObject.razorpayAccessToken = this.razorpayAccessToken
      myObject.razorpaySecrete = this.razorpaySecrete

      const res = await new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'

        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }

        document.body.appendChild(script)
      })

      if (!res) {
        alert('Razorpay SDK Failed to load')
        return
      }

      const data = await this.admissionUserRepositoryInstance.getOrderId(
        myObject
      )
      const options = {
        key: this.razorpayAccessToken, // Enter the Key ID generated from the Dashboard
        name: this.instituteStudentBrandingName,
        currency: data.currency,
        amount: data.amount,
        order_id: data.id,
        description: this.descriptionForPayment,
        image: this.instituteStudentLogoLink,
        handler: async (response) => {
          // Validate payment at server - using webhooks is a better idea.

          this.tempPayments.forEach((installment) => {
            if (installment.instalmentsNumber === installmentNo) {
              installment.status = 'paid'
              installment.paymentTransactionId = response.razorpay_payment_id
              installment.isPaidStatus = 1
              installment.paymentAmount = amt
                ; (installment.paymentMethod = 'Online'),
                  (installment.paymentDate = new Date())
            }
          })
          const objToPush = {
            admissionUserId: this.existingUser.admissionUserId,
            installmentId: installmentNo,
            payInstallmentDetails: {
              paymentDate: new Date(),
              paymentAmount: amt,
              paymentMethod: 'Online',
              paymentImage: ' ',
              paymentTransactionId: response.razorpay_payment_id,
            },
          }
          const res =
            await this.admissionUserRepositoryInstance.submitPaymentInstallment(
              objToPush
            )
          console.log(res)
          this.status = true
          alert('Payment Successfully Completed')
        },
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      paymentObject.on('payment.failed', function (response) {
        console.log(response.error)
      })
    },
  },
}
</script>
<style scoped src="./activityAdmissionForm.css">

</style>
