import Vue from "vue";
import App from "./App.vue";
import { router } from "./Router/"; // import router object
import { store } from "./Store/"; // import store (vuex) object
import Vuetify from "vuetify/lib";
import auth from "./Services/Firebase/auth";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VueMathjax from "vue-mathjax";
import VueApexCharts from "vue-apexcharts";
import OtpInput from "@bachdgvn/vue-otp-input";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueMathjax);
Vue.use(Chartkick.use(Chart));
Vue.use(Vuetify);
Vue.component("v-otp-input", OtpInput);
const vuetify = new Vuetify({});

Vue.config.productionTip = false;

new Vue({
  // use router object
  router,

  // use store (vuex) object
  store,

  vuetify,

  beforeCreate() {
    auth.init(this);
  },

  render: (h) => h(App),
}).$mount("#app");
