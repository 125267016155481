<template src='./activityEditSemesterTimetable.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'
import UserRepository from '../../Repository/User'
import SubjectRepository from '../../Repository/Subject'
import SemesterRepository from '../../Repository/Semester'
import TimetableRepository from '../../Repository/Timetable'
import ShiftRepository from '../../Repository/ShiftReference'
import SemesterUserRepository from '../../Repository/SemesterUser'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import { timeStringConverter } from '../../Services/Utils/DateTimeFormatter'
import { subjectTypes } from '../../Constants/Utils/Statics'
export default {
  name: 'activityEditSemesterTimetable',
  components: {
    inputContainer
  },
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  data () {
    return {
      subjectTypes: subjectTypes,
      loading: false,
      facultyIdToObjMapping: {},
      locationIdToObjMapping: {},
      subjectIdToObjMapping: {},
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedDivision: '',
      selectedFaculty: '',
      selectedFacultyId: '',
      selectedLocation: '',
      selectedLocationId: '',
      selectedSubject: '',
      selectedBatch: '',
      selectedTempSubject: '',
      selectedDay: '',
      selectedStartTimeAndEndTime: '',
      selectedShift: {},
      selectedSlots: [],
      selectedSubjectObj: {},
      selectedTempSlot: {},
      departments: [],
      courseYears: [],
      divisions: [],
      subjects: [],
      subjectsMapping: {},
      subjectsMapping2: {},
      tempBatchCount: 0,
      dataFetched: false,
      tempTimeSlots: [],
      tempWorkingDays: [],
      showAddingDialog: false,
      showEditingDialog: false,
      addingType: null,
      tempBatches: [],
      faculties: [],
      facultyNames: [],
      facultyNamesMapping: {},
      locations: [],
      locationNames: [],
      locationNamesMapping: {},
      subjectNamesMapping: {},
      facultiesIDtoNameMapping: {},
      locationsIDtoNameMapping: {},
      refresh: false
    }
  },
  created () {
    this.loading = true
    this.userRepositoryInstance = new UserRepository(this)
    this.shiftRepositoryInstance = new ShiftRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)

    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']

    if (this.tree) {
      this.departments = this.tree.map((child) => child.name)
    } else {
      this.departments = []
    }

    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.selectedDepartment = this.prop_selectedDepartment
      this.selectDepartment()
      this.selectedCourseYear = this.prop_selectedCourseYear
      this.selectCourseYear()
      this.selectedDivision = this.prop_selectedDivision
      this.selectDivision()
    }
    this.loading = false
  },
  methods: {
    selectDepartment () {
      this.loading = true
      this.courseYears = []
      this.divisions = []
      this.selectedCourseYear = ''
      this.selectedDivision = ''
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      this.courseYears = this.tree[indexOfDepartment].children.map((child) => child.name)
      this.refresh = false
      this.loading = false
    },
    selectCourseYear () {
      this.loading = true
      this.divisions = []
      this.selectedDivision = ''
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      const indexOfCourseYear = this.courseYears.indexOf(this.selectedCourseYear)
      this.divisions = this.tree[indexOfDepartment].children[indexOfCourseYear].children.map((child) => child.name)
      this.refresh = false
      this.loading = false
    },
    async selectDivision () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision
        }
        const shiftIdOfADivision = await this.semesterRepositoryInstance.getShiftOfADivisionOfASemester(objToPush)
        objToPush.shiftId = shiftIdOfADivision
        this.selectedShift = await this.shiftRepositoryInstance.getShiftDetailsOfAShift(objToPush)

        this.tempTimeSlots = []
        this.tempWorkingDays = []

        if (this.selectedShift.timeSlots) {
          for (let i = 0; i < this.selectedShift.timeSlots.length; i++) {
            this.tempTimeSlots.push(this.selectedShift.timeSlots[i].startTime + '__' + this.selectedShift.timeSlots[i].endTime)
          }
          this.tempWorkingDays = this.selectedShift.workingDays
        } else {
          showStatus('No shift assigned to division', 1500, 'error', this)
        }

        this.subjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)
        console.log(this.subjects)
        this.subjects.forEach((sub) => {
          // console.log(sub)
          this.subjectNamesMapping[sub.subjectId] = sub.subjectName
        })
        this.subjectsMapping = {}
        this.subjectsMapping2 = {}

        for (let i = 0; i < this.subjects.length; i++) {
          this.subjectIdToObjMapping[this.subjects[i].subjectId] = this.subjects[i]
          this.subjectsMapping[this.subjects[i].subjectId] = this.subjects[i]
          this.subjectsMapping2[this.subjects[i].groupName + ' | ' + this.subjects[i].subjectName + ' | ' + this.subjectTypes[this.subjects[i].subjectType]] = this.subjects[i]
        }
        this.tempBatches = []

        this.tempBatchCount = await this.semesterRepositoryInstance.getNumberOfBatchesOfADivisionInASemester(objToPush)
        this.dataFetched = true
        console.log(this.subjects, this.subjectIdToObjMapping, this.subjectsMapping2)
        this.processData()
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async processData () {
      this.loading = true
      try {
        let objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          withDuplications: true
        }
        console.log('before getting timetable of a division', objToPush)
        try {
          this.selectedSlots = await this.timetableRepositoryInstance.getTimetableOfADivisionInASemester(objToPush)
        } catch (err) {
          console.log(err)
          this.selectedSlots = []
        }
        this.slotsMapping = {}
        for (let i = 0; i < this.tempTimeSlots.length; i++) {
          if (!this.slotsMapping[this.tempTimeSlots[i]]) {
            this.slotsMapping[this.tempTimeSlots[i]] = {}
          }
          for (let j = 0; j < this.tempWorkingDays.length; j++) {
            this.slotsMapping[this.tempTimeSlots[i]][this.tempWorkingDays[j]] = {}
          }
        }

        objToPush = {
          instituteId: this.selectedInstitute
        }

        for (let i = 0; i < this.selectedSlots.length; i++) {
          const slot = this.selectedSlots[i]
          // this.slotsMapping[slot.startTime + '__' + slot.endTime][slot.day].push(slot)
          this.slotsMapping[slot.startTime + '__' + slot.endTime][slot.day][slot.timetableId] = slot
          this.refresh = false

          try {
            if (!this.facultiesIDtoNameMapping[slot.uId]) {
              const facultyName = await this.userRepositoryInstance.getFullNameOfUser({ uId: slot.uId })
              console.log(facultyName)
              this.facultiesIDtoNameMapping[slot.uId] = facultyName
            }
          } catch (err) {
            console.log(err)
          }

          if (slot.roomId) {
            try {
              objToPush.roomId = slot.roomId
              const roomName = await this.infrastructureReferenceRepositoryInstance.getRoomName(objToPush) || ''
              console.log(roomName)
              this.locationsIDtoNameMapping[slot.roomId] = roomName
            } catch (err) {
              console.log(err)
            }
          }
          this.refresh = true
          console.log(this.refresh)
        }
        this.refresh = true
        console.log(this.slotsMapping)
      } catch (err) {
        console.log(err)
      } finally {
        this.dataFetched = true
      }
      this.loading = false
    },
    async editSlot (item) {
      this.loading = true
      console.log(item)
      this.selectedTempSlot = item
      this.selectedTempSubject = this.subjectsMapping[item.subjectId].subjectName
      this.faculties = []
      this.selectedFaculty = ''
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: item.subjectId
      }
      let facultiesTeachingASubject = await this.subjectRepositoryInstance.getFacultiesTeachingASubjectOfACourseYearInASemester(objToPush)
      if (facultiesTeachingASubject.length === 0) {
        facultiesTeachingASubject = this.subjectsMapping[item.subjectId].faculties
      }
      console.log(facultiesTeachingASubject, objToPush, this.subjectsMapping[item.subjectId].faculties)
      this.facultyNames = []
      for (let i = 0; i < facultiesTeachingASubject.length; i++) {
        const facultyObject = {
          uId: facultiesTeachingASubject[i]
        }
        this.faculties.push(facultyObject)
        this.facultyIdToObjMapping[facultyObject.uId] = facultyObject
        this.facultyNamesMapping[facultyObject.name] = facultyObject.uId
        try {
          facultyObject.name = await this.userRepositoryInstance.getFullNameOfUser({ uId: facultiesTeachingASubject[i] })
          if (facultyObject.name) {
            this.facultiesIDtoNameMapping[facultyObject.uId] = facultyObject.name
            this.facultyNames.push(facultyObject.name)
          }
        } catch (err) {
          console.log(err)
        }
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          day: item.day,
          time: item.time
        }
        this.locations = await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(objToPush)
        console.log(this.locations)
      } catch (err) {
        console.log(err)
        this.locations = []
      } finally {
        this.locationNames = []
        for (let i = 0; i < this.locations.length; i++) {
          this.locationIdToObjMapping[this.locations[i].roomId] = this.locations[i]
          this.locationNamesMapping[this.locations[i].name] = this.locations[i].roomId
          this.locationsIDtoNameMapping[this.locations[i].roomId] = this.locations[i].roomName
          this.locationNames.push(this.locations[i].roomName)
        }
        this.showEditingDialog = true
      }
      this.loading = false
    },
    async saveEditingSlot () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          timetableId: this.selectedTempSlot.timetableId,
          day: this.selectedTempSlot.day,
          startTime: this.selectedTempSlot.startTime,
          endTime: this.selectedTempSlot.endTime,
          uId: this.facultyNamesMapping[this.selectedFaculty],
          roomId: this.locationNamesMapping[this.selectedLocation]
        }

        if (this.facultyNamesMapping[this.selectedFaculty] !== this.selectedTempSlot.uId) {
          await this.timetableRepositoryInstance.updateFacultyInATimetableSlot(objToPush)
        }
        if (this.locationNamesMapping[this.selectedLocation] !== this.selectedTempSlot.roomId) {
          await this.timetableRepositoryInstance.updateRoomInATimetableSlot(objToPush)
        }

        this.processData()
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async deleteSlot (item) {
      this.loading = true
      console.log(item)
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          timetableId: item.timetableId
        }

        await this.timetableRepositoryInstance.deleteTimetableSlot(objToPush)

        this.processData()
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async addSlot (startTimeAndEndTime, day) {
      this.loading = true
      this.selectedSubject = ''
      this.facultyNames = []
      this.selectedFaculty = []
      this.selectedLocation = ''
      console.log(startTimeAndEndTime, day)
      this.selectedDay = day
      this.selectedStartTimeAndEndTime = startTimeAndEndTime
      this.tempBatches = []
      this.selectedBatch = ''
      for (let i = 0; i < parseInt(this.tempBatchCount); i++) {
        this.tempBatches.push((i + 1))
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment
        }
        this.locations = await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(objToPush)
      } catch (err) {
        console.log(err)
        this.locations = []
      } finally {
        console.log(this.locations)
        this.locationNames = []
        for (let i = 0; i < this.locations.length; i++) {
          this.locationIdToObjMapping[this.locations[i].roomId] = this.locations[i]
          this.locationNamesMapping[this.locations[i].roomName] = this.locations[i].roomId
          this.locationsIDtoNameMapping[this.locations[i].roomId] = this.locations[i].roomName
          this.locationNames.push(this.locations[i].roomName)
        }
        this.showAddingDialog = true
      }
      this.loading = false
    },
    async selectSubject () {
      this.loading = true
      this.selectedSubjectObj = this.subjectsMapping2[this.selectedSubject]
      this.faculties = []
      this.selectedFaculty = ''
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.selectedSubjectObj.subjectId
        }
        let facultiesTeachingASubject = await this.subjectRepositoryInstance.getFacultiesTeachingASubjectOfACourseYearInASemester(objToPush)
        if (facultiesTeachingASubject.length === 0) {
          facultiesTeachingASubject = this.subjectsMapping[this.selectedSubjectObj.subjectId].faculties
        }
        console.log(facultiesTeachingASubject, this.selectedSubjectObj)
        this.facultyNames = []
        for (let i = 0; i < facultiesTeachingASubject.length; i++) {
          const facultyObject = {
            uId: facultiesTeachingASubject[i]
          }
          try {
            facultyObject.name = await this.userRepositoryInstance.getFullNameOfUser({ uId: facultiesTeachingASubject[i] })
            this.faculties.push(facultyObject)
            this.facultyIdToObjMapping[facultyObject.uId] = facultyObject
            this.facultyNamesMapping[facultyObject.name] = facultyObject.uId
            this.facultiesIDtoNameMapping[facultyObject.uId] = facultyObject.name
            this.facultyNames.push(facultyObject.name)
          } catch (err) {
            console.log(err)
          }
        }
      } catch (err) {
        console.log(err)
        this.faculties = []
      }
      this.loading = false
    },
    async saveAddedSlot () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          batch: this.selectedBatch || '',
          subjectId: this.selectedSubjectObj.subjectId,
          uId: this.facultyNamesMapping[this.selectedFaculty],
          day: this.selectedDay,
          roomId: this.locationNamesMapping[this.selectedLocation],
          startTime: this.selectedStartTimeAndEndTime.split('__')[0],
          endTime: this.selectedStartTimeAndEndTime.split('__')[1]
        }
        console.log(objToPush)
        await this.timetableRepositoryInstance.createTimetableSlot(objToPush)
        this.showAddingDialog = false
        this.selectedFaculty = ''
        this.selectedSubjectObj = {}
        this.selectedLocation = ''
        this.selectedDay = ''
        this.processData()
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    timeStringConverter (time) {
      return timeStringConverter(time)
    },
    resetVariables () {
    }
  }
}
</script>
<style src='./activityEditSemesterTimetable.css' scoped>
</style>
