<template src="./activityAdmissionUser.html"> </template>
<script>
import UserRepository from '../../Repository/User'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SemesterUserRepository from '../../Repository/SemesterUser'
export default {
  name: 'activityAdmissionUser',
  components: {
    inputContainer
  },
  props: ['prop_user','prop_admission'],
  data () {
    return {
      admissionList:[],
      selectedAdmission:'',
      showToStudents: false,
      loading: false,
      user: {},
      userAuths: [],
      fullName: '',
      subjects: ['Accounts', 'Maths', 'Eco', 'OCM', 'SP', 'German', 'French']
    }
  },
  created () {
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'liveData/get_admissionSemester'
    ]
    this.admissionList.push(this.prop_admission)
    this.selectedAdmission=this.prop_admission
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.user = this.prop_user
      this.loading = false
      try {
        this.userAuths = await this.userRepositoryInstance.getUserAuthenticationByEmail(
          {
            email: this.user.emailId
          }
        )
      } catch (err) {
        console.log(err)
        this.userAuths = []
      }
      console.log(this.userAuths)
      // const objToPush = {
      //   instituteId: this.selectedInstitute
      // }

      // try {
      //   const instituteUsers = await this.instituteUserRepositoryInstance.getUsersOfAnInstitute(objToPush)

      //   for (let i = 0; i < instituteUsers.length; i++) {
      //     this.users.push({
      //       fullName: '',
      //       instituteProvidedId: instituteUsers[i].instituteProvidedId,
      //       uId: instituteUsers[i].uId
      //     })
      //   }
      // } catch (err) {
      //   console.log(err)
      // }

      // this.loading = false
    },

    parseDate (dateOfBirth) {
      const parsedDate = new Date(dateOfBirth)
      return (
        parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getUTCDay()).slice(-2)
      )
    },

    removeFromArray (item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },

    dateConverter (date) {
      date = new Date(date)
      const dateString = date.toISOString()
      return dateString
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/')
    },

    async addUserDetails () {
      const nameArr = this.user.fullName.split(' ')
      let firstName = ''
      let middleName = ''
      let lastName = ''
      if (nameArr.length === 3) {
        firstName = nameArr[0]
        middleName = nameArr[1]
        lastName = nameArr[2]
      } else {
        firstName = nameArr[0]
        middleName = ''
        lastName = nameArr[1]
      }
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        email: this.user.emailId,
        mobileNumber: this.user.mobileNumber,
        parentName: this.user.parentFullName,
        parentEmailId: this.user.parentEmailId,
        parentMobileNumber: this.user.parentMobileNumber,
        address: this.user.address
      }

      try {
        const res = await this.admissionUserRepositoryInstance.addUserDetails(
          objToPush
        )
        this.userAuths = await this.userRepositoryInstance.getUserAuthenticationByEmail(
          {
            email: this.user.emailId
          }
        )
      } catch (err) {
        console.log(err)
        alert('Could not connect to server!')
      }
    },

    async createSemesterUser () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester,
        uId: this.userAuths[0].uId,
        department: this.prop_user.department,
        courseYear: this.prop_user.courseYear,
        division: this.prop_user.division,
        batch: '',
        rollNumber: '',
        examSeatNumber: '',
        subjects: this.prop_user.batches.map(batch => {
          return batch.subjectId
        })
      }
      try {
        const res = await this.semesterUserRepositoryInstance.createSemesterUser(
          objToPush
        )
        this.$router.go(-1)
      } catch (err) {
        console.log(err)
        alert('Could not connect to server!')
      }
    },

    async AddPaymentStatus(payments){
      this.user.payments.forEach(admissionPayment=>{
        if(admissionPayment.dueDate == payments.dueDate){
           admissionPayment.status='paid'
        }
      });
      this.updateAdmissionUserPayment()
    },
    async updateAdmissionUserPayment(){
      let objToPush={
        admissionUserId:this.user.admissionUserId,
        payments:this.user.payments
      }
      try {
        const res = await this.admissionUserRepositoryInstance.updateAdmissionUserPayment(
          objToPush
        )
      } catch (err) {
        console.log(err)
        alert('Could not connect to server!')
      }
    }
  }
}
</script>
<style src="./activityAdmissionUser.css" scoped></style>
