// this is the main class of the router which will contain all the routes

// Import statements to get all required libraries
import Vue from 'vue'
import Router from 'vue-router' // library for creating Router object
import validateTravel from './routerGuard'

// Import statements to get all required components for routing
import activityWelcome from '../Activities/activityWelcome/activityWelcome.vue'
import admissionPlatform from '../Activities/activityWelcome/activityWelcome.vue'
import activityHome from '../Activities/activityHome/activityHome'
import activitySetup from '../Activities/activitySetup/activitySetup'
import activityProfile from '../Activities/activityProfile/activityProfile'
import activitySelectInstituteNSemester from '../Activities/activitySelectInstituteNSemester/activitySelectInstituteNSemester'
import activityInstitutesBasicDetails from '../Activities/activityInstitutesBasicDetails/activityInstitutesBasicDetails'
import activityReferenceAcademicDetails from '../Activities/activityReferenceAcademicDetails/activityReferenceAcademicDetails'
import activityReferenceInfrastructure from '../Activities/activityReferenceInfrastructure/activityReferenceInfrastructure'
import activityReferenceShiftDetails from '../Activities/activityReferenceShiftDetails/activityReferenceShiftDetails'
import activityManageRoles from '../Activities/activityManageRoles/activityManageRoles'
import activityViewSemesters from '../Activities/activityViewSemesters/activityViewSemesters'
import activityCreateSemester from '../Activities/activityCreateSemester/activityCreateSemester'
import activityEditSemester from '../Activities/activityEditSemester/activityEditSemester'
import activityDirections from '../Activities/activityDirections/activityDirections'
import activityCreateInstituteOrUser from '../Activities/activityCreateInstituteOrUser/activityCreateInstituteOrUser'
import activityDashboard from '../Activities/activityDashboard/activityDashboard'
import activityArrangeLecture from '../Activities/activityArrangeLecture/activityArrangeLecture'
import activityManageHoliday from '../Activities/activityManageHoliday/activityManageHoliday'
import activityAttendanceAnalysis from '../Activities/activityAttendanceAnalysis/activityAttendanceAnalysis'
import activitySyllabusTracking from '../Activities/activitySyllabusTracking/activitySyllabusTracking'
import activityFeedbackAnalysis from '../Activities/activityFeedbackAnalysis/activityFeedbackAnalysis'
import activityNotifications from '../Activities/activityNotifications/activityNotifications'
import activityManageUsers from '../Activities/activityManageUsers/activityManageUsers'
import activityTimeline from '../Activities/activityTimeline/activityTimeline'
import activityManageSMS from '../Activities/activityManageSMS/activityManageSMS'
import activitySMSUsers from '../Activities/activitySMSUsers/activitySMSUsers'
import activityTransferUsers from '../Activities/activityTransferUsers/activityTransferUsers'
import activityManageTopicFeedbackTemplates from '../Activities/activityManageTopicFeedbackTemplates/activityManageTopicFeedbackTemplates'
import activityTopicFeedbackAnalysis from '../Activities/activityTopicFeedbackAnalysis/activityTopicFeedbackAnalysis'
import activityAdmission from '../Activities/activityAdmission/activityAdmission'
import activityAdmissionUser from '../Activities/activityAdmissionUser/activityAdmissionUser'
import activityAdmissionNewUsers from '../Activities/activityAdmissionNewUsers/activityAdmissionNewUsers'
import activityAdmissionForm from '../Activities/activityAdmissionForm/activityAdmissionForm'
import activityExistingAdmissionForm from '../Activities/activityExistingAdmissionForm/activityExistingAdmissionForm'
import activityFeeReceipt from '../Activities/activityFeeReceipt/activityFeeReceipt'

// NBA ACTIVITIES
import activityNBAModule from '../Activities/activityNBAModule/activityNBAModule'
import activityNBAextended from '../Activities/activityNBAextended/activityNBAextended'
Vue.use(Router) // Using Router object in Vue

export const router = new Router({
  // Exporting a constant object 'router' which is of Router type
  mode: 'history', // using history mode to maintain history stack of routes
  base: process.env.BASE_URL, // using 'base' variable as a baseURL
  routes: [
    // this is the array which contains all the paths/routes
    {
      // this is the default route which redirects to route of 'redirect' property
      path: '/',
      redirect: '/activityWelcome',
    },
    {
      // this is the object which defines route and name for component 'activityWelcome'
      path: '/activityWelcome', // route of component
      name: 'activityWelcome', // name of component
      component: activityWelcome, // object of component
      meta: { guestOnly: true },
    },
    {
      // this is the object which defines route and name for component 'activityWelcome'
      path: "/admissionPlatform/:instituteShortCode", // route of component
      name: "admissionPlatform", // name of component
      component: admissionPlatform, // object of component
      meta: { guestOnly: true }
    },
    {
      path: '/activitySetup',
      name: 'activitySetup',
      component: activitySetup,
      meta: { guestOnly: true },
    },
    {
      path: '/activitySelectInstituteNSemester',
      name: 'activitySelectInstituteNSemester',
      component: activitySelectInstituteNSemester,
      meta: { requireAuth: true },
    },
    {
      path: '/activityUser',
      name: 'activityUser',
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true },
    },
    {
      path: '/activityCreateInstitute',
      name: 'acitivityCreateInstitute',
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true },
    },
    {
      path: '/activityHome',
      // name: 'activityHome',
      component: activityHome,
      meta: { requireAuth: true },
      children: [
        {
          path: '/',
          redirect: '/activityDirections',
        },
        {
          path: '/activityTimeline',
          name: 'activityTimeline',
          component: activityTimeline,
          meta: { requireAuth: true },
        },
        {
          path: '/activityDirections',
          name: 'activityDirections',
          component: activityDirections,
          meta: { requireAuth: true },
        },
        {
          path: '/activityProfile',
          name: 'activityProfile',
          component: activityProfile,
          meta: { requireAuth: true },
        },
        {
          path: '/activityInstitutesBasicDetails',
          name: 'activityInstitutesBasicDetails',
          component: activityInstitutesBasicDetails,
          meta: { requireAuth: true },
        },
        {
          path: '/activityReferenceAcademicDetails',
          name: 'activityReferenceAcademicDetails',
          component: activityReferenceAcademicDetails,
          meta: { requireAuth: true },
        },
        {
          path: '/activityReferenceInfrastructure',
          name: 'activityReferenceInfrastructure',
          component: activityReferenceInfrastructure,
          meta: { requireAuth: true },
        },
        {
          path: '/activityReferenceShiftDetails',
          name: 'activityReferenceShiftDetails',
          component: activityReferenceShiftDetails,
          meta: { requireAuth: true },
        },
        {
          path: '/activityManageRoles',
          name: 'activityManageRoles',
          component: activityManageRoles,
          meta: { requireAuth: true },
        },
        {
          path: '/activityViewSemesters',
          name: 'activityViewSemesters',
          component: activityViewSemesters,
          meta: { requireAuth: true },
        },
        {
          path: '/activityCreateSemester',
          name: 'activityCreateSemester',
          component: activityCreateSemester,
          meta: { requireAuth: true },
        },
        {
          path: '/activityEditSemester',
          name: 'activityEditSemester',
          component: activityEditSemester,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: '/activityDashboard',
          name: 'activityDashboard',
          component: activityDashboard,
          meta: { requireAuth: true },
        },
        {
          path: '/activityArrangeLecture',
          name: 'activityArrangeLecture',
          component: activityArrangeLecture,
          meta: { requireAuth: true },
        },
        {
          path: '/activityManageHoliday',
          name: 'activityManageHoliday',
          component: activityManageHoliday,
          meta: { requireAuth: true },
        },
        {
          path: '/activityAttendanceAnalysis',
          name: 'activityAttendanceAnalysis',
          component: activityAttendanceAnalysis,
          meta: { requireAuth: true },
        },
        {
          path: '/activitySyllabusTracking',
          name: 'activitySyllabusTracking',
          component: activitySyllabusTracking,
          meta: { requireAuth: true },
        },
        {
          path: '/activityFeedbackAnalysis',
          name: 'activityFeedbackAnalysis',
          component: activityFeedbackAnalysis,
          meta: { requireAuth: true },
        },
        {
          path: '/activityNotifications',
          name: 'activityNotifications',
          component: activityNotifications,
          meta: { requireAuth: true },
        },
        {
          path: '/activityManageUsers',
          name: 'activityManageUsers',
          component: activityManageUsers,
          meta: { requireAuth: true },
        },
        {
          path: '/activityManageSMS',
          name: 'activityManageSMS',
          component: activityManageSMS,
          meta: { requireAuth: true },
        },
        {
          path: '/activitySMSUsers',
          name: 'activitySMSUsers',
          props: true,
          component: activitySMSUsers,
          meta: { requireAuth: true },
        },
        {
          path: '/activityTransferUsers',
          name: 'activityTransferUsers',
          props: true,
          component: activityTransferUsers,
          meta: { requireAuth: true },
        },
        {
          path: '/activityManageTopicFeedbackTemplates',
          name: 'activityManageTopicFeedbackTemplates',
          component: activityManageTopicFeedbackTemplates,
          meta: { requireAuth: true },
        },
        {
          path: '/activityTopicFeedbackAnalysis',
          name: 'activityTopicFeedbackAnalysis',
          component: activityTopicFeedbackAnalysis,
          meta: { requireAuth: true },
        },
        {
          path: '/activityNBAModule',
          name: 'activityNBAModule',
          component: activityNBAModule,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: '/activityNBAextended',
          name: 'activityNBAextended',
          component: activityNBAextended,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: '/activityAdmission',
          name: 'activityAdmission',
          component: activityAdmission,
          meta: { requireAuth: true },
        },
        {
          path: '/activityAdmissionUser',
          name: 'activityAdmissionUser',
          component: activityAdmissionUser,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: '/activityAdmissionNewUsers',
          name: 'activityAdmissionNewUsers',
          props: true,
          component: activityAdmissionNewUsers,
          meta: { requireAuth: true },
        },
        {
          path: '/activityAdmissionForm',
          name: 'activityAdmissionForm',
          component: activityAdmissionForm,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: '/activityExistingAdmissionForm',
          name: 'activityExistingAdmissionForm',
          component: activityExistingAdmissionForm,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: '/activityFeeReceipt',
          name: 'activityFeeReceipt',
          component: activityFeeReceipt,
          props: true,
          meta: { requireAuth: true },
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  validateTravel(to, from, next)
})
