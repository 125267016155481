<template src ='./activityEditSemesterHOD.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
export default {
  name: 'activityEditSemesterHOD',
  components: {
    smallButton,
    inputContainer
  },
  props: [
    'prop_selectedDepartment',
    'tree'
  ],
  data () {
    return {
      selectedInstitute: '',
      selectedSemester: '',
      tableItems: [],
      departments: [],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'HOD',
          value: 'hod'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      selectedDepartment: '',
      assignHODDialog: false,
      faculties: [],
      headers2: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      search: ''
    }
  },
  created () {
    this.selectedInstitute = ''
    this.selectedSemester = ''
    if (this.tree) {
      for (let i = 0; i < this.tree.length; i++) {
        this.departments.push(this.tree[i].name)
      }
    } else {
      this.departments = []
    }
    for (let i = 0; i < this.departments.length; i++) {
      // Call API to get hod for departmet
      const hod = ''
      this.tableItems.push({
        department: this.departments[i],
        hod: hod
      })
    }
  },
  methods: {
    editHOD (obj) {
      console.log(obj)
      this.selectedDepartment = obj.department
      // TODO: Call API to get faculties for a department
      // { name: '', uid: ''}
      this.faculties = []
      this.assignHODDialog = true
    },
    assignHOD (obj) {
      const objToPush = {
        instituteID: this.selectedInstitute,
        semId: this.selectedSemester,
        department: this.selectedDepartment,
        hod: obj.uid
      }
      console.log(objToPush)
      // TODO: Call API to allocate HOD
      this.assignHODDialog = false
    }
  }
}
</script>
<style src='./activityEditSemesterHOD.css' scoped>
</style>
