export default {
  createShift: '/shift/createShift',
  getShift: '/shift/getShift',
  getShifts: '/shift/getShifts',
  getShiftNames: '/shift/getShiftNames',
  getShiftName: '/shift/getShiftName',
  updateShiftName: '/shift/updateShiftName',
  updateWorkingDays: '/shift/updateWorkingDays',
  updateTimeSlots: '/shift/updateTimeSlots',
  deleteShift: '/shift/deleteShift'
}
