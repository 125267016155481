<template src="./activityTimeline.html"> </template>

<script>
import SemesterUserRepository from '../../Repository/SemesterUser'
import auth from '../../Services/Firebase/auth'
import SemesterRepository from '../../Repository/Semester'
import UserRepository from '../../Repository/User'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import { convertToISTTime } from '../../Services/Utils/DateTimeFormatter'
import PostsRepository from '../../Repository/Posts'

var firestorage = null
export default {
  name: 'activityTimeline',
  components: {
    inputContainer
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        'state_changed',
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          )
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true

            this.downloadURLs.push({
              downloadURL: downloadURL,
              isMedia: ['image', 'video'].includes(this.fileCurrentlyUploading.type.split('/')[0])
            })
            this.uploadCount++
            if (this.uploadCount === this.files.length) {
              this.uploadPost()
            } else {
              this.progressUpload = 0
              this.upload(this.uploadCount)
            }
          })
        }
      )
    }
  },
  data () {
    return {
      userData: {},
      posts: [],
      files: [],
      files2: [],
      tempTitle: '',
      tempDescription: '',
      uploadTask: '',
      uploadEnd: false,
      uploading: false,
      showAddingDialog: false,
      showToStudents: false,
      showToFaculties: false,
      selectedDepartment: '',
      selectedDepartment2: '',
      departments: [],
      selectedCourseYear: '',
      courseYears: [],
      selectedDivision: '',
      divisions: [],
      selectedInstitute: '',
      selectedSemester: {},
      academicDataTree: {},
      isImportant: false,
      selectedFacultyCriteria: [],
      selectedStudentCriteria: [],
      fileCurrentlyUploading: ''
    }
  },
  created () {
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.postsRepositoryInstance = new PostsRepository(this)
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    // this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.$store.commit('liveData/set_selectedActivityName', 'Timeline')
    this.userData = this.$store.getters['user/get_userData']
    console.log(this.userData)
    this.getMasterDataTree()
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.posts = []
      try {
        const objToPush = {
          uId: this.userData.uId
        }
        this.posts = await this.postsRepositoryInstance.getPosts(objToPush)
        this.posts.sort().reverse()
        console.log(this.posts)
      } catch (err) {
        console.log(err)
        this.posts = []
      }

      const batchSize = 100

      const promises = []
      for (let i = 0; i < this.posts.length; i += batchSize) {
        promises.push(
          this.getFullNamesOfUsers(
            i,
            i + batchSize < this.posts.length
              ? i + batchSize
              : this.posts.length
          )
        )
      }

      await Promise.allSettled(promises)
      this.posts.forEach(post => {
        post.count = 0
      })
      this.posts = this.posts.slice()
      // Call API to get posts for faculty
      // this.posts = [
      //   {
      //     postId: 0,
      //     name: 'Viraj Lagad',
      //     postTime: new Date().toISOString(),
      //     title: 'Title Of the post',
      //     description: 'description Of the post description Of the post description Of the post description Of the post',
      //     count: 0,
      //     media: [
      //       // {
      //       // type: 0, // 0 for image, 1 for video, 2 for document
      //       // link: 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/images%2Fafe80a58-4970-4221-80db-bd2477fef561.jpg?alt=media&token=5ee8041a-ce12-4d2e-b898-f745d2d51228'
      //       // },
      //       {
      //         type: 1,
      //         link: 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/lectureVideos%2F835e0776-6cda-44cc-99a6-19d9c7cd1c68%2F1597003141662________Pexels%20Videos%201389615.mp4?alt=media&token=7a282788-e207-4c76-b42d-3f7d5aef9904'
      //       }
      //     ],
      //     attachments: [
      //       {
      //         fileName: 'file name.pdf',
      //         link: 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/lectureVideos%2F835e0776-6cda-44cc-99a6-19d9c7cd1c68%2F1597003141662________Pexels%20Videos%201389615.mp4?alt=media&token=7a282788-e207-4c76-b42d-3f7d5aef9904'
      //       }
      //     ]
      //   },
      //   {
      //     postId: 1,
      //     name: 'Viraj Lagad',
      //     postTime: new Date().toISOString(),
      //     title: 'Title Of the post',
      //     description: 'description Of the post description Of the post description Of the post description Of the post',
      //     count: 0,
      //     media: [
      //       {
      //         type: 0, // 0 for image, 1 for video, 2 for document
      //         link: 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/images%2Ffeatured-area-top.jpg?alt=media&token=8d24ba8e-de01-4fd1-b2b3-8c5e47de6f87'
      //       },
      //       {
      //         type: 1,
      //         link: 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/lectureVideos%2F835e0776-6cda-44cc-99a6-19d9c7cd1c68%2F1597003141662________Pexels%20Videos%201389615.mp4?alt=media&token=7a282788-e207-4c76-b42d-3f7d5aef9904'
      //       }
      //     ],
      //     attachments: [
      //       {
      //         fileName: 'file name.pdf',
      //         link: 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/images%2Ffeatured-area-top.jpg?alt=media&token=8d24ba8e-de01-4fd1-b2b3-8c5e47de6f87'
      //       }
      //     ]
      //   }
      // ]
    },
    async getFullNamesOfUsers (firstIndex, lastIndex) {
      console.log(firstIndex, lastIndex)
      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.posts[i].postedById)
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        )

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(
            elem => elem.uId === this.posts[i].postedById
          )
          this.posts[i].nameOfPostee = user ? user.fullName : ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getFullName (index) {
      try {
        this.posts[
          index
        ].nameOfPostee = await this.userRepositoryInstance.getFullNameOfUser({
          uId: this.posts[index].postedById
        })
      } catch (err) {
        console.log(err)
        this.posts[index].nameOfPostee = ''
      }
    },
    pushFiles () {
      console.log(this.files)
      this.files2.forEach(file => {
        this.files.push(file)
      })
      this.files2 = []
      this.showAddingDialog = false
    },
    removeFile (i) {
      this.files = arrayOps.removeFromArray(this.files, i)
    },
    filesChanged (e) {
      console.log(e)
    },
    getFileName (url) {
      const decodedUrl = decodeURIComponent(url)
      const lastIndexOfDelimiter = decodedUrl.lastIndexOf('________')
      const indexOfQueryParams = decodedUrl.lastIndexOf('?alt')

      return decodedUrl.substring(
        lastIndexOfDelimiter + '________'.length,
        indexOfQueryParams
      )
    },
    async getMasterDataTree () {
      try {
        const objToPush = {
          uId: this.userData.uId,
          instituteId: this.selectedInstitute
        }
        const semesterObject = await this.semesterUserRepositoryInstance.getCurrentSemesterUserForAUser(
          objToPush
        )
        this.selectedSemester = semesterObject
      } catch (err) {
        console.log(err)
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        console.log(objToPush)
        const semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(
          objToPush
        )
        this.constructAcademicDataTree(semestersList)
      } catch (err) {
        console.log(err)
      }
    },
    async constructAcademicDataTree (semestersList) {
      const academicDataTree = {}

      semestersList.forEach(sem => {
        if (!academicDataTree[sem.department]) {
          academicDataTree[sem.department] = {}
        }
        if (!academicDataTree[sem.department][sem.courseYear]) {
          academicDataTree[sem.department][sem.courseYear] = []
        }
        academicDataTree[sem.department][sem.courseYear].push(sem.division)
        this.departments = ['All Departments']
        this.departments = this.departments.concat(
          Object.keys(academicDataTree)
        )
        this.academicDataTree = academicDataTree
      })
    },
    selectDepartment () {
      this.courseYears = []
      this.selectedCourseYear = ''
      this.divisions = []
      this.selectedDivision = ''
      this.courseYears = ['All Course Years']
      if (this.academicDataTree[this.selectedDepartment]) {
        this.courseYears = this.courseYears.concat(
          Object.keys(this.academicDataTree[this.selectedDepartment])
        )
      }
    },
    selectCourseYear () {
      this.divisions = []
      this.selectedDivision = ''
      this.divisions = ['All Divisions']
      if (
        this.academicDataTree[this.selectedDepartment][this.selectedCourseYear]
      ) {
        this.divisions = this.divisions.concat(
          this.academicDataTree[this.selectedDepartment][
            this.selectedCourseYear
          ]
        )
      }
    },
    dismissStudentCriteria () {
      this.resetStudentCriteria()
      this.showToStudents = false
    },
    addStudentCriteria () {
      if (
        this.selectedDepartment === 'All Departments' ||
        this.selectedCourseYear === 'All Course Years' ||
        this.selectedDivision === 'All Divisions'
      ) {
        this.addAllStudentCriteria()
      } else {
        const criteriaToInsert = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision
        }
        if (
          this.selectedStudentCriteria.filter(
            elem =>
              elem.department === criteriaToInsert.department &&
              elem.courseYear === criteriaToInsert.courseYear &&
              elem.division === criteriaToInsert.division
          ).length === 0
        ) {
          this.selectedStudentCriteria.push(criteriaToInsert)
        }
      }
      this.resetStudentCriteria()
      this.showToStudents = false
    },
    addAllStudentCriteria () {
      if (this.selectedDepartment === 'All Departments') {
        this.selectedStudentCriteria = []
        Object.keys(this.academicDataTree).forEach(department => {
          Object.keys(this.academicDataTree[department]).forEach(courseYear => {
            this.academicDataTree[department][courseYear].forEach(division => {
              this.selectedStudentCriteria.push({
                department: department,
                courseYear: courseYear,
                division: division
              })
            })
          })
        })
      } else if (this.selectedCourseYear === 'All Course Years') {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          elem => elem.department !== this.selectedDepartment
        )
        Object.keys(this.academicDataTree[this.selectedDepartment]).forEach(
          courseYear => {
            this.academicDataTree[this.selectedDepartment][courseYear].forEach(
              division => {
                this.selectedStudentCriteria.push({
                  department: this.selectedDepartment,
                  courseYear: courseYear,
                  division: division
                })
              }
            )
          }
        )
      } else if (this.selectedDivision === 'All Divisions') {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          elem =>
            elem.department !== this.selectedDepartment ||
            elem.courseYear !== this.selectedCourseYear
        )
        this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
        ].forEach(division => {
          this.selectedStudentCriteria.push({
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: division
          })
        })
      }
    },
    uploadFiles () {
      this.loading = true
      this.downloadURLs = []
      this.uploadCount = 0
      console.log(this.files)
      if (this.files.length === 0) {
        this.uploadPost()
      } else {
        // this.files.forEach(file => {
        //   this.upload(file)
        // })
        this.upload(this.uploadCount)
      }
    },
    upload (fileNumber) {
      console.log('uploading')
      this.fileName = this.files[fileNumber].name
      this.uploading = true
      console.log(this.fileName)
      this.fileCurrentlyUploading = this.files[fileNumber]
      this.uploadTask = firestorage
        .ref(
          'posts/' +
            this.userData.uId +
            '/' +
            new Date().valueOf().toString() +
            '________' +
            this.files[fileNumber].name
        )
        .put(this.files[fileNumber])
    },
    async uploadPost () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        title: this.tempTitle,
        description: this.tempDescription,
        isImportant: this.isImportant,
        postedById: this.userData.uId,
        dateOfPost: convertToISTTime(new Date()).toISOString(),
        images: this.downloadURLs
          .filter(url => url.isMedia)
          .map(url => url.downloadURL),
        otherMedia: this.downloadURLs
          .filter(url => !url.isMedia)
          .map(url => url.downloadURL),
        criteria: {
          forFaculties: this.selectedFacultyCriteria.length > 0,
          forStudents: this.selectedStudentCriteria.length > 0,
          facultyCriteria: this.selectedFacultyCriteria,
          studentCriteria: this.selectedStudentCriteria
        }
      }
      try {
        await this.postsRepositoryInstance.createPost(objToPush)
        this.fetchData()
      } catch (err) {
        console.log(err)
      }
      console.log(objToPush)
    },
    getFileType (fileUrl) {
      const intermediatePath = fileUrl.split('?alt=media')[0]
      return intermediatePath.substring(
        intermediatePath.lastIndexOf('.'),
        intermediatePath.length
      )
    },
    resetStudentCriteria () {
      this.selectedDepartment = ''
      this.selectedCourseYear = ''
      this.selectedDivision = ''
    },
    dismissFacultyCriteria () {
      this.selectedDepartment2 = ''
      this.showToFaculties = false
    },
    addFacultyCriteria () {
      if (this.selectedDepartment2 === 'All Departments') {
        this.selectedFacultyCriteria = []
        Object.keys(this.academicDataTree).forEach(department => {
          this.selectedFacultyCriteria.push({
            department: department
          })
        })
      } else if (
        !this.selectedFacultyCriteria.includes(this.selectedDepartment2)
      ) {
        this.selectedFacultyCriteria.push({
          department: this.selectedDepartment2
        })
      }
      this.selectedDepartment2 = ''
      this.showToFaculties = false
    },
    removeStudentCriteria (index) {
      this.selectedStudentCriteria = arrayOps.removeFromArray(
        this.selectedStudentCriteria,
        index
      )
    },
    removeFacultyCriteria (index) {
      this.selectedFacultyCriteria = arrayOps.removeFromArray(
        this.selectedFacultyCriteria,
        index
      )
    }
  }
}
</script>

<style src="./activityTimeline.css"></style>
