<template src='./activityCreateSemester.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'
import arrayOps from '../../Services/Utils/arrayOps'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import SemesterRepository from '../../Repository/Semester'
export default {
  name: 'activityCreateSemester',
  components: {
    inputContainer
  },
  data () {
    return {
      loading: false,
      semesterName: '',
      departments: [],
      selectedDepartment: '',
      tempDepartment: '',
      showRemoveDepartmentDialog: false,
      courseYears: [],
      selectedCourseYear: '',
      tempCourseYear: '',
      showRemoveCourseYearDialog: false,
      divisions: [],
      academicDetails: [],
      selectedInstitute: '',
      academicTree: {},
      tempDivision: '',
      showRemoveDivisionDialog: false
    }
  },
  created () {
    this.loading = true
    this.$store.commit('liveData/set_selectedActivityName', 'Create Semester')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.fetchData()
    this.loading = false
  },
  methods: {
    async fetchData () {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.academicDetails = await this.academicReferenceRepositoryInstance.getDivisionsOfAnInstitute(objToPush)
        console.log(this.academicDetails.length)
        this.processData()
      } catch (err) {
        console.log(err)
        this.loading = false
      }
      this.loading = false
    },
    processData () {
      this.loading = true
      for (let i = 0; i < this.academicDetails.length; i++) {
        const obj = this.academicDetails[i]
        if (!this.academicTree[obj.department]) {
          this.academicTree[obj.department] = {}
        }
        if (!this.academicTree[obj.department][obj.courseYear]) {
          this.academicTree[obj.department][obj.courseYear] = []
        }
        this.academicTree[obj.department][obj.courseYear].push(obj.division)
      }
      this.departments = Object.keys(this.academicTree)
      this.loading = false
    },
    selectDepartment (department) {
      if (this.selectedDepartment !== department) {
        this.selectedCourseYear = ''
        this.courseYears = []
        this.divisions = []
        this.selectedDepartment = department
        this.courseYears = Object.keys(this.academicTree[department])
      }
    },
    startRemovingDepartment (department) {
      this.showRemoveDepartmentDialog = true
      this.tempDepartment = department
    },
    cancelRemoveDepartment () {
      this.tempDepartment = ''
      this.showRemoveDepartmentDialog = false
    },
    removeDepartment () {
      delete this.academicTree[this.tempDepartment]
      this.academicDetails = this.academicDetails.filter((elem) => elem.department !== this.tempDepartment)
      this.tempDepartment = ''
      this.selectedDepartment = ''
      this.divisions = []
      this.courseYears = []
      this.departments = Object.keys(this.academicTree)
      this.showRemoveDepartmentDialog = false
    },
    selectCourseYear (courseYear) {
      if (this.selectedCourseYear !== courseYear) {
        this.divisions = []
        this.selectedCourseYear = courseYear
        this.divisions = this.academicTree[this.selectedDepartment][courseYear]
      }
    },
    startRemovingCourseYear (courseYear) {
      this.showRemoveCourseYearDialog = true
      this.tempCourseYear = courseYear
    },
    cancelRemoveCourseYear () {
      this.tempCourseYear = ''
      this.showRemoveCourseYearDialog = false
    },
    removeCourseYear () {
      delete this.academicTree[this.selectedDepartment][this.tempCourseYear]
      this.academicDetails = this.academicDetails.filter((elem) => elem.department !== this.selectedDepartment || elem.courseYear !== this.tempCourseYear)
      this.courseYears = Object.keys(this.academicTree[this.selectedDepartment])
      this.divisions = []
      this.tempCourseYear = ''
      this.selectedCourseYear = ''
      this.showRemoveCourseYearDialog = false
    },
    startRemovingDivision (division) {
      this.tempDivision = division
      this.showRemoveDivisionDialog = true
    },
    cancelRemoveDivision () {
      this.tempDivision = ''
      this.showRemoveDivisionDialog = false
    },
    removeDivision () {
      this.divisions = arrayOps.removeFromArray(this.divisions, this.divisions.indexOf(this.tempDivision))
      this.academicTree[this.selectedDepartment][this.selectedCourseYear] = arrayOps.removeFromArray(this.academicTree[this.selectedDepartment][this.selectedCourseYear], this.academicTree[this.selectedDepartment][this.selectedCourseYear].indexOf(this.tempDivision))
      this.academicDetails = this.academicDetails.filter((elem) => elem.department !== this.selectedDepartment || elem.courseYear !== this.selectedCourseYear || elem.division !== this.tempDivision)
      this.tempDivision = ''
      this.showRemoveDivisionDialog = false
    },
    async createSemester () {
      this.loading = true
      if (this.semesterName.trim() === '') {
        // handle semester name is empty error
        console.log('sem name is empty.')
      } else {
        console.log(this.academicDetails)
        const arrayOfObjectsToPush = this.academicDetails.map((academicDetailsObject) => {
          return {
            instituteId: this.selectedInstitute,
            semName: this.semesterName,
            department: academicDetailsObject.department,
            courseYear: academicDetailsObject.courseYear,
            division: academicDetailsObject.division
          }
        })

        try {
          await this.semesterRepositoryInstance.createSemester(arrayOfObjectsToPush)
          this.$store.dispatch('liveData/set_selectedActivity', 7)
          this.$router.push('/activityViewSemesters')
        } catch (err) {
          console.log(err)
          showStatus('Error in creating semester', 1000, 'error', this)
        }
      }
      this.loading = false
    }
  }
}
</script>
<style src='./activityCreateSemester.css' scoped>
</style>
