export default {
  createAdmissionUser: '/admissionUser/createAdmissionUserAuth',
  loginAdmissionUser: '/admissionUser/admissionUserLogin',
  createUser: '/users/createUser',
  addEmail: '/auth/createUserAuthenticationWithEmail',
  addAndUpdateUserName:'/auth/createUserAuthenticationWithUserName',
  addPhone: '/auth/createUserAuthenticationWithPhone',
  login: '/auth/login',
  getUserProfile: '/users/getUser',
  getUserAuthentication: '/auth/getUserAuthenticationsOfAUser',
  getUserAuthenticationByEmail: '/auth/getUserAuthenticationByEmail',
  getFullNameOfUser: '/users/getFullNameOfUser',
  getFullNameOfUsers: '/users/getFullNameOfUsers',
  getParentsOfAUser: '/users/getParentsOfAUser',
  updateFirstName: '/users/updateFirstName',
  updateMiddleName: '/users/updateMiddleName',
  updateLastName: '/users/updateLastName',
  updateDateOfBirth: '/users/updateDateOfBirth',
  updateGender: '/users/updateGender',
  updateEmail: '/auth/updateEmail',
  updatePhone: '/auth/updatePhone',
  deleteEmail: '/auth/deleteUserAuthenticationUsingEmail',
  deletePhone: '/auth/deleteUserAuthenticationUsingPhone',
  loginWithUserNameAndPassword: '/auth/loginWithUserNameAndPassword',
  passwordUpdate: '/auth/passwordUpdate',
  forgotPassword: '/auth/loginPasswordByUserName'
}
