<template src='./activityEditSemesterClassteacher.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import SemesterUserRepository from '../../Repository/SemesterUser'
import showStatus from '../../NetworkManager/showStatus'

export default {
  name: 'activityEditSemesterClassteacher',
  props: [
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer,
    smallButton
  },
  data () {
    return {
      loading: false,
      currentTeacherUid: '',
      currentTeacherName: '',
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedDivision: '',
      departments: [],
      courseYears: [],
      divisions: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      faculties: [],
      search: '',
      criteriaSelected: false
    }
  },
  created () {
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']

    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    console.log(this.tree)
    if (this.tree) {
      for (let i = 0; i < this.tree.length; i++) {
        this.departments.push(this.tree[i].name)
      }
    } else {
      this.departments = []
    }
    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.selectedDepartment = this.prop_selectedDepartment
      this.selectDepartment()
      this.selectedCourseYear = this.prop_selectedCourseYear
      this.selectCourseYear()
      this.selectedDivision = this.prop_selectedDivision
      this.selectDivision()
    }
    this.loading = false
  },
  methods: {
    selectDepartment () {
      this.loading = true
      this.criteriaSelected = false
      this.courseYears = []
      this.divisions = []
      this.selectedCourseYear = ''
      this.resetCurrentTeacher()
      this.selectedDivision = ''
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      this.courseYears = this.tree[indexOfDepartment].children.map((child) => child.name)
      console.log(this.courseYears)
      this.loading = false
    },

    selectCourseYear () {
      this.loading = true
      this.divisions = []
      this.resetCurrentTeacher()
      this.selectedDivision = ''
      // this.criteriaSelected = false
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      const indexOfCourseYear = this.courseYears.indexOf(this.selectedCourseYear)
      this.divisions = this.tree[indexOfDepartment].children[indexOfCourseYear].children.map((child) => child.name)
      this.loading = false
    },

    async selectDivision () {
      this.loading = true
      this.resetCurrentTeacher()
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision
      }

      console.log(objToPush)

      try {
        this.currentTeacherUid = await this.semesterRepositoryInstance.getClassTeacherOfADivisionOfASemester(objToPush)

        if (this.currentTeacherUid) {
          this.currentTeacherName = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.currentTeacherUid })
          console.log(this.currentTeacherName)
        } else {
          this.resetCurrentTeacher()
        }

        console.log(this.currentTeacherName, this.currentTeacherUid)
        this.criteriaSelected = true

        // caching faculties for a department. Only when a department changes, get faculties
        if (this.faculties.filter((faculty) => faculty.department === this.selectedDepartment).length === 0) {
          console.log(objToPush)
          this.faculties = []

          const facultyObjects = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(objToPush) || []

          // this.faculties = []

          for (let i = 0; i < facultyObjects.length; i++) {
            const nameOfFaculty = await this.userRepositoryInstance.getFullNameOfUser({ uId: facultyObjects[i].uId })

            if (nameOfFaculty) {
              this.faculties.push(
                {
                  uId: facultyObjects[i].uId,
                  name: nameOfFaculty,
                  department: facultyObjects[i].department
                }
              )
            }
          }
        }
        console.log(this.faculties)
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
      this.loading = false
    },

    async assignFaculty (item) {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          classTeacher: item.uId
        }
        console.log(objToPush)

        await this.semesterRepositoryInstance.updateClassTeacherOfADivisionOfASemester(objToPush)
        showStatus('Class teacher allocated successfully.', 1000, 'success', this)
        this.currentTeacherUid = item.uId
        this.currentTeacherName = item.name
      } catch (err) {
        showStatus('Selected faculty may be a class teacher of another division.', 1000, 'error', this)
      }
      this.loading = false
    },

    resetCurrentTeacher () {
      this.currentTeacherUid = ''
      this.currentTeacherName = ''
    }
  }
}
</script>
<style src='./activityEditSemesterClassteacher.css' scoped>
</style>
