<template src='./importAllDialog.html'>
</template>
<script>
// import axios from 'axios'
// import { apiUrl } from '../../Services/Config/apiConfig'

import ImportFromView from './importFromView.vue'
import ImportedUsersView from './importedUsersView.vue'
import AddUsersIntoView from './addUsersIntoView.vue'
import SemesterRepository from '../../../Repository/Semester'
import showStatus from '../../../NetworkManager/showStatus'

export default {
  props: ['dialog', 'closeDialog', 'tree', 'getUsers'],
  components: {
    ImportFromView,
    ImportedUsersView,
    AddUsersIntoView
  },
  data () {
    return {
      loading: false,
      snackbar: false,
      error: false,
      step: 1,
      semesters: [],
      users: [],
      selectedUsers: [],
      importFromObj: {},
      addIntoObj: {},
      selectedInstitute: null,
      selectedSemester: {}
    }
  },
  created () {
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']

    this.getSemesters()
  },
  methods: {
    async getSemesters () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async nextStep (step) {
      if (step === 1) {
        const child = this.$refs.importFrom
        const userRequestObj = child.getData()
        this.loading = true
        this.users = await this.getUsers(
          userRequestObj.userType,
          userRequestObj.selection,
          userRequestObj.semId
        )
        this.importFromObj = userRequestObj

        this.step = step + 1
        this.loading = false
      }
      if (step === 2) {
        const child = this.$refs.importedUsers
        this.selectedUsers = child.getData()
        this.step = step + 1
      }
    },

    async importAllSemesterUsers () {
      if (this.selectedUsers.length === 0) {
        showStatus('No users selected!', 1500, 'error', this)
        return
      }
      const child2 = this.$refs.importFrom
      const userRequestObj = child2.getData()
      this.loading = true
      const child = this.$refs.addUsersInto
      const addInto = child.getData()

      let res = null
      if (userRequestObj.userType === 'faculty') {
        const objToPush = {
          collegeId: this.selectedCollege.collegeId,
          semId: this.importFromObj.semId,
          department: addInto.department,
          semIdNew: addInto.semIdNew,
          selectedUsers: this.selectedUsers,
          activate: true
        }
        console.log(objToPush)
        // res = await axios.post(`${apiUrl}/semesterUsers/faculty/import`, objToPush)
        // Call APi to import semesterUsers Faculty
        res = {
          status: 200,
          data: {}
        }
      } else {
        const objToPush = {
          collegeId: this.selectedCollege.collegeId,
          semId: this.importFromObj.semId,
          department: addInto.department,
          courseYear: addInto.courseYear,
          division: addInto.division,
          semIdNew: addInto.semIdNew,
          selectedUsers: this.selectedUsers,
          activate: true
        }
        console.log(objToPush)
        // res = await axios.post(`${apiUrl}/semesterUsers/students/import`, objToPush)
        // Call APi to import semesterUsers Students
        res = {
          status: 200,
          data: {}
        }
      }

      if (res.status === 200) {
        this.error = false
        this.snackbar = true
        this.closeDialog()
      } else {
        this.error = true
        this.snackbar = true
      }

      this.loading = false
    }
  }
}
</script>
<style src='./importAllDialog.css' scoped>
</style>
