// this is the main class of the Vuex which will contain all the Stores

// Import statements to get all required libraries
import Vue from 'vue'
import Vuex from 'vuex' // library for creating VueX object

// Import statements to get all required modules for separating data into different categories
import user from './Modules/user'
import instituteData from './Modules/instituteData'
import liveData from './Modules/liveData'

Vue.use(Vuex) // Using VueX object in Vue

export const store = new Vuex.Store({
  // this is the main object of the store which will contain all other stores as child stores
  state: {
    // this object contains initialization of all the data entities
  },
  getters: {
    // this object contains getters for every data entity in state
  },
  mutations: {
    // this object contains Mutations(Sync. setters) for every data entity in state
  },
  actions: {
    // this object contains actions(Async. setters) for every data entity in state
  },
  modules: {
    // this object contains list of all child stores which are defined in the modules folder
    // get modules
    user,
    instituteData,
    liveData
  }
})
