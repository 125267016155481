<template src='./activityArrangeLecture.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import SubjectRepository from '../../Repository/Subject'
import SemesterUserRepository from '../../Repository/SemesterUser'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import ShiftRepository from '../../Repository/ShiftReference'
import TimetableRepository from '../../Repository/Timetable'
import LecturesRepository from '../../Repository/Lecture'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import { timeStringConverter, convertFromYYYYMMDDToDate } from '../../Services/Utils/DateTimeFormatter'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityArrangeLecture',
  props: [
    'prop_selectedDate',
    'prop_occupiedSlots'
  ],
  components: {
    inputContainer
  },
  data () {
    return {
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      occupiedSlots: [],
      selectedDate: '',
      dataFetched: false,
      facultyNames: [],
      selectedDepartment: '',
      departments: [],
      selectedFaculty: '',
      faculties: [],
      userData: {},
      selectedInstitute: '',
      selectedSemester: '',
      allocations: [],
      subjectsMapping: {},
      freeSlots: [],
      selectedAllocation: {
        name: ''
      },
      tempTimeSlot: '',
      tempLocation: '',
      locations: [],
      locationNames: [],
      freeSlotsList: [],
      finalStep: false,
      timetable: [],
      shift: {},
      facultyOccupiedSlots: [],
      divisionOccupiedSlots: []
    }
  },
  created () {
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.shiftRepositoryInstance = new ShiftRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.lecturesRepositoryInstance = new LecturesRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    // this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
    this.subjectsMapping = {}
    // console.log(this.subjectsMapping)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    // this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.$store.commit('liveData/set_selectedActivityName', 'Arrange Lecture')
    this.userData = this.$store.getters['user/get_userData']
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.departments = []
        const divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async selectAllocation (allocation) {
      this.tempTimeSlot = ''
      this.tempLocation = ''
      this.finalStep = true
      this.selectedAllocation = {
        name: allocation.subjectId + '_' + allocation.department + '_' + allocation.courseYear + '_' + allocation.division + '_' + allocation.batch,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: allocation.division,
        batch: allocation.batch,
        subjectId: allocation.subjectId
      }
      console.log(this.selectedAllocation)
      const objToPush = {
        instituteId: allocation.instituteId,
        semId: allocation.semId,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: allocation.division,
        dateOfLecturesNeeded: this.convertToISOString(convertFromYYYYMMDDToDate(this.selectedDate))
      }

      this.locations = []

      try {
        this.locations = await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(objToPush)
        console.log(this.locations)
      } catch (err) {
        console.log(err)
        this.locations = []
      }

      this.locations.forEach((location) => {
        this.locationNames.push(location.roomName)
      })

      try {
        const shiftId = await this.semesterRepositoryInstance.getShiftOfADivisionOfASemester(objToPush)
        if (shiftId) {
          this.shift = await this.shiftRepositoryInstance.getShift({
            instituteId: allocation.instituteId,
            shiftId: shiftId
          })
        }
      } catch (err) {
        console.log(err)
      }

      try {
        this.timetable = await this.lecturesRepositoryInstance.getLecturesOfADivisionForADay(objToPush)
      } catch (err) {
        console.log(err)
        this.timetable = []
      }
      this.freeSlots = []
      this.freeSlotsList = []
      this.facultyOccupiedSlots = []
      this.divisionOccupiedSlots = []
      if (this.shift.timeSlots) {
        this.shift.timeSlots.forEach((timeSlot) => {
          this.freeSlots.push(timeSlot)
        })
      }
      this.freeSlots.forEach((tSlot) => {
        this.freeSlotsList.push(timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime))
      })
      this.occupiedSlots.forEach((tSlot) => {
        this.facultyOccupiedSlots.push(timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime))
      })
      this.timetable.forEach((tSlot) => {
        this.divisionOccupiedSlots.push(timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime))
      })
      console.log(this.freeSlots, this.freeSlotsList, this.facultyOccupiedSlots, this.divisionOccupiedSlots)
    },
    async selectDepartment () {
      this.dataFetched = false
      this.faculties = []
      this.selectedFaculty = ''
      this.faculties = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment
      })
      let promises = []
      promises = this.faculties.map((faculty) => {
        return this.userRepositoryInstance.getFullNameOfUser({ uId: faculty.uId })
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.facultyNames = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          // console.log(allSettledPromises[i].value)
          this.facultyNames.push(allSettledPromises[i].value)
        }
      }
      console.log(this.facultyNames)
    },
    async selectFaculty () {
      this.dataFetched = false
      console.log(this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId
      }
      try {
        const res = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(objToPush)
        console.log(res)
        this.allocations = res
      } catch (err) {
        console.log(err)
        this.allocations = []
      }
      try {
        objToPush.dateOfLecturesNeeded = this.convertToISOString(convertFromYYYYMMDDToDate(this.selectedDate))
        this.occupiedSlots = await this.lecturesRepositoryInstance.getLecturesForADay(objToPush)
        console.log(this.occupiedSlots)
      } catch (err) {
        console.log(err)
      }
      try {
        // for (let i = 0; i < this.allocations.length; i++) {
        //   await this.getSubjectDetails(this.allocations[i])
        // }
        let promises = []
        promises = this.allocations.map((allocation) => {
          return this.getSubjectDetails(allocation.subjectId)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            // console.log(allSettledPromises[i].value)
            this.subjectsMapping[allSettledPromises[i].value.subjectId] = (allSettledPromises[i].value)
          }
        }
        this.dataFetched = true
      } catch (err) {
        console.log(err)
      }
    },
    async selectDate () {
      this.dataFetched = false
      this.selectedDepartment = ''
      this.selectedFaculty = ''
      this.faculties = []
      this.facultyNames = []
      console.log(new Date(this.selectedDate))
    },
    async getSubjectDetails (subjectId) {
      const response = await this.subjectRepositoryInstance.getDetailsOfASubject({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: subjectId
      })
      return {
        subjectId: subjectId,
        subjectName: response.subjectName,
        subjectType: response.subjectType
      }
    },
    unselectAllocation () {
      this.finalStep = false
      this.selectedAllocation = {
        name: ''
      }
      this.tempTimeSlot = ''
      this.tempLocation = ''
    },
    getDayNameFromDayNumber (dayNumber) {
      switch (dayNumber) {
        case 0: return 'Sunday'
        case 1: return 'Monday'
        case 2: return 'Tuesday'
        case 3: return 'Wednesday'
        case 4: return 'Thursday'
        case 5: return 'Friday'
        case 6: return 'Saturday'
      }
    },
    getDateTimeOfLecture (dateObject, startTimeOfLecture) {
      const timeArray = startTimeOfLecture.split('_').map((time) => parseInt(time))

      timeArray[0] += 5
      timeArray[1] += 30

      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      )
    },
    convertToISOString (date) {
      try {
        const convertedDate = new Date(date.valueOf())
        // IST is 5.30 hours ahead of UTC
        convertedDate.setHours(5, 30, 0, 0)
        return convertedDate.toISOString()
      } catch (err) {
        console.log(err)
        return undefined
      }
    },
    async arrangeLecture () {
      const indexOfSlot = this.freeSlotsList.indexOf(this.tempTimeSlot)
      const indexOfLocation = this.locationNames.indexOf(this.tempLocation)
      const lectureToArrange = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedAllocation.department,
        courseYear: this.selectedAllocation.courseYear,
        division: this.selectedAllocation.division,
        batch: this.selectedAllocation.batch,
        subjectId: this.selectedAllocation.subjectId,
        uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId,
        roomId: this.locations[indexOfLocation].roomId,
        startTime: this.freeSlots[indexOfSlot].startTime,
        endTime: this.freeSlots[indexOfSlot].endTime,
        day: this.getDayNameFromDayNumber(new Date(this.selectedDate).getDay()),
        dateTime: this.getDateTimeOfLecture(new Date(this.selectedDate), this.freeSlots[indexOfSlot].startTime)
      }
      console.log(lectureToArrange)
      try {
        const objToPush = {
          lectures: [lectureToArrange]
        }
        const lectures = await this.lecturesRepositoryInstance.arrangeALecture(objToPush)
        console.log(lectures)
        this.unselectAllocation()
        showStatus('Lectures Arranged successfully.', 1000, 'success', this)
        const self = this
        setTimeout(() => self.$router.go(-1), 1000)
      } catch (err) {
        console.log(err)
        showStatus('Could not arrange lectures. Try again', 1000, 'error', this)
      }
    }
  }
}
</script>

<style src='./activityArrangeLecture.css'>
</style>
