<template src="./activityWelcome.html"></template>
<script>
import auth from '../../Services/Firebase/auth'
import UserRepository from '../../Repository/User'
import showStatus from '../../NetworkManager/showStatus'
import { ROLES } from '../../Constants'
import axios from 'axios'

export default {
  name: 'activityWelcome',
  data() {
    return {
      instituteShortCode: '',
      instituteId: '',
      isInstituteIdAvailable: false,
      isMobileView: false,
      signInWithPhone: false,
      phoneAuthStep: 0, // 0 -> enter phoneNum, 1 -> enter otp
      phoneNum: null,
      phoneOtp: null,
      isSendingRequest: false,
      username: '',
      password: '',
      usernameRules: [(v) => !!v || 'Username is required'],
      passwordRules: [(v) => !!v || 'Password is required'],
      confirmationResultFunc: null,
      verificationCode: null,
      resetPassword: false,
      currentPassword: '',
      showCurrentPassword: false,
      newPassword: '',
      showNewPassword: false,
      confirmPassword: '',
      showConfirmPassword: false,
      confirmPasswordRules: [
        (v) => v === this.newPassword || 'New passwords do not match',
      ],
      showLoginPassword: false,
      uId: '',
      forgotPassword: false,
      email: '',
      //Thanks stackoverflow: https://stackoverflow.com/a/59598452
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid',
      ],
    }
  },
  methods: {
    async getInstituteIdByShortCode() {
      console.log('instituteCode', this.instituteShortCode)
      const serverUrl = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT
      axios
        .get(
          `${serverUrl}/institute/getInstituteByShortCode?instituteShortCode=${this.instituteShortCode}`
        )
        .then((res) => {
          this.isInstituteIdAvailable = true
          this.instituteId = res.data.institute.instituteId
          console.log('institute id', res.data.institute)
          this.$store.commit('liveData/set_admissionInstitute', {
            admissionInstitute: this.instituteId,
          })
          this.$store.commit('liveData/set_razorPayNumber', {
            razorPayNumber: res.data.institute.razorPayNumber,
          })
          this.$store.commit('liveData/set_razorpayAccessToken', {
            razorpayAccessToken: res.data.institute.razorpayAccessToken,
          })
          this.$store.commit('liveData/set_razorpaySecrete', {
            razorpaySecrete: res.data.institute.razorpaySecrete,
          })
          this.$store.commit('liveData/set_descriptionForPayment', {
            descriptionForPayment: res.data.institute.descriptionForPayment,
          })
          this.$store.commit('liveData/set_instituteStudentLogoLink', {
            instituteStudentLogoLink:
              res.data.institute.instituteStudentLogoLink,
          })
          this.$store.commit('liveData/set_instituteStudentBrandingName', {
            instituteStudentBrandingName:
              res.data.institute.instituteStudentBrandingName,
          })
          this.$store.commit('liveData/set_instituteAddress', {
            instituteAddress: res.data.institute.instituteAddress,
          })
          this.$store.commit('liveData/set_instituteEmailId', {
            instituteEmailId: res.data.institute.instituteEmail[0],
          })
          this.$store.commit('liveData/set_instituteContactNumber', {
            instituteContactNumber: res.data.institute.institutePhoneNumber[0],
          })
          this.$store.commit('liveData/set_senderMail', {
            senderMail: res.data.institute.senderMail,
          })
          this.$store.commit('liveData/set_senderMailPassword', {
            senderMailPassword: res.data.institute.senderMailPassword,
          })
          this.$store.commit('liveData/set_displayName', {
            displayName: res.data.institute.displayName,
          })
          this.$store.commit('liveData/set_subjectDescription', {
            subjectDescription: res.data.institute.subjectDescription,
          })
          this.$store.commit('liveData/set_instituteTermsAndConditions', {
            instituteTermsAndConditions: res.data.institute.TnC,
          })
          this.$store.commit('liveData/set_instituteGstNumber', {
            instituteGstNumber: res.data.institute.gstNumber,
          })
        })
    },
    /* loginType -> google | phone | username */
    login(loginType) {
      if (this.isInstituteIdAvailable) {
        switch (loginType) {
          case 'google':
            auth.login()
            break
          case 'phone':
            this.confirmOtp()
            break
          case 'username':
            this.loginWithUserNameAndPassword()
            break
          default:
            throw new Error('Unhandled Case in login')
        }
      }
    },
    async loginWithUserNameAndPassword() {
      try {
        let fcmToken = ''
        this.isSendingRequest = true
        this.userRepositoryInstance = new UserRepository(this)
        //login
        const response =
          await this.userRepositoryInstance.loginWithUserNameAndPassword({
            userName: this.username,
            password: this.password,
            registrationToken: fcmToken,
            role: ROLES.ADMIN,
          })
        if (response.isPasswordReset !== 0) {
          this.resetPassword = true
          this.username = ''
          this.password = ''
          this.uId = response.uId
        } else {
          //commit user to store
          this.$store.commit('user/set_userData', {
            uId: response.uId,
          })
          //save to localStorage
          window.localStorage.setItem(
            'userKey',
            JSON.stringify({ uId: response.uId })
          )
          //redirect to institute
          showStatus('Login Successful!', 1000, 'success', this)
          this.$router.push('/activitySelectInstituteNSemester')
        }
      } catch (e) {
        console.error(`Error logging with username and password: ${e}`)
        if (e.response.status === 401) {
          alert("You don't have access to this portal")
        }
        if (e.response.status === 404) {
          alert('Error occured, please check your credentials and retry')
        }
      } finally {
        this.isSendingRequest = false
      }
    },
    async passwordUpdate() {
      try {
        this.isSendingRequest = true
        const response = await this.userRepositoryInstance.passwordUpdate({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          uId: this.uId,
        })
        if (response.error) {
          throw new Error(response.message)
        }
        this.resetPassword = false
        alert('Password updated successfully')
      } catch (e) {
        if (e.message === 'Current password does not match') {
          alert('Current password does not match')
        } else {
          alert('An error occured')
          this.resetPassword = false
        }
        console.error(`Error resetting password: ${e}`)
      } finally {
        this.isSendingRequest = false
      }
    },
    togglePhoneLogin() {
      this.signInWithPhone = !this.signInWithPhone
    },
    async sendOtp() {
      try {
        this.isSendingRequest = true
        const firebase = auth.getFirebaseObject()
        firebase.auth().useDeviceLanguage()
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha',
          {
            size: 'invisible',
          }
        )
        const phoneNumber = `+91${this.phoneNum}`
        const appVerifier = window.recaptchaVerifier
        const confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
        this.confirmationResultFunc = confirmationResult
        this.verificationCode = confirmationResult.verificationId
        this.phoneAuthStep = 1
      } catch (error) {
        console.error(`Error sending otp: ${error}`)
      } finally {
        this.isSendingRequest = false
      }
    },
    async confirmOtp() {
      try {
        this.isSendingRequest = true
        const firebase = auth.getFirebaseObject()
        const credential = firebase.auth.PhoneAuthProvider.credential(
          this.verificationCode,
          this.phoneOtp
        )
        await firebase.auth().signInWithCredential(credential)
        // after this, the firebase auth listener automatically
        // detects the user and redirects to setup activity
        // check in created method below
      } catch (error) {
        console.error(`Error confirming otp: ${error}`)
      } finally {
        this.isSendingRequest = false
      }
    },
    async onForgotPassword() {
      try {
        this.isSendingRequest = true
        //call API
        const data = { userName: this.email }
        this.userRepositoryInstance = new UserRepository(this)
        await this.userRepositoryInstance.forgotPassword(data)
        alert('Check your email')
        this.forgotPassword = false
      } catch (e) {
        console.error(`Error in forgot password: ${e}`)
        alert('An error occured')
      } finally {
        this.isSendingRequest = false
      }
    },
    handleOnChange(value) {
      this.phoneOtp = value
    },
    getLoginHeaderImage() {
      return require(`../../Assets/Images/${process.env.VUE_APP_LOGINPAGE_LOGO}`)
    },
  },

  created() {
    if(this.$route.params.instituteShortCode){
      this.instituteShortCode = this.$route.params.instituteShortCode?this.$route.params.instituteShortCode:''
      this.getInstituteIdByShortCode()
      this.isInstituteIdAvailable
    }
    if (window.innerWidth < 1200) {
      this.isMobileView = true
    } else {
      this.isMobileView = false
    }
    const self = this
    if (window.innerWidth < window.innerHeight) {
      this.$store.commit('liveData/set_isMobileView', true)
      this.$store.commit('liveData/set_selectedActivity', 2)
    }
    const user = window.localStorage.getItem('userKey')
    if (user) {
      const { uId } = JSON.parse(user)
      self.$store.commit('user/set_userData', {
        uId,
      })
      self.$router.push('/activitySetup')
      return
    }
    if (auth.user()) {
      self.$router.push('/activitySetup')
    }
  },
}
</script>
<style src="./activityWelcome.css"></style>
