<template src="./activityViewSemesters.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import SubjectRepository from '../../Repository/Subject'
import smallButton from '../../Components/smallButton'
import { convertDate } from '../../Services/Utils/DateTimeFormatter'
export default {
  name: 'activityViewSemesters',
  components: {
    inputContainer,
    smallButton
  },
  data () {
    return {
      userData: '',
      selectedInstitute: '',
      selectedSemester: '',
      search: '',
      search1: '',
      search2: '',
      semesters: [],
      semestersList: [],
      headers1: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'HOD',
          value: 'hod'
        }
      ],
      headers2: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Subjects',
          value: 'subjects'
        }
      ],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Class Teacher',
          value: 'classTeacher'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        },
        {
          text: 'Holidays',
          value: 'holidays'
        },
        {
          text: 'Shift',
          value: 'shift'
        },
        {
          text: 'Batches',
          value: 'batches'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      semesterDepartments: [],
      semesterCourseYears: [],
      semesterDivisions: [],
      editingPermission: false,
      divisionOperations: [
        'Select Start Date/End Date',
        'Allocate Shift',
        'Set Batch Count',
        'Assign Classteachers',
        'Register Students to Subjects',
        'Define Timetable'
      ],
      courseYearOperations: ['Add Subjects'],
      departmentOperations: ['Add Students/Faculties', 'Assign HOD'],
      facultyIdToFacultyNameMapping: {}
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'View Semester')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semesters.reverse()
        this.semestersList = this.semesters.map(semester => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemester = this.semestersList[0]
          this.getEditingPermissionOfASemester()
          this.selectSemester()
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.getDepartmentDataForSemesters()
      this.getCourseYearDataForSemesters()
      this.getDivisionsForSemesters()
    },
    async getEditingPermissionOfASemester () {
      this.semesterCourseYears = []
      this.semesterDivisions = []
      this.editingPermission = false
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.editingPermission = await this.semesterRepositoryInstance.getEditingPermissionOfASemester(
          objToPush
        )
      } catch (err) {
        console.log(err)
        this.editingPermission = false
      }
    },
    async getDepartmentDataForSemesters () {},
    async getCourseYearDataForSemesters () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.semesterCourseYears = []
        const subjectsOfAnInstitute = await this.subjectRepositoryInstance.getSubjectsOfAInstituteInASemester(
          objToPush
        )

        const departmentCourseYearToSubjectsMapping = {}

        subjectsOfAnInstitute.forEach(subjectObject => {
          if (
            !departmentCourseYearToSubjectsMapping[
              subjectObject.department + subjectObject.courseYear
            ]
          ) {
            departmentCourseYearToSubjectsMapping[
              subjectObject.department + subjectObject.courseYear
            ] = {
              department: subjectObject.department,
              courseYear: subjectObject.courseYear,
              subjects: []
            }
          }

          departmentCourseYearToSubjectsMapping[
            subjectObject.department + subjectObject.courseYear
          ].subjects.push(subjectObject)
        })

        this.semesterCourseYears = Object.values(
          departmentCourseYearToSubjectsMapping
        )
      } catch (err) {
        console.log(err)
        this.semesterCourseYears = []
      }
    },
    async getDivisionsForSemesters () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.semesterDivisions = []
        this.semesterDivisions = await this.semesterRepositoryInstance.getDivisionsOfASemester(
          objToPush
        )
        for (let i = 0; i < this.semesterDivisions.length; i++) {
          if (this.semesterDivisions[i].classTeacher) {
            this.facultyIdToFacultyNameMapping[
              this.semesterDivisions[i].classTeacher
            ] =
              (await this.userRepositoryInstance.getFullNameOfUser({
                uId: this.semesterDivisions[i].classTeacher
              })) || ''
          }
        }
        // TODO: shift name of shift id
        // for (let i = 0; i < this.semesterDivisions.length; i++) {
        //   if (this.semesterDivisions[i].classTeacher) {
        //     this.facultyIdToFacultyNameMapping[this.semesterDivisions[i].classTeacher] = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.semesterDivisions[i].classTeacher }) || ''
        //   }
        // }
      } catch (err) {
        console.log(err)
        this.semesterDivisions = []
      }
    },
    gotoEditSemesterDivision (operation, item) {
      console.log(operation, item)
      this.$store.commit(
        'liveData/set_editingSemester',
        this.getSemesterObjectOfSelectedSemester()
      )
      // this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push({
        name: 'activityEditSemester',
        params: {
          prop_selectedOperation: operation,
          prop_selectedDepartment: item.department,
          prop_selectedCourseYear: item.courseYear,
          prop_selectedDivision: item.division
        }
      })
    },
    gotoEditSemesterCourseYear () {},
    gotoEditSemesterDepartment () {},
    gotoEditSemester () {
      this.$store.commit(
        'liveData/set_editingSemester',
        this.getSemesterObjectOfSelectedSemester()
      )
      // this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push('/activityEditSemester')
    },
    convertDate (time) {
      return convertDate(time)
    },
    getSemesterObjectOfSelectedSemester () {
      const index = this.semestersList.indexOf(this.selectedSemester)
      return this.semesters[index]
    }
  }
}
</script>
<style scoped src="./activityViewSemesters.css"></style>
