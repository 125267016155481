export default {
  createInstitute: '/institute/createInstitute',
  getInstituteInformation: '/institute/getInstitute',
  updateInstituteName: '/institute/updateInstituteName',
  updateInstituteAddress: '/institute/updateInstituteAddress',
  updateAffliatingUniversityName: '/institute/updateAffliatingUniversityName',
  updateAffiliatingUniversityAddress:'/institute/updateAffliatingUniversityAddress',
  updateInstituteEmail: '/institute/updateInstituteEmail',
  updateInstitutePhoneNumber: '/institute/updateInstitutePhoneNumber',
  updateEstablishmentYear: '/institute/updateEstablishmentYear',
  updateInstituteType: '/institute/updateInstitutionType',
  updateOwnershipStatus: '/institute/updateOwnershipStatus',
  updateOwners: '/institute/updateOwners',
  updateInstituteWebsite: '/institute/updateInstituteWebsite',
  updateInstituteLocation: '/institute/updateInstituteLocation',
  getInstituteBranding: '/institute/getInstituteBranding',
  updateInstituteAdminLogo: '/institute/updateInstituteLogoLink',
  updateInstituteAdminBrandingName: '/institute/updateInstituteBrandingName',
  updateInstituteFacultyLogo: '/institute/updateInstituteLogoLink',
  updateInstituteFacultyBrandingName: '/institute/updateInstituteBrandingName',
  updateInstituteStudentLogo: '/institute/updateInstituteLogoLink',
  updateInstituteStudentBrandingName: '/institute/updateInstituteBrandingName'
}
