export default {
  getSubjectsOfAInstitute: '/subject/getSubjectsOfAInstitute',
  getSubjectName: '/subject/getSubjectName',
  getSubjectsOfACourseYear: '/subject/getSubjectsOfACourseYear',
  getSubjectsOfACourseYearForAdmission: '/subject/getSubjectsOfACourseYearForAdmission',
  getFacultiesTeachingASubject: '/subject/getFacultiesTeachingASubject',
  getDetailsOfASubject: '/subject/getSubject',
  updateGroupName: '/subject/updateGroupName',
  updateSubjectName: '/subject/updateSubjectName',
  updateSubjectCredits: '/subject/updateCredits',
  updateSubjectCoordinators: '/subject/updateSubjectCoordinators',
  updateFacultiesTeachingASubject: '/subject/updateFacultiesTeachingASubject',
  updateSubjectType: '/subject/updateSubjectType',
  createSubject: '/subject/createSubject',
  deleteSubject: '/subject/deleteSubject'
}
