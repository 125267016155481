<template src="./activityHome.html"> </template>
<script>
import auth from '../../Services/Firebase/auth'
import state from '../../Store/Modules/liveData'
import NotificationRepository from '../../Repository/Notification'
import NetworkManager from '../../NetworkManager'
import InstituteAPIs from '../../ApiNames/Institute'
import PortalFooter from '../../Components/PortalFooter'

export default {
  name: 'activityHome',
  components: {
    PortalFooter
  },
  data () {
    return {
      selectedActivity: 1,
      userData: null,
      user: null,
      logoutDialog: false,
      state: state,
      show: false,
      selectedInstitute: '',
      brandingLogo: '',
      brandingName: ''
    }
  },
  created () {
    this.notificationRepositoryInstance = new NotificationRepository(this)
    this.user = this.$store.getters['user/get_user']
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]

    console.log('hello');
    this.fetchBrandingDetails(this.selectedInstitute)
  },
  methods: {
    getLoginHeaderImage() {
      return require(`../../Assets/Images/${process.env.VUE_APP_LOGINPAGE_LOGO}`)
    },
    async fetchBrandingDetails (instituteId) {
       if(instituteId){
         try {
           const data = await NetworkManager.getRequest(
             InstituteAPIs.getInstituteBranding,
             { instituteId }
           )

           this.brandingName = data.instituteBrandingName
           this.brandingLogo = data.instituteLogoLink
         } catch (error) {
           console.log({ error })
         }
       }
    },
    async fetchData () {
      // Call API to get todays notifications
      const objToPush = {
        instituteId: '',
        semId: '',
        uId: ''
      }
      try {
        this.notifications = await this.notificationRepositoryInstance.getTodaysNotifications(
          objToPush
        )
      } catch (err) {
        this.notifications = []
        console.log(err)
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    gotoMyProfile () {
      this.$store.commit('liveData/set_selectedActivity', 1)
      this.$router.push('/activityProfile')
    },
    gotoInstituteBasicDetails () {
      this.$store.dispatch('liveData/set_selectedActivity', 2)
      this.$router.push('/activityInstitutesBasicDetails')
    },
    gotoReferenceAcademicDetails () {
      this.$store.dispatch('liveData/set_selectedActivity', 3)
      this.$router.push('/activityReferenceAcademicDetails')
    },
    gotoReferenceInfrastructure () {
      this.$store.dispatch('liveData/set_selectedActivity', 4)
      this.$router.push('/activityReferenceInfrastructure')
    },
    gotoReferenceShiftDetails () {
      this.$store.dispatch('liveData/set_selectedActivity', 5)
      this.$router.push('/activityReferenceShiftDetails')
    },
    gotoManageRoles () {
      this.$store.dispatch('liveData/set_selectedActivity', 6)
      this.$router.push('/activityManageRoles')
    },
    gotoViewSemesters () {
      this.$store.dispatch('liveData/set_selectedActivity', 7)
      this.$router.push('/activityViewSemesters')
    },
    gotoCreateSemester () {
      this.$store.dispatch('liveData/set_selectedActivity', 8)
      this.$router.push('/activityCreateSemester')
    },
    gotoDashboard () {
      this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push('/activityDashboard')
    },
    gotoExams () {
      this.$store.dispatch('liveData/set_selectedActivity', 10)
      this.$router.push('/activityExams')
    },
    gotoAttendanceAnalysis () {
      this.$store.dispatch('liveData/set_selectedActivity', 11)
      this.$router.push('/activityAttendanceAnalysis')
    },
    gotoArrangeLecture () {
      this.$store.dispatch('liveData/set_selectedActivity', 12)
      this.$router.push('/activityArrangeLecture')
    },
    gotoManageHoliday () {
      this.$store.dispatch('liveData/set_selectedActivity', 13)
      this.$router.push('/activityManageHoliday')
    },
    gotoSyllabusTracking () {
      this.$store.dispatch('liveData/set_selectedActivity', 14)
      this.$router.push('/activitySyllabusTracking')
    },
    gotoNotifications () {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 15)
      this.$router.push('/activityNotifications')
    },
    gotoManageUsers () {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 16)
      this.$router.push('/activityManageUsers')
    },
    gotoTimeline () {
      this.$store.commit('liveData/set_selectedActivity', 17)
      this.$router.push('/activityTimeline')
    },
    gotoManageSMS () {
      this.$store.commit('liveData/set_selectedActivity', 18)
      this.$router.push('/activityManageSMS')
    },
    gotoManageTopicFeedbackTemplates () {
      this.$store.commit('liveData/set_selectedActivity', 19)
      this.$router.push('/activityManageTopicFeedbackTemplates')
    },
    gotoTopicFeedbackAnalysis () {
      this.$store.commit('liveData/set_selectedActivity', 20)
      this.$router.push('/activityTopicFeedbackAnalysis')
    },
    gotoNBAModule () {
      this.$store.dispatch('liveData/set_selectedActivity', 21)
      this.$router.push('/activityNBAModule')
    },
    gotoNBAextended () {
      this.$store.dispatch('liveData/set_selectedActivity', 22)
      this.$router.push('/activityNBAextended')
    },
    gotoAdmission () {
      this.$store.dispatch('liveData/set_selectedActivity', 23)
      this.$router.push('/activityAdmission')
    },
    gotoFeeReceipt() {
      this.$store.dispatch('liveData/')
    },
    logout () {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      this.$store.commit('liveData/reset')
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    }
  }
}
</script>
<style scoped src="./activityHome.css"></style>
