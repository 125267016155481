import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/TopicFeedbackTemplate'

class TopicFeedbackTemplate {
  constructor (context) {
    this.context = context
  }

  async createTopicFeedbackTemplate (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTopicFeedbackTemplate, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackTemplate
  }

  async getTopicFeedbackTemplatesOfAnInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbackTemplatesOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackTemplates
  }

  async getTopicFeedbackTemplateById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbackTemplateById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackTemplate
  }
}

export default TopicFeedbackTemplate
