<template src='./activityAttendanceAnalysis.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import { subjectTypes } from '../../Constants/Utils/Statics'
// import showStatus from '../../NetworkManager/showStatus'
import SubjectRepository from '../../Repository/Subject'
import TimetableRepository from '../../Repository/Timetable'
import LectureRepository from '../../Repository/Lecture'
import SemesterRepository from '../../Repository/Semester'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
export default {
  name: 'activityAttendanceAnalysis',
  components: {
    inputContainer
  },
  data () {
    return {
      dataFetched: false,
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      stepOneCompleted: false,
      departments: [],
      selectedDepartment: '',
      selectedDate: '',
      selectedFaculty: '',
      faculties: [],
      facultyNames: [],
      allocations: [],
      // occupiedSlots: [],
      subjectsMapping: {},
      selectedAllocation: {
        name: ''
      },
      totalStudents: 0,
      totalLectures: 0,
      selectedLimit: 75,
      defaulterStudentsPercent: 0,
      defaulterStudentsCount: 0,
      nondefaulterStudentsPercent: 0,
      nondefaulterStudentsCount: 0,
      search: '',
      studentsList: [],
      headers: [
        {
          text: '',
          value: 'defaulterStatusDot'
        },
        {
          text: 'Roll Number',
          value: 'rollNumber'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Total Attended',
          value: 'totalAttended'
        },
        {
          text: 'Percentage',
          value: 'percentage'
        }
      ],
      selectedSubject: {},
      userData: {},
      subjectTypes: subjectTypes
    }
  },
  created () {
    this.userData = this.$store.getters['user/get_userData']
    this.$store.commit('liveData/set_selectedActivityName', 'Attendance Analysis')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    // this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      this.dataFetched = false
      this.stepOneCompleted = false
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.departments = []
        const divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async selectDate () {
      this.dataFetched = false
      this.selectedDepartment = ''
      this.selectedFaculty = ''
      this.faculties = []
      this.facultyNames = []
      console.log(new Date(this.selectedDate))
    },
    async selectDepartment () {
      this.dataFetched = false
      this.stepOneCompleted = false
      this.faculties = []
      this.facultyNames = []
      this.selectedFaculty = ''
      this.faculties = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment
      })
      let promises = []
      promises = this.faculties.map((faculty) => {
        return this.userRepositoryInstance.getFullNameOfUser({ uId: faculty.uId })
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.facultyNames = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          // console.log(allSettledPromises[i].value)
          this.facultyNames.push(allSettledPromises[i].value)
        }
      }
      console.log(this.facultyNames)
    },
    async selectFaculty () {
      this.dataFetched = false
      this.stepOneCompleted = false
      console.log(this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId
      }
      try {
        const res = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(objToPush)
        console.log(res)
        this.allocations = res
      } catch (err) {
        console.log(err)
        this.allocations = []
      }
      // try {
      //   objToPush.dateOfLecturesNeeded = new Date(this.selectedDate).toISOString()
      //   this.occupiedSlots = await this.lecturesRepositoryInstance.getLecturesForADay(objToPush)
      //   console.log(this.occupiedSlots)
      // } catch (err) {
      //   this.occupiedSlots = []
      //   console.log(err)
      // }
      try {
        // for (let i = 0; i < this.allocations.length; i++) {
        //   await this.getSubjectDetails(this.allocations[i])
        // }
        let promises = []
        promises = this.allocations.map((allocation) => {
          return this.getSubjectDetails(allocation.subjectId)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            // console.log(allSettledPromises[i].value)
            this.subjectsMapping[allSettledPromises[i].value.subjectId] = (allSettledPromises[i].value)
          }
        }
        console.log(this.subjectsMapping, allSettledPromises)
        this.dataFetched = true
      } catch (err) {
        console.log(err)
      }
    },
    async getSubjectDetails (subjectId) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: subjectId
      }
      try {
        const response = await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush)
        console.log(objToPush, {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType
        })
        return {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType
        }
      } catch (err) {
        console.log(err)
        return err
      }
    },
    async selectAllocation (allocation) {
      this.selectedAllocation = {
        name: allocation.subjectId + '_' + allocation.department + '_' + allocation.courseYear + '_' + allocation.division + '_' + allocation.batch,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: allocation.division,
        batch: allocation.batch,
        subjectId: allocation.subjectId
      }
      this.stepOneCompleted = false
      this.selectedSubject = allocation
      await this.fetchData2()
    },
    async fetchData2 () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          subjectId: this.selectedSubject.subjectId,
          uId: this.selectedSubject.uId
        }
        this.studentsList = await this.lectureRepositoryInstance.getAttendanceAnalysisForADivisionForASubject(objToPush)

        this.totalLectures = this.studentsList.length > 0 ? this.studentsList[0].totalConducted : 0
      } catch (err) {
        console.log(err)
        this.studentsList = []
        this.totalLectures = 0
      }
      console.log(this.studentsList)

      const promises = []

      this.totalStudents = this.studentsList.length
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0

      for (let i = 0; i < this.studentsList.length; i++) {
        this.studentsList[i].percentage = this.studentsList[i].totalConducted > 0
          ? ((this.studentsList[i].totalAttended / this.studentsList[i].totalConducted) * 100).toFixed(2) : 0

        promises.push(this.getNameOfStudent(i))
      }

      this.changeLimit()

      await Promise.allSettled(promises)

      this.studentsList = this.studentsList.slice()
      this.stepOneCompleted = true
    },
    async getNameOfStudent (studentIndex) {
      console.log(studentIndex)
      try {
        if (!this.studentsList[studentIndex].name) {
          this.studentsList[studentIndex].name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.studentsList[studentIndex].uId })
        }
      } catch (err) {
        console.log(err)
        this.studentsList[studentIndex].name = ''
      }
    },
    changeLimit () {
      if (this.selectedLimit === '' || this.selectedLimit < 0) {
        this.selectedLimit = 1
      }
      if (this.selectedLimit > 100) {
        this.selectedLimit = 100
      }
      if (isNaN(this.selectedLimit)) {
        this.selectedLimit = 0
      }
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0
      for (let i = 0; i < this.studentsList.length; i++) {
        if (parseInt(this.studentsList[i].percentage) < parseInt(this.selectedLimit)) {
          this.studentsList[i].status = 'Defaulter'
          this.defaulterStudentsCount++
        } else {
          this.studentsList[i].status = 'Non-Defaulter'
          this.nondefaulterStudentsCount++
        }
      }
      this.defaulterStudentsPercent = ((this.defaulterStudentsCount / this.totalStudents) * 100).toFixed(0)
      this.nondefaulterStudentsPercent = ((this.nondefaulterStudentsCount / this.totalStudents) * 100).toFixed(0)
    },
    async downloadPdf () {
      try {
        const pdfBlob = await this.lectureRepositoryInstance.generateAttendanceAnalysisExcel(this.selectedSubject)
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    }
  }
}
</script>

<style src='./activityAttendanceAnalysis.css'>
</style>
