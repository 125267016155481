<template src="./activityNBAextended.html"> </template>

<script>
import { subjectTypes } from '../../Constants/Utils/Statics'
import NBARepository from '../../Repository/NBA'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import inputContainer from '../../Components/inputContainer'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'activityNBAextended',
  components: {
    inputContainer
  },
  data () {
    return {
      selectedDepartment: '',
      departments: [],
      toggle: true,
      flagMission: true,
      flagPO: true,
      itemIndex: 0,
      tempMission: '',
      dataLoaded: false,
      addingMission: false,
      NBAObject: {},
      DepartmentNBAObject: {},
      editingCO: false,
      series: [
        {
          name: 'AVG Attainment Level',
          data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65]
        }
      ],
      editingVision: false,
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          title: {
            text: 'Program Outcome'
          },
          labels: {},
          categories: [
            'PO1',
            'PO2',
            'PO3',
            'PO4',
            'PO5',
            'PO6',
            'PO7',
            'PO8',
            'PO9',
            'PO10',
            'PO11',
            'PO12'
          ]
        },
        yaxis: {
          title: {
            text: 'AVG Attainment Level'
          }
        },
        fill: {
          colors: ['#050992']
        }
      },
      addingCO: false,
      tempheading: '',
      tempdescription: '',
      editingMission: false,
      // test items
      Items: [
        {
          name: 'Apple'
        },
        {
          name: 'Orange'
        },
        {
          name: 'Mango'
        },
        {
          name: 'Watermelon'
        }
      ],
      desserts: [
        {
          name: 'S.T.Gaikwad',
          calories: 'SE',
          fat: 'SPOS',
          carbs: 'Complete'
        },
        {
          name: 'Disha Deshmukh',
          calories: 'TE',
          fat: 'M-II',
          carbs: 'Complete'
        },
        {
          name: 'Kiran Kendre',
          calories: 'SE',
          fat: 'DAA',
          carbs: 'Inprogress'
        },
        {
          name: 'Pooja Joshi',
          calories: 'TE',
          fat: 'DS',
          carbs: 'Complete'
        },
        {
          name: 'Rajesh Kedar',
          calories: 'BE',
          fat: 'SPOS',
          carbs: 'Inprogress'
        }
      ],
      headers: [
        {
          text: 'Class teacher',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Year', value: 'calories', sortable: false },
        { text: 'Subject', value: 'fat', sortable: false },
        { text: 'Status', value: 'carbs', sortable: false }
      ],
      // test data to store checkbox inputs
      user: {
        fruitCollection: []
      },
      userData: null,
      itemIndexForMission: 0,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      subjectTypes: subjectTypes
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'NBA Extended')
    this.userData = this.$store.getters['user/get_userData']
    console.log(this.userData)
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    console.log(this.selectedSemester)
    this.NBARepositoryInstance = new NBARepository(this)
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(this)
    // Call API to fetch subjects
    this.fetchData()
  },
  // mounted () {
  //   this.renderChart(this.datacollection, this.options)
  // },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.departments = await this.academicReferenceRepositoryInstance.getDepartments(objToPush)
        console.log(this.departments)
      } catch (err) {
        console.log(err)
      }
    },
    selectDepartment () {
      this.fetchData2()
    },
    async fetchData2 () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.selectedDepartment
          //   semId: this.selectedSemester.semId,
          //   uId: this.userData.uId
        }
        console.log('this is the object to push', objToPush)
        this.NBAObject = await this.NBARepositoryInstance.getNBA(objToPush)
        this.DepartmentNBAObject = await this.NBARepositoryInstance.getDepartmentNBA(
          objToPush
        )
        console.log('this is the nbaObject', this.DepartmentNBAObject)
        if (this.DepartmentNBAObject.departmentProgramOutcomes) {
        } else {
          throw console.error('No data found!')
        }
        // if (this.DepartmentNBAObject.departmentProgramOutcomes) {
        // } else {
        //   this.DepartmentNBAObject.departmentProgramOutcomes = []
        // }
        // if (this.DepartmentNBAObject.departmentMission) {
        // } else {
        //   this.DepartmentNBAObject.departmentMission = []
        // }
        // if (this.DepartmentNBAObject.departmentProgramOutcomes.length === 0) {
        //   this.flagPO = false
        // }
        // if (this.DepartmentNBAObject.departmentMission.length === 0) {
        //   this.flagMission = false
        // }
        // if (this.DepartmentNBAObject.departmentVision) {
        // } else {
        //   this.DepartmentNBAObject.departmentVision = ''
        // }
        this.dataLoaded = true
        this.flagPO = true
        this.flagMission = true
        // this.subjects = subjectObjects
      } catch (err) {
        console.log(err)
        this.DepartmentNBAObject = {}
        this.DepartmentNBAObject.departmentProgramOutcomes = []
        this.DepartmentNBAObject.departmentMission = []
        this.DepartmentNBAObject.departmentVision = ''
        this.dataLoaded = true
        this.flagPO = false
        this.flagMission = false
      }
    },
    async addPO () {
      if (!this.flagMission) {
        this.DepartmentNBAObject.departmentMission.splice(0, 1)
        // this.flagMission = true
      }
      if (!this.flagPO) {
        this.DepartmentNBAObject.departmentProgramOutcomes.splice(0, 1)
        this.flagPO = true
      }
      const objToPush = {
        psoId: uuidv4(),
        heading: this.tempheading,
        description: this.tempdescription
      }
      this.DepartmentNBAObject.departmentProgramOutcomes.push(objToPush)
      this.tempheading = ''
      this.tempdescription = ''
      this.addingCO = false
      this.updateDepartmentNBA()
    },
    async editItem (item) {
      this.itemIndex = item
      console.log(
        'this is in edit',
        this.DepartmentNBAObject.departmentProgramOutcomes[this.itemIndex]
      )
      this.editingCO = true
    },
    async deleteItem (index) {
      this.DepartmentNBAObject.departmentProgramOutcomes.splice(index, 1)
      this.updateDepartmentNBA()
    },
    async addMission () {
      if (!this.flagMission) {
        this.DepartmentNBAObject.departmentMission.splice(0, 1)
        this.flagMission = true
      }
      if (!this.flagPO) {
        this.DepartmentNBAObject.departmentProgramOutcomes.splice(0, 1)
        // this.flagPO = true
      }
      this.DepartmentNBAObject.departmentMission.push(this.tempMission)
      this.tempMission = ''
      this.updateDepartmentNBA()
      this.addingMission = false
    },
    async editMission (index) {
      this.itemIndexForMission = index
      this.editingMission = true
    },
    async deleteMission (index) {
      this.DepartmentNBAObject.departmentMission.splice(index, 1)
      this.updateDepartmentNBA()
    },
    async updateDepartmentNBA () {
      console.log('this to update NBA', this.DepartmentNBAObject)
      const objToPush = Object.assign(
        { instituteId: this.selectedInstitute, department: this.selectedDepartment },
        this.DepartmentNBAObject
      )
      console.log(objToPush, this.userData)
      await this.NBARepositoryInstance.updateDepartmentNBA(
        objToPush
      )
      this.fetchData()
    },
    readMore () {}
  }
}
</script>

<style src="./activityNBAextended.css"></style>
