export default {
  getLecture: '/lectures/getLectureById',
  arrangeALecture: '/lectures/arrangeLectures',
  createLecturesFromATimetableDay: '/lectures/createLecturesFromATimetableDay',
  getLecturesOfADepartmentForADay: '/lectures/getLecturesOfADepartment',
  getRealtimeSchedule: '/lectures/getDepartmentRealtimeSchedule',
  getLecturesOfADivisionForADay: '/lectures/getLecturesOfADivisionForADay',
  getLecturesForADay: '/lectures/getLecturesForADayForAFaculty',
  declareHoliday: '/lectures/declareAHolidayForGivenDates',
  cancelLecture: '/lectures/cancelALecture',
  getAttendanceAnalysisForADivisionForASubject: '/lectures/getAttendanceAnalysisForADivisionForASubject',
  getLecturesForADayForAFaculty: '/lectures/getLecturesForADayForAFaculty',
  generateAttendanceAnalysisExcel: '/excel/lecture/attendance'
}
