<template src="./activityFeeReceipt.html"></template>

<script>
import html2pdf from 'html2pdf.js'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import Vue from 'vue'
import { Vuetify, VCard } from 'vuetify'
export default {
  name: 'activityFeeReceipt',
  props: ['prop_user', 'prop_admissionDataStudent'],
  data() {
    return {
      isMobileView: false,
      pdfDocument: '',
      instituteStudentLogoLink: '',
      studentFirstName: this.prop_user.firstName,
      studentMiddleName: this.prop_user.middleName,
      studentLastName: this.prop_user.lastName,
      studentFatherName: this.prop_user.nameOfFather,
      studentMotherName: this.prop_user.nameOfMother,
      paymentMode: this.prop_user.paymentOption,
      payentDetails: this.prop_user.payments,
      subjectDetails: this.prop_user.batches,
      totalPayment: this.prop_user.totalPayment,
      admissionPayment: {},
      totalDiscountInRs: '',
      instituteInfo: '',
      instituteAddress: '',
      instituteEmailId: '',
      instituteContactNumber: '',
      instituteName: '',
      instituteGstNumber: '',
      subjectTotalAmount: 0,
      instituteTermsAndConditions: '',
      isPracEduskill: false,
      basicFeesSum: 0,
      totalFeesSum: 0,
      admissionTitle: ''
    }
  },
  async created() {
    this.admissionTitle = this.prop_admissionDataStudent.admissionTitle
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.instituteStudentLogoLink =
      this.$store.getters['liveData/get_instituteStudentLogoLink']

    this.instituteInfo = this.$store.getters['liveData/get_admissionInstitute']
    if (this.instituteInfo === '2c5265b2-53fd-4012-b336-52107ffa4a6d') {
      this.isPracEduskill = true
    }

    this.instituteAddress = this.$store.getters['liveData/get_instituteAddress']

    this.instituteEmailId = this.$store.getters['liveData/get_instituteEmailId']

    this.instituteContactNumber =
      this.$store.getters['liveData/get_instituteContactNumber']

    this.instituteAddress = this.$store.getters['liveData/get_instituteAddress']

    this.instituteName =
      this.$store.getters['liveData/get_instituteStudentBrandingName']

    this.instituteGstNumber =
      this.$store.getters['liveData/get_instituteGstNumber']

    this.instituteTermsAndConditions =
      this.$store.getters['liveData/get_instituteTermsAndConditions']

    this.prop_user.batches.map((payment) => {
      this.subjectTotalAmount += parseInt(payment.totalFees)
    })

    const res =
      await this.admissionUserRepositoryInstance.getAdmissionDataForEdit({
        instituteId: this.prop_user.instituteId,
        semId: this.prop_admissionDataStudent.semId,
        admissionId: this.prop_user.admissionId,
      })
    this.admissionPayment = res.admissionPayment[0]
    this.admissionPayment.installmentDiscount

    this.prop_user.batches.map((sub)=>{
      this.totalFeesSum += parseInt(sub.totalFees)
      this.basicFeesSum += parseInt(sub.basicFees)
    })
  },
  methods: {
    downloadPDF() {
      html2pdf(this.$refs.feeReceipt, {
        margin: [0, -1],
        filename: 'document.pdf',
        enableLinks: true,
        image: { type: 'jpg', quality: 0.98 },
        html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
        jsPDF: { unit: 'in', orientation: 'portrait', format: 'a4' },
      })
    },
  },
}
</script>

<style src="./activityFeeReceipt.css" scoped></style>
