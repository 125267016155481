var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityAdmission"},[_c('v-card',{staticStyle:{"padding":"30px","padding-top":"0","margin-top":"20px","box-shadow":"2px 2px 20px rgba(0, 0, 0, 0.1)","border-radius":"20px"}},[_c('v-btn',{staticStyle:{"color":"white","margin":"10px"},attrs:{"color":"#ff4f1f","rounded":""},on:{"click":function($event){return _vm.goToAdmissionBack()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-step-backward ")]),_vm._v(" Back To Home Page")],1),_c('v-card-title',[_vm._v(" Admissions Details "),_c('inputContainer',{staticStyle:{"margin":"30px"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search Existing Admission...","single-line":"","hide-details":"","color":"#ff4f1f","solo":"","flat":"","background-color":"transparent"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"color":"#050992","headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.dateOfAdmission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateConverter(item.createdAt))+" ")]}},{key:"item.studentName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+_vm._s(item.middleName)+_vm._s(item.lastName)+" ")]}},{key:"item.subjects",fn:function(ref){
var item = ref.item;
return _vm._l((item.batches),function(subject,i){return _c('v-chip',{key:i,staticStyle:{"margin":"5px"}},[(subject.feesCriteria === 'subjectWise' || !subject.feesCriteria)?_c('div',[_vm._v(" "+_vm._s(subject.subjectName)+" ")]):_c('div',[_vm._v(" "+_vm._s(subject.courseName)+" ")])])})}},{key:"item.installments",fn:function(ref){
var item = ref.item;
return [(item.installments === 1)?_c('div',[_vm._v("One Time Payment")]):_c('div',[_vm._v(_vm._s(item.installments))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-right":"3px","padding":"10px","color":"#ED5C35"},attrs:{"outlined":""},on:{"click":function($event){return _vm.gotoForm(item)}}},[_vm._v(" FORM ")]),_c('v-btn',{staticStyle:{"margin-right":"3px","padding":"10px","color":"#ED5C35"},attrs:{"outlined":""},on:{"click":function($event){return _vm.gotoReceipt(item)}}},[_vm._v(" Receipt ")])]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentOption ? item.paymentOption + ' | ' +item.paymentMethod : "")+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }