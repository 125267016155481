<template src='./activityProfile.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import UserRepository from '../../Repository/User'
import arrayOps from '../../Services/Utils/arrayOps'
export default {
  name: 'activityProfile',
  components: {
    inputContainer
  },
  data () {
    return {
      addingEmail: false,
      addingPhone: false,
      userData: null,
      tempEmail: '',
      tempPhone: '',
      tempFirstName: '',
      tempMiddleName: '',
      tempLastName: '',
      tempAddress: '',
      tempPhones: [],
      tempDateOfBirth: '',
      tempEmails: [],
      tempGender: '',
      tempBloodGroup: '',
      bloodGroups: [
        'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'
      ],
      genders: ['Male', 'Female', 'Other']
    }
  },
  created () {
    this.userRepositoryInstance = new UserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'My Profile')
    this.userData = this.$store.getters['user/get_userData']

    this.fetchData()
  },
  methods: {
    async fetchData () {
      const objToPush = {
        uId: this.userData.uId
      }
      try {
        this.user = await this.userRepositoryInstance.getUserProfile(objToPush)
        this.tempFirstName = this.user.firstName
        this.tempMiddleName = this.user.middleName
        this.tempLastName = this.user.lastName
        this.tempDateOfBirth = this.user.dateOfBirth ? this.parseDate(this.user.dateOfBirth) : ''
        this.tempGender = this.user.gender
        this.user.emails = []
        this.user.phones = []



        const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication(objToPush)
        if (emailsAndPhones.emails) {
          emailsAndPhones.emails.forEach((email) => {
            this.tempEmails.push(email)
            this.user.emails.push(email)
          })
        }
        if (emailsAndPhones.phones) {
          emailsAndPhones.phones.forEach((phone) => {
            this.tempPhones.push(phone)
            this.user.phones.push(phone)
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async save () {
      if (this.tempFirstName !== this.user.firstName) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            firstName: this.tempFirstName
          }
          this.tempFirstName = await this.userRepositoryInstance.updateFirstName(objToPush)
          this.user.firstName = this.tempFirstName
        } catch (err) {
          console.log(err)
        }
      }
      if (this.tempMiddleName !== this.user.middleName) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            middleName: this.tempMiddleName
          }
          this.tempMiddleName = await this.userRepositoryInstance.updateMiddleName(objToPush)
          this.user.middleName = this.tempMiddleName
        } catch (err) {
          console.log(err)
        }
      }
      if (this.tempLastName !== this.user.lastName) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            lastName: this.tempLastName
          }
          this.tempLastName = await this.userRepositoryInstance.updateLastName(objToPush)
          this.user.lastName = this.tempLastName
        } catch (err) {
          console.log(err)
        }
      }
      if (this.parseDate(this.convertFromYYYYMMDDToDate(this.tempDateOfBirth)) !== this.user.dateOfBirth) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            dateOfBirth: this.convertFromYYYYMMDDToDate(this.tempDateOfBirth)
          }
          const updatedDateOfBirth = await this.userRepositoryInstance.updateDateOfBirth(objToPush)
          this.tempDateOfBirth = this.parseDate(updatedDateOfBirth)
          this.user.dateOfBirth = updatedDateOfBirth
        } catch (err) {
          console.log(err)
        }
      }
      /* if (this.tempEmail !== this.user.email) {
        const objToPush = {
          uId: this.userData.uId,
          email: this.tempEmail
        }
        await this.userRepositoryInstance.updateEmail(objToPush)
      }
      if (this.phone !== this.user.phone) {
        const objToPush = {
          uId: this.userData.uId,
          phone: this.tempPhone
        }
        await this.userRepositoryInstance.updatePhone(objToPush)
      } */

      if (this.tempGender !== this.user.gender) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            gender: this.tempGender
          }
          this.tempGender = await this.userRepositoryInstance.updateGender(objToPush)
          this.user.gender = this.tempGender
        } catch (err) {
          console.log(err)
        }
      }
      await this.addAndRemoveEmailsAndPhones()
    },
    async addAndRemoveEmailsAndPhones () {
      const emailsToDelete = this.user.emails.filter((email) => !this.tempEmails.includes(email))

      for (let i = 0; i < emailsToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deleteEmail({
            uId: this.user.uId,
            email: emailsToDelete[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const phonesToDelete = this.user.phones.filter((phone) => !this.tempPhones.includes(phone))

      for (let i = 0; i < phonesToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deletePhone({
            uId: this.user.uId,
            phone: phonesToDelete[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const emailsToAdd = this.tempEmails.filter((email) => !this.user.emails.includes(email))

      for (let i = 0; i < emailsToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addEmail({
            uId: this.user.uId,
            email: emailsToAdd[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const phonesToAdd = this.tempPhones.filter((phone) => !this.user.phones.includes(phone))

      for (let i = 0; i < phonesToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addPhone({
            uId: this.user.uId,
            phone: phonesToAdd[i]
          })
        } catch (err) {
          console.log(err)
        }
      }
    },
    addEmail () {
      this.tempEmails.push(this.tempEmail)
      this.tempEmail = ''
      this.addingEmail = false
    },
    addPhone () {
      this.tempPhones.push(this.tempPhone)
      this.tempPhone = ''
      this.addingPhone = false
    },
    parseDate (dateOfBirth) {
      console.log(dateOfBirth)
      const parsedDate = new Date(dateOfBirth)
      return parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getDate()).slice(-2)
    },
    removeFromArray (item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },
    convertFromYYYYMMDDToDate (dateString, timeString) {
      const dateArray = dateString.split('-').map((date) => parseInt(date))
      console.log(dateArray)
      return new Date(
        ...dateArray
      )
    }
  }
}
</script>
<style src='./activityProfile.css' scoped>
</style>
