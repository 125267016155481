import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/TopicFeedback'

class TopicFeedback {
  constructor (context) {
    this.context = context
  }

  async createTopicFeedback (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTopicFeedback, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedback
  }

  async getTopicFeedbackById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbackById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedback
  }

  async getTopicFeedbacksForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbacksForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbacks
  }

  async getTopicFeedbacksForAStudentForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbacksForAStudentForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbacks
  }

  async getTopicFeedbacksForAFacultyForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbacksForAFacultyForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbacks
  }

  async getTopicFeedbacksForAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbacksForAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbacks
  }

  async getTopicFeedbacksForADivisionForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbacksForADivisionForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbacks
  }

  async submitFeedbackOfAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.putRequest(ApiNames.submitFeedbackOfAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default TopicFeedback
