import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/AdmissionUser'

class AdmissionUser {
  constructor (context) {
    this.context = context
  }

  async submitPaymentInstallment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.submitPaymentInstallment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getOrderId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getOrderId,data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async createAdmissionUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.postRequest(ApiNames.createAdmissionUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async saveAdmission (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.saveAdmission, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async savePayment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.savePayment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async saveSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.saveSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAdmissionDataForEdit (data) {
    console.log("APPPPP--->")
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAdmissionDataForEdit, data)
    console.log("APPPPP1111--->")
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async saveEditAdmission (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.saveEditAdmission, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async saveEditPayment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.saveEditPayment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async saveEditSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.saveEditSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAdmissionUsers (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAdmissionUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAdmissionUsersById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAdmissionUsersById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAdmissionDataBySem (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAdmissionDataBySem, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getAdmissionUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAdmissionUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAdmissionDataForStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAdmissionDataForStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAdmissionDataByAdmissionId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAdmissionDataByAdmissionId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateAdmissionUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    await networkManager.putRequest(ApiNames.updateAdmissionUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async updateAdmissionUserPayment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    await networkManager.putRequest(ApiNames.updateAdmissionUserPayment, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteAdmissionUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    await networkManager.deleteRequest(ApiNames.deleteAdmissionUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async addUserDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.postRequest(ApiNames.addUserDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default AdmissionUser
